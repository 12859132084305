import React from "react";
import imageUtil from "../globalUtility/imageHelper";
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

class Deals extends React.Component{
    componentDidMount(){
        const grid = document.querySelector('.grid');
        const msnry = new Masonry( grid, {
      // options...
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true
        });
        imagesLoaded( grid ).on( 'progress', function() {
            // layout Masonry after each image loads
            msnry.layout();
          });
    
    }
    
    render() {   
        const images = imageUtil.images
        return(
            <section className="padding-80px-tb best-deals-style1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 center-col margin-bottom-40px lg-margin-bottom-30px md-margin-bottom-25px sm-margin-bottom-20px text-center">
                            {/* best deals heading starts */}
                            <h3 className="text-black font-weight-800 main-font text-transform lg-title-medium md-title-medium sm-title-mdmore">
                                Best Deals
                            </h3>
                            <span className="text-black md-text-large main-font font-weight-600 sm-text-large">
                                Here's The Best Deals You Can Grab
                            </span>
                            {/* best deals heading ends */}
                        </div>
                    </div>
                </div>
                <div className="container-fluid margin-bottom-5px">
                    <div className="grid" /*data-masonry='{ "itemSelector": ".grid-item", "columnWidth": ".grid-sizer", "percentPosition": "true"}'*/>
                        <div className="grid-sizer w-50" />
                        <div className="row">
                            {/* product1 starts */}
                            <div className="grid-item col-lg-6 col-md-12 col-12 margin-bottom-30px sm-margin-bottom-20px">
                                <div className="row">
                                    {/* product1 image1 starts */}
                                    <div className="col-md-4 col-12 sm-margin-bottom-20px mas-ex">
                                        <div className="best-images">
                                            <img src={images.bagsImage} alt="image1" className="img-fluid border-radius-5" />
                                        </div>
                                    </div>
                                    {/* product1 image1 ends */}
                                    {/* product1 image2 starts */}
                                    <div className="col-md-4 col-12 sm-margin-bottom-20px mas-ex">
                                        <div className="best-images">
                                            <img src={images.watch2} alt="image1" className="img-fluid border-radius-5" />
                                        </div>
                                    </div>
                                    {/* product1 image2 ends */}
                                    {/* product1 flat offers starts */}
                                    <div className="col-md-4 col-12 mas-ex">
                                        <div className="bg-sky-blue-light border-radius-5 xl-padding-30px lg-padding-15px sm-padding-60px md-padding-40px paddingsixty-five width-100 height-100">
                                            <span className="font-weight-700 alt-font text-white text-center title-small lg-text-mediumall lg-text-mediumall-desktop md-title-med-more2 sm-title-mdmore">
                                                FLAT 70% OFF ON ALL JACKETS
                                            </span>
                                        </div>
                                    </div>
                                    {/* product1 flat offers ends */}
                                </div>
                            </div>
                            {/* product1 ends */}
                            {/* product2 starts */}
                            <div className="grid-item col-lg-6 col-md-12 margin-bottom-30px sm-margin-bottom-20px">
                                <div className="row">
                                    {/* product2 image1 starts */}
                                    <div className="col-md-8 col-12 sm-margin-bottom-20px mas-ex">
                                        <div className="best-images">
                                            <img className="img-fluid lg-width-100 lg-height-100" src={images.shoesBig2} alt="" />
                                        </div>
                                    </div>
                                    {/* product2 image1 ends */}
                                    <div className="col-md-4 col-12 mas-ex">
                                        {/* product2 image2 starts */}
                                        <div className="product-image-2 margin-bottom-30px sm-margin-bottom-20px">
                                            <div className="best-images">
                                                <img alt="" className="img-fluid sm-width-100" src={images.shoes1} />
                                            </div>
                                        </div>
                                        {/* product2 image2 ends */}
                                        {/* product2 image3 starts */}
                                        <div className="product-image-2 margin-bottom-30px sm-margin-bottom-20px">
                                            <div className="best-images">
                                                <img alt="" className="img-fluid sm-width-100" src={images.shoes2} />
                                            </div>
                                        </div>
                                        {/* product2 image3 ends */}
                                        {/* product2 flat offers starts */}
                                        <div className="bg-sky-blue-light border-radius-5 xl-padding-30px paddingsixty-five md-padding-40px sm-padding-60px lg-padding-15px">
                                            <span className="font-weight-700 alt-font text-white text-center title-small lg-text-mediumall-desktop lg-text-mediumall md-title-med-more2 sm-title-mdmore">
                                                FLAT 35% OFF ON ALL SHOES
                                            </span>
                                        </div>
                                        {/* product2 flat offers ends */}
                                    </div>
                                </div>
                            </div>
                            {/* product2 ends */}
                            {/* product3 starts */}
                            <div className="grid-item col-lg-6 col-md-12 sm-margin-bottom-20px">
                                <div className="row">
                                    <div className="col-md-4 col-12 sm-margin-bottom-20px mas-ex">
                                        {/* product3 flat offers starts */}
                                        <div className="bg-sky-blue-light border-radius-5 xl-padding-30px paddingsixty-five md-padding-40px sm-padding-60px lg-padding-15px margin-bottom-30px sm-margin-bottom-20px">
                                            <span className="font-weight-700 alt-font text-center text-white title-small lg-text-mediumall-desktop lg-text-mediumall md-title-med-more2 sm-title-mdmore">
                                                FLAT 20% OFF ON SUN GLASSES
                                            </span>
                                        </div>
                                        {/* product3 flat offers ends */}
                                        {/* product3 image1 starts */}
                                        <div className="product-image-2 margin-bottom-30px sm-margin-bottom-20px">
                                            <div className="best-images">
                                                <img alt="" className="img-fluid sm-width-100" src={images.sungLass1} />
                                            </div>
                                        </div>
                                        {/* product3 image1 ends */}
                                        {/* product3 image2 starts */}
                                        <div className="product-image-2 md-margin-bottom-30px sm-margin-bottom-0px">
                                            <div className="best-images">
                                                <img alt="" className="img-fluid sm-width-100" src={images.sungLass2} />
                                            </div>
                                        </div>
                                        {/* product3 image2 ends */}
                                    </div>
                                    <div className="col-md-8 col-12 md-margin-bottom-30px sm-margin-bottom-0px mas-ex">
                                        {/* product3 image3 starts */}
                                        <div className="best-images">
                                            <img className="img-fluid lg-width-100 lg-height-100" src={images.sungLassBig} alt="" />
                                        </div>
                                        {/* product3 image3 ends */}
                                    </div>
                                </div>
                            </div>
                            {/* product3 ends */}
                            {/* product4 starts */}
                            <div className="grid-item col-lg-6 col-md-12">
                                <div className="row">
                                    {/* product4 flat offers starts */}
                                    <div className="col-md-4 col-12 sm-margin-bottom-20px">
                                        <div className="bg-sky-blue-light border-radius-5 xl-padding-30px paddingsixty-five sm-padding-60px md-padding-40px lg-padding-15px width-100 height-100">
                                            <span className="font-weight-700 alt-font text-white text-center title-small lg-text-mediumall-desktop lg-text-mediumall md-title-med-more2 sm-title-mdmore">
                                                FLAT 15% OFF ON ALL WATCHES
                                            </span>
                                        </div>
                                    </div>
                                    {/* product4 flat offers ends */}
                                    {/* product4 image1 starts */}
                                    <div className="col-md-4 col-12 sm-margin-bottom-20px">
                                        <div className="best-images">
                                            <img src={images.watch1} alt="image1" className="img-fluid border-radius-5" />
                                        </div>
                                    </div>
                                    {/* product4 image1 ends */}
                                    {/* product4 image2 ends */}
                                    <div className="col-md-4 col-12 sm-margin-bottom-20px">
                                        <div className="best-images">
                                            <img src={images.shoeImg} alt="image1" className="img-fluid border-radius-5" />
                                        </div>
                                    </div>
                                    {/* product4 image2 ends */}
                                </div>
                            </div>
                            {/* product4 ends */}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Deals
