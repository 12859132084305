import React from "react"
import {Link} from "react-router-dom"
import titleUpperCase from "../../../globalUtility/commonHelper";

const BlogsListFull = (props) => {
    return (
        <div className="row">
            {/* blog starts */}
            {
                (props && props.blogList && props.blogList.length) ? props.blogList.map((alr, i) =>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-12 blog-con" key={i}>
                        <div className="b-content">
                            <div className="position-relative blog-img">
                                {/* blog image starts */}
                                <figure className="no-margin-bottom">
                                    <img src={alr.image} alt="blog-1" className="img-fluid" />
                                </figure>
                                {/* blog image ends */}
                            </div>
                            {/* blog info starts */}
                            <div className="blog1">
                                <div className="blod-date-like">
                                    <div className="data-box-grid">
                                        <h6 className="display-inline-block">{alr.date}</h6>
                                        <p className="no-margin-bottom display-inline-block">{alr.month}</p>
                                    </div>
                                    {/*<div className="blog-likes">*/}
                                    {/*    <i className="far fa-heart" /><span>{`${alr.like || 0} Likes`}</span>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="clearfix" />
                                {/* blog heading starts */}
                                <h6 className="b-title text-extra-large md-title-mdall sm-text-extra-large">
                                    <Link to={{ pathname: '/blogs/blog-details', state: {blog:{id: alr.id, image: alr.image, title: alr.title}} }} className="font-weight-600 text-black hoverstyle1">{titleUpperCase(alr.title)}</Link>
                                </h6>
                                {/* blog heading ends */}
                                <p className="b-cont d-inline-block text-black sm-text-large">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</p>
                                <div className="b-read-more">
                                    <Link to={{ pathname: '/blogs/blog-details', state: { blog:{id: alr.id, image: alr.image, title: alr.title} } }} className="btn btn-style1">Read More</Link>
                                </div>
                                <div className="blog-ad-name">
                                    <div className="b-admin">
                                        <i className="fas fa-user-check" />
                                        <p className="no-margin-bottom display-inline-block">{`By ${alr.author}`}</p>
                                    </div>
                                    <div className="blog-likes">
                                        <i className="far fa-comment" /><span>{`${alr.comment} Comment`}</span>
                                    </div>
                                </div>
                            </div>
                            {/* blog info ends */}
                        </div>
                    </div>
                ) : <p>Blog not found</p>
            }
        </div>
    )
}

export default BlogsListFull
