import {ADD_TO_WISH_LIST_PRODUCT, END_ADD_TO_WISH_LIST, REMOVE_TO_WISH_LIST_PRODUCT} from "../constants/actionTypes";
import {toast} from "react-toastify";

export const addItemToWish = (data) => {
    return (dispatch) => {
        try {
            const allWishList = JSON.parse(localStorage.getItem('wishList') ) || []
            const isExists = allWishList && allWishList.length && allWishList.find(d => d.id === data.id)
            if (isExists && Object.keys(isExists).length) {
                const idx = allWishList.findIndex(f => f.id === isExists.id)
                allWishList[idx].count = allWishList[idx].count + 1
                toast.warn(`Item already exists to wishList`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                allWishList.push({...data, count: 1})
                toast.success(`Item has been added to wishList`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            localStorage.setItem('wishList', JSON.stringify(allWishList))
            dispatch({type: ADD_TO_WISH_LIST_PRODUCT, allWish: allWishList});

        } catch (error) {
            dispatch({type: END_ADD_TO_WISH_LIST, errorMsg: error});
        }
    }
};

export const removeItemToWishList = (data) => {
    return (dispatch) => {
        try {
            localStorage.setItem("wishList" , JSON.stringify(data))
            dispatch({type: REMOVE_TO_WISH_LIST_PRODUCT, totalWishList: data.length});
        } catch (error) {
            dispatch({type: END_ADD_TO_WISH_LIST, errorMsg: error});
        }
    }
};