import React from "react";
import RelatedProducts from "./RelatedProducts";
import {productList} from "../../globalUtility/jsonData/product/ProductDataList";
import imageUtil from "../../globalUtility/imageHelper";
import BreadCrumb from "../../GlobalComponents/BreadCrumb";
import {Link} from "react-router-dom";
import Rating from "./Common/Rating";
import ProductDetailsView from "./Common/ProductDetailsView";
import titleUpperCase from "../../globalUtility/commonHelper";
import {addItemToCart} from "../../actions/cartAction";
import {connect} from "react-redux";
import {productCompareAction} from "../../actions/productCompareAction";
import {addItemToWish} from "../../actions/wishListAction";

class ProductDetails extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            productDetails: {},
            activeImage: "",
            productDataList: productList,
        }
    }

    componentWillMount() {
        const {location} = this.props
        const {productDataList} = this.state
        const currentId = Number(location && location.pathname.split && location.pathname.split("/")[2])
        const currentProductDetails = productDataList.find(item => item.id === currentId)
        this.setState({
            productDetails: currentProductDetails || {},
            activeImage: (currentProductDetails && currentProductDetails.pictures && currentProductDetails.pictures[0]) || "",
        })
    }

    zoomImage(){
        const el = document.querySelectorAll('.tile');

        el.forEach(function(item,i){
            if(item.children.length)
            {
              item.removeChild(item.childNodes[0])
            }
            const ele = document.createElement('div')
            ele.setAttribute("class", "photo border-radius-5")
            ele.style.backgroundImage = 'url('+item.getAttribute('data-image')+')'
            item.appendChild(ele)
            item.addEventListener('mouseover',function(){

                item.querySelector('.photo').style.transform = 'scale('+ item.getAttribute('data-scale') +')'
            });
            item.addEventListener('mouseout',function(){
                item.querySelector('.photo').style.transform = 'scale(1)'
            });
            item.addEventListener('mousemove',function(e){
                const rect = item.getBoundingClientRect();
                const x =e.pageX - (rect.left + window.pageXOffset)
                const xp =parseFloat(getComputedStyle(item, null).width.replace("px", ""))
                const xpp=  (x/xp) * 100
                const y = e.pageY - (rect.top + window.pageYOffset)
                const yp = parseFloat(getComputedStyle(item, null).height.replace("px", ""))
                const ypp = (y/yp ) * 100
                item.querySelector('.photo').style.transformOrigin = ''+xpp + '%' + ypp+'%'
            });
        })

    }
    componentDidMount() {
        this.zoomImage()
    }
    componentDidUpdate(){
        this.zoomImage()
    }

    componentWillReceiveProps(nextProps) {
        const {productDataList} = this.state
        const {location} = this.props
        const currentId = Number(location.pathname && location.pathname.split("/")[2])
        const newId = Number(nextProps.location && nextProps.location.pathname.split && nextProps.location.pathname.split("/")[2])
        if(currentId !== newId){
            const currentProductDetails = productDataList.find(item => item.id === newId)
            this.setState({
                productDetails: currentProductDetails || {},
                activeImage: (currentProductDetails && currentProductDetails.pictures && currentProductDetails.pictures[0]) || ""
            })
        }
    }

    handelImage = (image) =>{
        this.setState({
            activeImage: image
        })
    }

    handelProduct = (product) =>{
        const {productDetails} = this.state
        if(product === 1){
            productDetails.count = (productDetails.count || 1) + 1
        }else if(product === -1){
            productDetails.count = (productDetails.count || 1) - 1
        }
        this.setState({
            productDetails
        })
    }

    render() {
        const {productDetails} = this.state
        const images = imageUtil.images
        return(
            <div>
                <BreadCrumb heading="Products" subHeading="Product Details"/>
                {
                    productDetails.productTitle
                        ? (
                        <div>
                            <section className="padding-top-80px tilesstyle-1 tilestyle-2 lg-padding-top-40px md-padding-top-30px">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                {/* product image starts */}
                                <div className="tiles height-400-px width-100 p-0 margin-bottom-20px sm-height-300-px">
                                    <div className="tile position-relative height-100 overflow-hidden" data-scale="1.6" data-image={this.state.activeImage} />
                                </div>
                                {/* product image ends */}
                                {/* products other images starts */}
                                <div className="row margin-l-m-5px margin-r-m-5px">
                                    {
                                        ((productDetails && productDetails.pictures) || []).map((image,index) =>{
                                            return(
                                                <div key={index} className="col-md-3 col-3 product-details-more padding-left-5px padding-right-5px">
                                                    <img src={image} alt={image} onClick={()=>this.handelImage(image)} className="img-fluid" />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {/* products' other images ends */}
                            </div>
                            <div className="col-md-12 col-lg-6 item-description-style1 md-margin-top-20px sm-margin-top-20px">
                                {/* product name starts */}
                                <div className="produc-name-details margin-bottom-10px">
                                    <h6 className="d-inline-block title-small font-weight-600 lg-text-extra-large md-title-mdall sm-text-extra-large">
                                        {titleUpperCase(productDetails.productTitle)}
                                    </h6>
                                </div>
                                {/* product name ends */}
                                {/* product price starts */}
                                <div className="font-weight-600 text-large margin-bottom-10px md-text-large sm-text-large">
                                    ${productDetails.salePrice && productDetails.salePrice.toFixed(2)}
                                    <span className="text-decoration-line-through text-medium text-gray pl-2 md-text-medium sm-text-medium">
                                        ${productDetails.price && productDetails.price.toFixed(2)}
                                    </span>
                                </div>
                                {/* product price ends */}
                                {/* product ratings starts */}
                                <Rating ratingStars={(productDetails.rating) || 0}/>
                                {/* product ratings ends */}
                                {/* product availablity starts */}
                                <div className="product-availablity margin-bottom-10px sm-text-large">
                                    <span className="display-inline-block font-weight-600">Availablity:</span>
                                    <span className={`display-inline-block font-weight-600 ${((productDetails.count || 1) < productDetails.stock) ? "text-green" : "text-danger"}`}>
                                     {((productDetails.count || 1) < productDetails.stock) ? " In Stocks" : " Out of Stocks"}
                                    </span>
                                </div>
                                {/* product availablity ends */}
                                {/* product colors availablity starts */}
                                {
                                    (productDetails.colors.length) > 0 &&
                                    <div className="d-inline-block margin-bottom-10px sm-text-large">
                                        <span className="d-inline-block font-weight-600">Colors Availablity :</span>
                                        <div className="d-inline-block vertical-align-texttop">&nbsp;
                                            {
                                                ((productDetails && productDetails.colors) || []).map((m, index)=> {
                                                    return(
                                                        <span key={index} style={{backgroundColor: m}} className="mr-1 p-2 d-inline-block rounded" />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {/* product colors availablity ends */}
                                {/* product size start */}
                                {
                                    (productDetails.size && productDetails.size.length) > 0 &&
                                    <div className="size">
                                        <span className="size-title font-weight-600 margin-right-5px">Size:</span>
                                        <div className="size-value">
                                            {
                                                productDetails.size.map((size, index) =>(
                                                    <Link key={index} to="#" className="text-black text-center sm-text-large">{size}</Link>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                {/* product size end */}
                                <div className="separator-border" />
                                {/* product description starts */}
                                <div className="product-description">
                                    <span className="d-inline-block font-weight-600 margin-bottom-10px">Product Description</span>
                                    <p className="sm-text-large">
                                        {titleUpperCase(productDetails.description)}
                                    </p>
                                </div>
                                {/* product description ends */}
                                <div className="separator-border" />
                                <div className="quntity">
                                    <div className="quantity-wrapper">
                                        {/* product qty starts */}
                                        <div className="qty-wrap">
                                            <div className="qty-title">
                                                <span className="font-weight-600 sm-text-large">Qty:</span>
                                            </div>
                                        </div>
                                        <div className="qty-input qty-input-style1">
                                            <i className="less title-medium"
                                               onClick={(productDetails.count || 1) > 1 ? () =>this.handelProduct(-1) : null}>-</i>
                                            <input type="text" value={(productDetails.count || 1)} onChange={()=>{}} />
                                            <i className="more title-medium"
                                               onClick={((productDetails.count || 1) < productDetails.stock) ? () =>this.handelProduct(1) : null}>+</i>
                                        </div>
                                        {/* product qty ends */}
                                        {/* product add to cart starts */}
                                        <div className="d-inline-block">
                                            <span onClick={() => this.props.addToCart(productDetails)} className="btn btn-style1 ml-2">
                                                <i className="fas fa-shopping-bag pr-2" />ADD TO CART
                                            </span>
                                        </div>
                                        {/* product add to cart ends */}
                                    </div>
                                    {/* qty button endt */}
                                </div>
                                <div className="clearfix" />
                                {/* links starts  */}
                                <div className="action-btn margin-top-15px">
                                    <span onClick={()=> this.props.addToWish(productDetails)}><i className="far fa-heart text-black" />
                                        <span className="mt-2 hoverstyle1 font-weight-600 text-black-ni">Add To Wishlist</span>
                                    </span>
                                    <span onClick={()=> this.props.addToCompareProduct(productDetails)}>
                                        <i className="fas fa-balance-scale text-black ml-3" />
                                        <span className="mt-2 hoverstyle1 font-weight-600 text-black-ni">Add To Compare</span>
                                    </span>
                                </div>
                                {/* links ends */}
                                {/* product share start */}
                                <div className="share d-inline-block">
                                    <label className="d-inline-block font-weight-600">Share with:</label>
                                    <div className="share-icon">
                                        <a href="https://www.facebook.com/" target="_blank" className="face-book" data-toggle="tooltip" title="facebook" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
                                        <a href="https://twitter.com/" target="_blank" className="twitter" data-toggle="tooltip" title="twitter" rel="noopener noreferrer"><i className="fab fa-twitter" /></a>
                                        <a href="https://in.pinterest.com/" target="_blank" className="pinterest" data-toggle="tooltip" title="pinterest" rel="noopener noreferrer"><i className="fab fa-pinterest" /></a>
                                        <a href="https://www.linkedin.com/" target="_blank" className="linkedin" data-toggle="tooltip" title="linkedin" rel="noopener noreferrer"><i className="fab fa-linkedin-in" /></a>
                                    </div>
                                </div>
                                {/* product shares end */}
                                <div className="separator-border" />
                                <div className="seller d-block lg-text-mediumall margin-top-15px font-weight-600 sm-margin-top-5px"><i className="fas fa-users padding-right-5px text-sky-blue" />4 sellers Are Available For This Item.</div>
                            </div>
                        </div>
                    </div>
                </section>
                        </div>
                    ) : (
                        <section className="mb-5 padding-top-80px tilesstyle-1 tilestyle-2 lg-padding-top-40px md-padding-top-30px">
                            <div className="container">
                                <p className="m-2 text-center">This product is not found</p>
                            </div>
                        </section>
                    )
                }
                {/* parallax section starts */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-top-40px">
                            {/* parallax image starts */}
                            <div className="parallax-style1 border-radius-5 fix-background height-300-px sm-height-200-px" style={{backgroundImage: `url(${images.proDetailParallex})`}}>
                                <div className="position-absolute top-110px sm-top-60px left-0 right-0 m-auto text-white height-80-px width-80-px bg-sky-blue-light d-flex align-items-center justify-content-center border-radius-50 sm-height-60px sm-width-60px">
                                    <a href="https://www.youtube.com/" target="_blank" className="text-white" rel="noopener noreferrer"><i className="fas fa-play title-large-1 sm-title-middle2" /></a>
                                </div>
                                <h6 className="position-absolute font-weight-600 top-45p left-55p text-white md-left-60 sm-left-65px sm-top-40p sm-text-large">Introducing New</h6>
                            </div>
                            {/* parallax image ends */}
                        </div>
                    </div>
                </div>
                <ProductDetailsView/>
                <RelatedProducts/>
                {/* parallax section ends */}

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item)),
    addToCompareProduct: (item) => dispatch(productCompareAction(item)),
    addToWish: (data) => dispatch(addItemToWish(data)),
})

export default connect(
    null,
    mapDispatchToProps
)(ProductDetails)
