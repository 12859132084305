import React from "react";
import BreadCrumb from "../GlobalComponents/BreadCrumb";
import {Link} from 'react-router-dom'

class NewsLetters extends React.Component{
    render() {
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Home" subHeading="Newsletter"/>
                {/* breadcrumb section end */}
                <section className="padding-80px-tb newsletterstyle-1">
                    {/* newsletter heading starts */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-9 col-12 center-col text-center margin-bottom-40px lg-margin-bottom-30px md-margin-bottom-25px sm-margin-bottom-20px">
                                <h6 className="text-transform lg-title-medium md-title-medium sm-title-mdmore">NEWSLETTER &amp; GET DISCOUNT 25% OFF</h6>
                                <span className="main-font sm-text-large">Sign Up to our newsletter and get a 20% discount offer</span>
                            </div>
                        </div>
                    </div>
                    {/* newsletter heading ends */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-4 col-lg-7 col-md-7 col-12 foremail text-center center-col display-inline-block">
                                {/* email starts */}
                                <input className="email form-control display-inline-block sm-text-large" data-field="required" id="email" type="text" name="email" placeholder="Your E-mail Address" />
                                {/* email ends */}
                                {/* subscribe button starts */}
                                <div>
                                    <Link id="subscribe-submit" to="#" className="btn-style1 margin-top-20px display-inline-block">
                                        <i className="fas fa-paper-plane pr-2" />Subscribe
                                    </Link>
                                </div>
                                {/* subscribe button ends */}
                            </div>
                        </div>
                    </div>
                </section>
            </>

        )
    }
}

export default NewsLetters
