    import React from "react";
import {Link} from "react-router-dom";
import imageUtil from "../../globalUtility/imageHelper";

class Slider1 extends React.Component {
    render() {
        const dataImage = imageUtil.images
        return (
            <div>
                {/* slider starts */}
                <section className="slider-style2">
                    <div className="swiper-container" id="home-slider2">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide fullscreen" style={{backgroundImage: `url(${dataImage.sliderImage4})`}}>
                                <div className="slidertext">
                                    <span className="offer-text">Special Offers</span>
                                    <h1 className="slider-title">
                                        <span>This Diwali Get Upto</span>
                                        <span>50%</span>
                                        <span>On Headphones</span>
                                    </h1>
                                    <Link to="/category/clothes" className="btn btn-style1">Buy Now</Link>
                                </div>
                            </div>
                            <div className="swiper-slide fullscreen" style={{backgroundImage: `url(${dataImage.sliderImage6})`}}>
                                <div className="slidertext">
                                    <span className="offer-text">Special Offers</span>
                                    <h1 className="slider-title">
                                        <span>This Diwali Get Upto</span>
                                        <span>50%</span>
                                        <span>On Headphones</span>
                                    </h1>
                                    <Link to="/category/clothes" className="btn btn-style1">Buy Now</Link>
                                </div>
                            </div>
                            <div className="swiper-slide fullscreen" style={{backgroundImage: `url(${dataImage.sliderImage5})`}}>
                                <div className="slidertext">
                                    <span className="offer-text">Special Offers</span>
                                    <h1 className="slider-title">
                                        <span>This Diwali Get Upto</span>
                                        <span>50%</span>
                                        <span>On Headphones</span>
                                    </h1>
                                    <Link to="/category/clothes" className="btn btn-style1">Buy Now</Link>
                                </div>
                            </div>
                        </div>
                        {/* Add Pagination */}
                        {/* Add Arrows */}
                        <div className="sw-ar">
                            <div className="prev"><i className="fas fa-angle-left" /></div>
                            <div className="swiper-pagination" />
                            <div className="next"><i className="fas fa-angle-right" /></div>
                        </div>
                    </div>
                </section>
                {/* slider ends */}

                <section className="top-banner">
                    <div className="container-fluid">
                        <div className="row top-banner-sec">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-12 top-banner-content">
                                <div className="top-b-img">
                                    <Link to="#">
                                        <img src={dataImage.banner1} className="img-fluid" alt="slider"/>
                                    </Link>
                                    <div className="top-banner-text">
                                        <span className="b-label">New In</span>
                                        <h6 className="b-title">Wireless Headphone</h6>
                                        <span className="b-offer">20% Off</span>
                                        <Link to="/category/clothes" className="btn btn-style1">shop now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-12 col-12 top-banner-content">
                                <div className="top-b-img">
                                    <Link to="#">
                                        <img src={dataImage.banner2} className="img-fluid" alt="slider"/>
                                    </Link>
                                    <div className="top-banner-text">
                                        <span className="b-label">Women Fashion</span>
                                        <h6 className="b-title">Women Dress</h6>
                                        <span className="b-offer">50% Off</span>
                                        <Link to="/category/clothes" className="btn btn-style1">shop now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-12 top-banner-content">
                                <div className="top-b-img">
                                    <Link to="#">
                                        <img src={dataImage.banner3} className="img-fluid" alt="slider"/>
                                    </Link>
                                    <div className="top-banner-text">
                                        <span className="b-label">New Collection</span>
                                        <h6 className="b-title">Men T-Shirt</h6>
                                        <span className="b-offer">40% Off</span>
                                        <Link to="/category/clothes" className="btn btn-style1">shop now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Slider1
