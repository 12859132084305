import React from "react";
import imageUtil from "../globalUtility/imageHelper";
import {Link} from "react-router-dom";
import ProductDataList from "../globalUtility/jsonData/product/ProductDataList";
import ProductViewModal from "../globalUtility/ProductViewModal";
import {addItemToCart} from "../actions/cartAction";
import {connect} from "react-redux";
import {addItemToWish} from "../actions/wishListAction";

class DealOfTheDay extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            isModal: false,
            productPreview: {},
            minutes: 0,
            seconds: 0,
            hours: 0,
            days: 0,
        }
    }

    handelModal = (item) =>{
        this.setState({
            isModal: !this.state.isModal,
            productPreview: item.productTitle ? item : {}
        })
    }

    componentDidMount() {
        let date = new Date();
        date.setDate(date.getDate() + 2);
        let countDownDate = new Date(date).getTime();
        setInterval(() => {
            let now = new Date().getTime();
            let distance = countDownDate - now;
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                minutes: minutes,
                days: days,
                hours: hours,
                seconds: seconds
            })
        }, 1000)
    }

    render() {
        const {isModal, productPreview, seconds, hours, minutes, days} = this.state
        const images = imageUtil.images
        const productList = ProductDataList.productList
        const product1 = productList.find(o => o.id === 72)
        const product2 = productList.find(o => o.id === 73)
        return(
            <>
                {/*quick view starts */}
                {
                    isModal &&
                    <ProductViewModal
                        isModal={isModal}
                        productPreview={productPreview}
                        handelModal={this.handelModal}/>
                }
                {/*quick view ends */}
                {/* deal of the day section starts */}
                <section className="padding-80px-tb dotd-style1 bg-light-gray1" style={{backgroundImage: `url(${imageUtil.images.dealBg})`}}>
                    <div className="container">
                        <div className="row">
                            {/* product1 starts */}
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 deal-of-day-pro">
                                <div className="product-info">
                                    <div className="content-overlay position-relative overflow-hidden">
                                        {/* product1 image starts */}
                                        <div className="product-img color-bg-b text-center">
                                            <img src={images.dealImage1} alt="" className="img-fluid" />
                                        </div>
                                        {/* product1 image ends */}
                                        <div className="content position-absolute opacity-none">
                                            {/* icons starts */}
                                            <ul className="no-padding-left">
                                                <li>
                                                    <span onClick={() => this.props.addToCart(product1)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block"><i className="fas fa-shopping-bag" /></span>
                                                </li>
                                                <li>
                                                    <span onClick={()=>this.handelModal(product1)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block"><i className="fas fa-eye" /></span>
                                                </li>
                                                <li>
                                                    <span onClick={()=> this.props.addToWish(product1)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block"><i className="fas fa-heart" /></span>
                                                </li>
                                            </ul>
                                            {/* icons ends */}
                                        </div>
                                    </div>
                                </div>
                                {/* product1 description starts */}
                                <div className="protext text-center margin-top-10px">
                                    <Link to={`/product/${product1.id}`} className="d-inline-block text-white font-weight-600 hoverstyle1">
                                        {product1.productTitle}
                                    </Link>
                                    <p className="pricetext text-white no-margin-bottom font-weight-600">
                                        ${product1.salePrice && product1.salePrice.toFixed(2)}
                                    </p>
                                </div>
                                {/* product1 description ends */}
                            </div>
                            {/* product1 ends */}
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12 timer">
                                {/* deal of the day heading starts */}
                                <div className="center-col margin-bottom-40px lg-margin-bottom-30px padding-top-40px text-center md-padding-top-10px md-padding-bottom-25px md-no-margin-bottom sm-no-padding-top sm-padding-bottom-15px">
                                    <h3 className="text-black font-weight-800 main-font text-transform lg-title-medium md-title-medium sm-title-mdmore">Deal Of The Day</h3>
                                    <span className="text-black md-text-large main-font font-weight-600 sm-text-large">Come Here Everyday To Grab The Best Deal</span>
                                </div>
                                {/* deal of the day heading ends */}
                                {/* timer starts */}
                                <div className="text-center timer-style1 md-padding-bottom-5px sm-padding-bottom-5px">
                                    <div id="counter-event" data-enddate="2019/12/25 00:00:00" className="width-100">
                                        <div className="counter-container">
                                            <div className="counter-box first">
                                                <div className="number">{days}</div>
                                                <span>Days</span>
                                            </div>
                                            <div className="counter-box">
                                                <div className="number">{hours}</div>
                                                <span>Hours</span>
                                            </div>
                                            <div className="counter-box">
                                                <div className="number">{minutes}</div>
                                                <span>Minutes</span>
                                            </div>
                                            <div className="counter-box last">
                                                <div className="number">{seconds}</div>
                                                <span>Seconds</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* timer ends */}
                                <div className="margin-top-40px lg-margin-top-30px md-no-padding-top md-no-margin-top text-center left-0 right-0 sm-title-middle">
                                    {/* offer description starts */}
                                    <span className="text-white font-weight-600 sm-text-large">Hurry up it's today offers</span>
                                    {/* offer description ends */}
                                </div>
                            </div>
                            {/* product2 starts */}
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 deal-of-day-pro">
                                <div className="product-info">
                                    <div className="content-overlay position-relative overflow-hidden">
                                        {/* product2 image starts */}
                                        <div className="product-img color-bg-b text-center">
                                            <img src={images.dealImage2} alt="img" className="img-fluid" />
                                        </div>
                                        {/* product2 image ends */}
                                        <div className="content position-absolute opacity-none">
                                            {/* icons starts */}
                                            <ul className="no-padding-left">
                                                <li>
                                                    <span onClick={() => this.props.addToCart(product2)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block"><i className="fas fa-shopping-bag" /></span>
                                                </li>
                                                <li>
                                                    <span onClick={() => this.handelModal(product2)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block"><i className="fas fa-eye" /></span>
                                                </li>
                                                <li>
                                                    <span onClick={()=> this.props.addToWish(product2)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block"><i className="fas fa-heart" /></span>
                                                </li>
                                            </ul>
                                            {/* icons ends */}
                                        </div>
                                    </div>
                                </div>
                                {/* product2 description starts */}
                                <div className="protext text-center margin-top-10px">
                                    <Link to={`/product/${product2.id}`} className="d-inline-block text-white font-weight-600 hoverstyle1">
                                        {product2.productTitle}
                                    </Link>
                                    <p className="pricetext text-white no-margin-bottom font-weight-600">
                                        ${product2.salePrice && product2.salePrice.toFixed(2)}
                                    </p>
                                </div>
                                {/* product2 description ends */}
                            </div>
                            {/* product2 ends */}
                        </div>
                    </div>
                </section>
                {/* deal of the day section ends */}
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item)),
    addToWish: (data) => dispatch(addItemToWish(data)),
})

export default connect(
    null,
    mapDispatchToProps
)(DealOfTheDay)
