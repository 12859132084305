import React from "react"
import BreadCrumb from "../GlobalComponents/BreadCrumb"
import {Link} from "react-router-dom"

class SignIn extends React.Component {
    render() {
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Pages" subHeading="Sign In"/>
                {/* breadcrumb section end */}
                {/* sign in section starts */}
                <section className="padding-80px-tb">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10 col-12 center-col gray-border sign-in-content border-radius-5">
                                <div className="sign-in-reg">
                                    {/* sign in options starts */}
                                    <div className="text-center">
                                        <div className="d-inline-block vertical-align-middle sm-display-none" />
                                        <Link to="https://accounts.google.com" className="font-weight-600 border-bottom-khaki text-black text-extra-large md-title-mdall sm-text-extra-large">Sign In </Link>
                                        <div className="d-inline-block vertical-align-middle sm-display-none" />
                                    </div>
                                    <div className="text-center margin-top-10px">
                                        <span className="font-weight-500 text-medium sm-text-large">Enter Your Details Below</span>
                                    </div>
                                    {/* sign in options ends */}
                                    <div className="comment-form">
                                        {/* form strats */}
                                        <div className="row">
                                            <div className="col-md-12 col-12">
                                                <div className="margin-bottom-10px">
                                                    <label htmlFor="exampleInputName" className="sm-text-large">User Name</label>
                                                    <input className="sm-margin-bottom-10px form-control border-radius-5 sm-text-large" id="exampleInputName" type="email" name="name" placeholder="Enter your user name" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className="margin-bottom-15px">
                                                    <label htmlFor="exampleInputEmail1" className="sm-text-large">Password</label>
                                                    <input className="form-control border-radius-5 sm-text-large" id="exampleInputEmail1" type="password" name="name" placeholder="Enter your password" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="sign-button">
                                                    <div className="sign-id">
                                                        <Link to="#" className="btn btn-style1 hoverstyle1"> Sign in</Link>
                                                    </div>
                                                    <div className="fgt-pwd">
                                                        <Link to="forget-password" className="text-black sm-text-large"><u>Forgot Password ?</u></Link>
                                                    </div>
                                                </div>
                                                <div className="form-choice">
                                                    <p>Or Sign In With</p>
                                                    <div className="share-btn">
                                                        <div className="sh-bt">
                                                            <Link to="#" className="btn btn-g-f">
                                                                <i className="fab fa-google" />
                                                                Login With Google
                                                            </Link>
                                                        </div>
                                                        <div className="sh-bt">
                                                            <Link to="#" className="btn btn-g-f">
                                                                <i className="fab fa-facebook-f" />
                                                                Login With Facebook
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* form ends */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* sign in section ends */}
            </>
        )
    }
}

export default SignIn