import React from "react"
import {Link} from "react-router-dom"
import imageUtil from "../../../globalUtility/imageHelper"
import BlogData from "../../../globalUtility/jsonData/blog/BlogDataList";
const images = imageUtil.images

class SideBar extends React.Component {

    state = {
        blogList: [],
        categoryData: []
    }

    componentDidMount() {
        const allBlogCategory = (BlogData.blogList || []).map(p => p.category)

        const result = {}
        for (let i = 0; i < allBlogCategory.length; i++) {
            result[allBlogCategory[i]] = (result[allBlogCategory[i]] || 0) + 1
        }

        this.setState({
            categoryData: Object.keys(result).map(key => ({ [key]: result[key] }))
        })
    }

    componentDidUpdate() {
        window.loadJQuery()
    }

    render() {
        const {categoryData} = this.state
        return (
            <div className="col-xl-3 col-lg-3 col-md-4 col-default-padding left-sidebar">
                {/* search starts */}
                <div className="input-group left-search-top">
                    <input type="text" className="form-control padding-four border-radius-5 text-extra-medium width-80 lg-width-75 md-text-large sm-text-large" name="search" placeholder="Enter search" />
                    <span className="input-group-btn margin-left-5px">
                    <button className="btn btn-default border-radius-5" type="button"><i className="fas fa-search" /></button>
                </span>
                </div>
                {/* search ends */}
                {/* categories starts */}
                <div className="sidebar-content categories">
                    <div className="sidebar-title padding-bottom-15px">
                        <h6 className="d-inline-block border-bottom-khaki font-weight-600 text-extra-large md-title-mdall sm-text-extra-large">Categories</h6>
                    </div>
                    <ul className="list-unstyled no-margin-bottom w-100 text-medium sm-text-large">
                        {
                            categoryData.map((item, index) =>(
                                <li className="margin-top-10px cursor-pointer" key={index}>
                                    {
                                        this.props.isRedirect ?
                                            <Link to={{ pathname: '/blogs/blogs-left-sidebar', state: { categoryType: Object.keys(item)}}} className="font-weight-600 text-black">
                                                {Object.keys(item)} <span className="text-sky-blue">({Object.values(item)})</span>
                                            </Link> :
                                            <span onClick={()=> this.props.handelCategories(Object.keys(item))} className="font-weight-600 text-black">
                                                {Object.keys(item)} <span className="text-sky-blue">({Object.values(item)})</span>
                                            </span>
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </div>
                {/* categories ends */}
                {/* recent posts starts */}
                <div className="sidebar-content">
                    <div className="sidebar-title padding-bottom-15px">
                        <h6 className="d-inline-block border-bottom-khaki font-weight-600 text-extra-large md-title-mdall sm-text-extra-large">Popular Post</h6>
                    </div>
                    <div className="padding-bottom-25px">
                        <div className="sepia-effect margin-bottom-10px">
                            <Link to="#"><img src={images.blogSlider1} className="img-fluid md-width-100 border-radius-5" alt="images" /></Link>
                        </div>
                        <div className="recent-blog-info sm-text-large">
                            <Link to="#" className="font-weight-600 m-0 text-justify text-black" >New Nikon 500jq Rocking The World!</Link>
                            <p className="no-margin-bottom text-gray text-extra-medium"><i className="far fa-calendar-alt padding-right-5px" />29-june-18</p>
                        </div>
                    </div>
                    <div className="second-post-img">
                        <div className="sepia-effect margin-bottom-10px">
                            <Link to="#"><img src={images.blogSlider2} className="img-fluid md-width-100 border-radius-5" alt="images" /></Link>
                        </div>
                        <div className="recent-blog-info sm-text-large">
                            <Link to="#" className="font-weight-600 m-0 text-justify text-black">Football Trend Rising In India</Link>
                            <p className="no-margin-bottom text-gray text-extra-medium"><i className="far fa-calendar-alt padding-right-5px" />14-may-18</p>
                        </div>
                    </div>
                </div>
                {/* recent posts ends */}
                {/* recent blog start */}
                <div className="sidebar-content">
                    <div className="sidebar-title padding-bottom-15px">
                        <h6 className="d-inline-block border-bottom-khaki font-weight-600 text-extra-large md-title-mdall sm-text-extra-large">Recent Blog</h6>
                    </div>
                    <div className="owl-carousel owl-theme" id="recent-blog">
                        <div className="item">
                            <div className="recent-blog-post">
                                <Link to="#"><img src={images.recentBlog1} alt="images" /></Link>
                            </div>
                            <div className="recent-blog-p-content">
                                <Link to="#" className="text-black">Lorem Ipsum is simply dummy text</Link>
                                <p className="no-margin-bottom text-gray text-extra-medium"><i className="far fa-calendar-alt padding-right-5px" />25 Dec 2019</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="recent-blog-post">
                                <Link to="#"><img src={images.recentBlog2} alt="images" /></Link>
                            </div>
                            <div className="recent-blog-p-content">
                                <Link to="#" className="text-black">Lorem Ipsum is simply dummy text</Link>
                                <p className="no-margin-bottom text-gray text-extra-medium"><i className="far fa-calendar-alt padding-right-5px" />24 Dec 2019</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* recent blog end */}
                {/* hash tags starts */}
                <div className="sidebar-content">
                    <div className="sidebar-title padding-bottom-15px">
                        <h6 className="d-inline-block border-bottom-khaki font-weight-600 text-extra-large md-title-mdall sm-text-extra-large">Tags</h6>
                    </div>
                    <div className="tag">
                        <ul className="no-margin-bottom p-0 text-medium sm-text-large">
                            <li className="list-unstyled p-2 bg-black border-radius-5 d-inline-block"><Link to="#" className="text-black font-weight-500">#marina</Link></li>
                            <li className="list-unstyled p-2 bg-black border-radius-5 d-inline-block"><Link to="#" className="text-black font-weight-500">#bay</Link></li>
                            <li className="list-unstyled p-2 bg-black border-radius-5 d-inline-block"><Link to="#" className="text-black font-weight-500">#mvc</Link></li>
                            <li className="list-unstyled p-2 margin-top-5px bg-black border-radius-5 d-inline-block"><Link to="#" className="text-black font-weight-500">#puppy</Link></li>
                            <li className="list-unstyled p-2 margin-top-5px bg-black border-radius-5 d-inline-block"><Link to="#" className="text-black font-weight-500">#respect</Link></li>
                            <li className="list-unstyled p-2 margin-top-5px bg-black border-radius-5 d-inline-block"><Link to="#" className="text-black font-weight-500">#flix</Link></li>
                        </ul>
                    </div>
                    {/* hash tags ends */}
                </div>
            </div>
        );
    }
}
export default SideBar
