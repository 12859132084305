import {
    ADD_TO_COMPARE_PRODUCT, END_ADD_TO_COMPARE, REMOVE_TO_COMPARE_PRODUCT,
} from '../constants/actionTypes';

const initialState = {
    compareListData: []
}

const productCompareReducer = (state = initialState , action) => {
    switch (action.type) {
        case ADD_TO_COMPARE_PRODUCT: {
            return {
                ...state,
                productCompareList: (action.compareListData),
            }
        }
        case REMOVE_TO_COMPARE_PRODUCT: {
            return {
                ...state,
                productCompareList: (action.compareListData) || [],
            }
        }
        case END_ADD_TO_COMPARE: {
            return {
                ...state,
                errorMsg: action.errorMsg,
            }
        }
        default:
            return state
    }
}
export default productCompareReducer
