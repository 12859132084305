import React from "react";
import {Link, withRouter} from "react-router-dom";
import BreadCrumb from "../../GlobalComponents/BreadCrumb";
import ProductDataList from "../../globalUtility/jsonData/product/ProductDataList";
import ProductViewModal from "../../globalUtility/ProductViewModal";
import Pagination from "../../GlobalComponents/Pagination";
import NotFoundData from "../../Pages/NotFoundData";
import titleUpperCase from "../../globalUtility/commonHelper"
import {addItemToCart} from "../../actions/cartAction";
import {connect} from "react-redux";
import {addItemToWish} from "../../actions/wishListAction";
const productList = (ProductDataList.productList) || []

class Categories extends React.Component {

    state = {
        isModal: false,
        productPreview: {},
        productDataList: []
    }

    subHeading = (data) => (data && data.split("/").pop()) || ""

    componentWillMount() {
        const {location} = this.props
        const category = this.subHeading(location.pathname)
        const categoryData = (productList && productList.length && category) ? productList.filter(alr => alr.subCategory === category) : []
        this.setState({
            categoryData,
            category
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            const category = this.subHeading(nextProps.location.pathname)
            const categoryData = (productList && productList.length && category) ? productList.filter(alr => alr.subCategory === category) : []
            this.setState({
                categoryData,
                category
            })
        }
     }

    myModel = (data) =>  {
        this.setState({
            isModal: !this.state.isModal,
            productPreview: data.productTitle ? data : {}
        })
    }

    onChangePage = (pageOfItems) => {
        this.setState({
            productDataList: pageOfItems
        });
    }

    render() {
        const {categoryData, category, productPreview, isModal, productDataList} = this.state
        const subHead = category === "clothes" ? "Clothes" : category === "footwear" ? "Footwear" : category === "kitchen" ? "Kitchen" :
            category === "accessories" ? "Mobile Accessories": category === "furniture" ? "Furniture" : ""
        return(
            <div>
                <ProductViewModal
                    isModal={isModal}
                    productPreview={productPreview}
                    handelModal={this.myModel}/>
                {/* breadcrumb section start */}
                <BreadCrumb heading="Categories" subHeading={subHead}/>
                {/* breadcrumb section end */}
                {/* products section starts */}
                <section className="exproducts-style1 padding-80px-tb md-padding-60px-tb">
                    <div className="container mt-md-0">
                        <div className="row">
                            {/* product1 starts */}
                            {
                                productDataList.map((item, index) =>{
                                    return(
                                        <div key={index} className="col-xl-3 col-lg-3 col-md-3 col-6">
                                            <div className="product-info">
                                                <div className="content-overlay position-relative overflow-hidden">
                                                    {/* product1 image starts */}
                                                    <div className="product-img color-bg-b text-center">
                                                        <img src={item.pictures && item.pictures[0]} alt={item.pictures && item.pictures[0]} className="img-fluid" />
                                                        {item.new && <div className="new-offer bg-green">New</div>}
                                                        {item.bestFeature && <div className="new-offer bg-light-blue">Best Feature</div>}
                                                        {item.trade  && <div className="new-offer bg-light-red">Trade</div>}
                                                    </div>
                                                    {/* product1 image ends */}
                                                    <div className="content position-absolute opacity-none">
                                                        {/* icons starts */}
                                                        <ul className="no-padding-left">
                                                            <li>
                                                                <span onClick={() => this.props.addToCart(item)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block">
                                                                    <i className="fas fa-shopping-bag" />
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span onClick={() => this.myModel(item)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block">
                                                                    <i className="fas fa-eye" />
                                                                </span>
                                                            </li>
                                                            <li >
                                                                <span onClick={() => this.props.addToWish(item)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block">
                                                                    <i className="fas fa-heart" />
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        {/* icons ends */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* product1 description starts */}
                                            <div className="protext text-center margin-top-10px">
                                                <Link to={`/product/${item.id}`} className="d-inline-block text-dark-gray font-weight-600 hoverstyle1">
                                                    {titleUpperCase(item.productTitle)}
                                                </Link>
                                                <p className="pricetext font-weight-600">
                                                    ${item.salePrice && item.salePrice.toFixed(2)}
                                                </p>
                                            </div>
                                            {/* product1 description ends */}
                                        </div>
                                    )
                                })
                            }
                            {!(productDataList && productDataList.length) && <NotFoundData/>}
                        </div>
                        <Pagination
                            pageSize={8}
                            sortType=""
                            items={categoryData}
                            onChangePage={this.onChangePage} />
                    </div>
                </section>
                {/* products section ends */}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item)),
    addToWish: (data) => dispatch(addItemToWish(data)),
})

export default withRouter(connect(
    null,
    mapDispatchToProps
)(Categories));
