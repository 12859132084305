import React from "react"
import imageUtil from "../globalUtility/imageHelper";


class NotFoundData extends React.Component{
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="not-found">
                            <img src={imageUtil.images.emptySearch} alt="not found"/>
                            <p>Sorry! Couldn't find the product you were looking For!!!</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFoundData
