import React from "react"
import BreadCrumb from "../GlobalComponents/BreadCrumb"
import {Link} from "react-router-dom"

class SignUp extends React.Component {
    render() {
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Pages" subHeading="Sign Up"/>
                {/* breadcrumb section end */}
                {/* sign up section starts */}
                <section className="padding-80px-tb">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10 center-col col-12 sign-up-content border-radius-5">
                                <div className="sign-up-reg">
                                    {/* sign in options starts */}
                                    <div className="text-center">
                                        <Link target="_blank" to="https://accounts.google.com" className="font-weight-600 text-black border-bottom-khaki text-extra-large md-title-mdall sm-text-extra-large">Sign Up</Link>
                                    </div>
                                    <div className="text-center margin-top-10px">
                                        <span className="font-weight-500 text-medium sm-text-large">Enter Your Details Below</span>
                                    </div>
                                    {/* sign in options ends */}
                                    <div className="comment-form">
                                        {/* form strats */}
                                        <div className="row">
                                            <div className="col-md-12 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputName" className="sm-text-large">User Name</label>
                                                    <input className="sm-margin-bottom-10px form-control border-radius-5 p-2" id="exampleInputName" type="text" name="name" placeholder="Your User Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1" className="sm-text-large">Email</label>
                                                    <input className="sm-margin-bottom-10px form-control border-radius-5 p-2" id="exampleInputEmail1" type="email" name="name" placeholder="Your Email Address" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword" className="sm-text-large">Password</label>
                                                    <input className="form-control border-radius-5 p-2" type="password" id="exampleInputPassword" name="name" placeholder="Your Password" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputCpassword" className="sm-text-large">Confirm Password</label>
                                                    <input className="form-control border-radius-5 p-2" id="exampleInputCpassword" type="password" name="name" placeholder="Re-type Your Password" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="sign-up">
                                                    <div className="sign-link">
                                                        <Link to="#" className="btn btn-style1 hoverstyle1">Sing Up</Link>
                                                    </div>
                                                    <div className="sign-link">
                                                        <Link to="/sign-in" className="text-black sm-text-large">Have You Already Account ?</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* form end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* sign up section ends */}

            </>
        )
    }

}

export default SignUp;