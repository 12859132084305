import React from "react";
import {Link} from "react-router-dom";
import imageUtil from "../../globalUtility/imageHelper";

class NewsLettersPopUp extends React.Component {
    render() {
        const dataImage = imageUtil.images;
        return (
            <div className="modal fade popup-bg" id="popup-style1">
                <div className="modal-dialog popup-style1">
                    <div className="modal-content">
                        <div className="border-radius-5 position-relative">
                            <button className="close" data-dismiss="modal"><i className="fas fa-times"/></button>
                            <div className="row popup-img">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <img src={dataImage.newsletterBg1} className="img-fluid" alt="newsletter-bg1"/>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 popup-newsletter">
                                    <div className="popup-content widget_blog_subscription">
                                        <div className="position-relative">
                                            <div className="padding-bottom-10px">
                                                {/*<!-- popup newsletters starts -->*/}
                                                <h3 className="text-black font-weight-800 main-font text-transform lg-title-medium md-title-medium sm-title-mdmore sm-margin-top-10px">Newsletter</h3>
                                                {/*<!-- popup newsletters ends -->*/}
                                                <span
                                                    className="text-black main-font font-weight-600 md-text-large sm-text-large">Subscribe To Our Newsletter To Get Important Updates</span>
                                            </div>
                                            <form className="newsletter-form">
                                                {/*<!-- popup getupdates starts -->*/}
                                                {/* popup getupdates starts */}
                                                <div className="form-group">
                                                    <input type="email" name="email" placeholder="Enter your email-id here" data-field="required" id="email" className="d-inline-block border-radius-5 margin-bottom-15px form-control" />
                                                    <Link to="#" className="btn btn-style1 d-inline-block right-0 top-0 subscribe-submit" id="newsletters-submit">Get Updates</Link>
                                                </div>
                                                {/* popup getupdates ends */}
                                                {/*<!-- popup getupdates ends -->*/}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsLettersPopUp
