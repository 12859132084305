import React from "react"
import Rating from "./Rating";
import moment from 'moment';
import {toast} from "react-toastify";
import {addItemToCart} from "../../../actions/cartAction";
import {connect} from "react-redux";

class ProductDetailsView extends React.Component{
    state = {
        reviewDescription: "",
        reviewTitle: "",
        email: "",
        name: "",
        starsSelected: 0,
        productDetails: {},
        reviewError: {}
    }

    componentWillMount() {
        let data = localStorage.getItem("productList");
        const currentId = Number(window.location && window.location.pathname && window.location.pathname.split("/")[2])
        let commonData = JSON.parse(data) || []
        const reviewList = commonData.filter(p => p.id === currentId)
        this.setState({
            productDetails: (reviewList && reviewList[0]) || {}
        })
    }

    handelOnChange = (e) =>{
        const {name, value} = e.target
        this.setState({
            [name]: value
        })
    }

    errorValidation = (name, value) => {
        switch (name) {
            case "name":
                if (!value) {
                    return "Name is require"
                } else {
                    return null
                }
            case "email":
                if (!value) {
                    return "Email  is require"
                }else if (!value.match(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)) {
                    return "Enter  a valid  Email "
                } else {
                    return null
                }
            case "reviewTitle":
                if (!value) {
                    return "Review Title is require"
                } else {
                    return null
                }
            default:
                return  null
        }
    };

    handleSubmit = () =>{
        let allError = {}
        const { reviewDescription, reviewTitle, email, name, starsSelected } = this.state
        const currentId = Number(window.location && window.location.pathname && window.location.pathname.split("/")[2])

        const itemData = {
            name: name,
            email: email,
            reviewTitle: reviewTitle,
            reviewDescription: reviewDescription,
            ratingStar: starsSelected,
            date: new Date()
        }

        Object.keys(itemData).forEach(key => {
            const error = this.errorValidation(key, itemData[key]);
            if (error) {
                allError[key] = error;
            }});
        if (Object.keys(allError).length) {
            this.setState({reviewError:allError})
        } else {
            let data = localStorage.getItem("productList");
            let dataItem = JSON.parse(data) || []
            let index = dataItem.findIndex(x => x.id === currentId);
            dataItem[index].customerReview.push(itemData)
            const reviewList = dataItem.filter(p => p.id === currentId)
            localStorage.setItem('productList', JSON.stringify(dataItem));
            this.setState({
                productDetails: (reviewList && reviewList[0]) || {},
                reviewDescription: "",
                reviewTitle: "",
                email: "",
                name: "",
                starsSelected: 0,
                reviewError: {}
            },()=>{
                toast.success(`Successfully review submit`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }
    }

    render() {
        const {reviewDescription, reviewTitle, email, name, starsSelected, productDetails,reviewError} = this.state
        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-center sm-text-center">
                            <ul className="nav nav-pills nav-pills-style-1 margin-top-40px lg-margin-top-30px md-margin-top-25px sm-margin-top-20px sm-justify-content" role="tablist">
                                {/* description starts */}
                                <li className="nav-item">
                                    <a className="nav-link text-black-ni active font-weight-600" data-toggle="pill" href="#desc">Description &amp; Overview</a>
                                </li>
                                {/* description ends */}
                                {/* customer review starts */}
                                <li className="nav-item padding-left-10px sm-no-padding-left">
                                    <a className="nav-link text-black font-weight-600" data-toggle="pill" href="#cust">Customer Review</a>
                                </li>
                                {/* customer review ends */}
                                {/* customer review starts */}
                                <li className="nav-item padding-left-10px sm-no-padding-left">
                                    <a className="nav-link text-black font-weight-600" data-toggle="pill" href="#size-chart">Size Chart</a>
                                </li>
                                {/* customer review ends */}
                                {/* customer review starts */}
                                <li className="nav-item padding-left-10px sm-no-padding-left">
                                    <a className="nav-link text-black font-weight-600" data-toggle="pill" href="#top-review">Write Review</a>
                                </li>
                                {/* customer review ends */}
                            </ul>
                        </div>
                        <div className="tab-content">
                            {/* description section starts */}
                            <div className="margin-top-20px tab-pane active" id="desc">
                                <p className="sm-text-large no-margin-bottom">
                                    {productDetails.description}
                                </p>
                            </div>
                            {/* description section ends */}
                            <div  className="margin-top-20px tab-pane" id="cust">
                                {
                                    ((productDetails && productDetails.customerReview) || []).map((item, index) =>{
                                        return(
                                            <div key={index} className="product-rating align-items-center">
                                                <div className="d-inline-block vertical-align-middle">
                                                    <div className="d-inline-block position-relative bottom-4">
                                                        <span className="font-weight-600 vertical-align-top">
                                                            {item.reviewTitle}
                                                        </span>
                                                    </div>
                                                    {/* rating star start */}
                                                    <Rating ratingStars={item.ratingStar}/>
                                                    {/* rating star endt */}
                                                </div>
                                                <div className="review-description">
                                                    <i className="fas fa-quote-left text-sky-blue fa-1x" />
                                                    <p className="sm-text-large">
                                                        {item.reviewDescription}
                                                    </p>
                                                </div>
                                                <div className="blockquote-footer">
                                                    Published on {moment(item.date).format("Do MMM YYYY")}
                                                </div>
                                                <div className="review-name">
                                                    <i className="far fa-user" />
                                                    <span>by {item.name}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {!(productDetails && productDetails.customerReview && productDetails.customerReview.length) &&
                                <p>Customer Review not found</p>}
                            </div>

                            <div className="modal-content size-chart-content tab-pane" id="size-chart">
                                <div className="modal-header">
                                    <h5 className="modal-title">Size chart</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">×</span> </button></div>
                                <div className="modal-body">
                                    <div className="content-item">
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th scope="col">A&amp;F</th>
                                                    <th scope="col">A&amp;F Sized</th>
                                                    <th scope="col">Bust(in)</th>
                                                    <th scope="col">Bust(cm)</th>
                                                    <th scope="col">EU</th>
                                                    <th scope="col">Italy</th>
                                                    <th scope="col">Japan</th>
                                                    <th scope="col">UK</th>
                                                    <th scope="col">China</th>
                                                    <th scope="col">Korea</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <th scope="row" rowSpan={2}>XXS</th>
                                                    <td>000</td>
                                                    <td>30- 31</td>
                                                    <td>76 - 79</td>
                                                    <td>32</td>
                                                    <td>36</td>
                                                    <td>3</td>
                                                    <td>4</td>
                                                    <td>155/80A</td>
                                                    <td rowSpan={2}>44</td>
                                                </tr>
                                                <tr>
                                                    <td>00</td>
                                                    <td>32</td>
                                                    <td>81</td>
                                                    <td>34</td>
                                                    <td>38</td>
                                                    <td>5</td>
                                                    <td>6</td>
                                                    <td>160/80A</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" rowSpan={2}>XS</th>
                                                    <td>0</td>
                                                    <td>33</td>
                                                    <td>84</td>
                                                    <td rowSpan={2}>36</td>
                                                    <td rowSpan={2}>40</td>
                                                    <td rowSpan={2}>7</td>
                                                    <td rowSpan={2}>8</td>
                                                    <td>160/84A</td>
                                                    <td>44</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>34</td>
                                                    <td>86</td>
                                                    <td>165/84A</td>
                                                    <td>55</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" rowSpan={2}>S</th>
                                                    <td>4</td>
                                                    <td>35</td>
                                                    <td>89</td>
                                                    <td rowSpan={2}>38</td>
                                                    <td rowSpan={2}>42</td>
                                                    <td rowSpan={2}>9</td>
                                                    <td rowSpan={2}>10</td>
                                                    <td>165/88A</td>
                                                    <td rowSpan={2}>55</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>36</td>
                                                    <td>91</td>
                                                    <td>165/92A</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" rowSpan={2}>M</th>
                                                    <td>8</td>
                                                    <td>37</td>
                                                    <td>94</td>
                                                    <td>40</td>
                                                    <td>44</td>
                                                    <td>11</td>
                                                    <td>12</td>
                                                    <td>170/92A</td>
                                                    <td rowSpan={2}>66</td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>37</td>
                                                    <td>97</td>
                                                    <td>42</td>
                                                    <td>46</td>
                                                    <td>13</td>
                                                    <td>14</td>
                                                    <td>170/96A</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" rowSpan={2}>L</th>
                                                    <td>12</td>
                                                    <td>40</td>
                                                    <td>102</td>
                                                    <td>42</td>
                                                    <td>46</td>
                                                    <td>13</td>
                                                    <td>14</td>
                                                    <td>175/104A</td>
                                                    <td rowSpan={2}>77</td>
                                                </tr>
                                                <tr>
                                                    <td>14</td>
                                                    <td>41</td>
                                                    <td>104</td>
                                                    <td>44</td>
                                                    <td>48</td>
                                                    <td>15</td>
                                                    <td>16</td>
                                                    <td>175/104A</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">XL</th>
                                                    <td>16</td>
                                                    <td>43 - 44</td>
                                                    <td>109 - 102</td>
                                                    <td>46</td>
                                                    <td>50</td>
                                                    <td>17</td>
                                                    <td>18</td>
                                                    <td>175/112A</td>
                                                    <td>88</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade customer-top-review" id="top-review" role="tabpanel" aria-labelledby="review-top-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="media">
                                            <label>Rating</label>
                                            <div className="media-body">
                                                <div className="rating">
                                                    <div className="rate">
                                                        {[1,2,3,4,5].map((n, i) =>
                                                            <label key={i}
                                                               className={(i < starsSelected) ? "fas fa-star text-rating" : "fas fa-star text-gray"}
                                                               onClick={() => this.setState({starsSelected: i+1})}/>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <span className="text-danger">{reviewError.name}</span>
                                        <label htmlFor="name">Name</label>
                                        <input type="text"
                                               className="form-control"
                                               name="name"
                                               value={name}
                                               onChange={this.handelOnChange}
                                               placeholder="Enter Your name" required />
                                    </div>
                                    <div className="col-md-6">
                                        <span className="text-danger">{reviewError.email}</span>
                                        <label htmlFor="email">Email</label>
                                        <input type="text"
                                               className="form-control"
                                               name="email"
                                               value={email}
                                               onChange={this.handelOnChange}
                                               placeholder="Email"
                                               required />
                                    </div>
                                    <div className="col-md-12">
                                        <span className="text-danger">{reviewError.reviewTitle}</span>
                                        <label htmlFor="review">Review Title</label>
                                        <input type="text"
                                               className="form-control"
                                               name="reviewTitle"
                                               value={reviewTitle}
                                               onChange={this.handelOnChange}
                                               placeholder="Enter your Review Subjects"
                                               required />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="review">Review Description</label>
                                        <textarea className="form-control"
                                                  placeholder="Wrire Your Testimonial Here"
                                                  name="reviewDescription"
                                                  value={reviewDescription}
                                                  onChange={this.handelOnChange}
                                                  rows={6} />
                                    </div>
                                    <div className="col-md-12">
                                        <button className="btn btn-style1" onClick={this.handleSubmit}>Submit YOur Review</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item))
})

export default connect(
    null,
    mapDispatchToProps
)(ProductDetailsView)
