import React,{Component} from "react";
import BreadCrumb from "../GlobalComponents/BreadCrumb"
import { Link } from "react-router-dom";
class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            contactError: {}
        };
    }
    handleChange =(e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    errorValidation = (name, value) => {
        switch (name) {
            case "firstName":
                if (!value) {
                    return "First Name is require"
                } else if(value.length<3) {
                    return 'enter a valid "First Name '
                } else {
                    return null
                }
            case "lastName":
                if (!value) {
                    return "Last Name is require"
                } else {
                    return null
                }
            case "phone":
                if (!value) {
                    return "PhoneNumber  is require"
                } else if (!value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
                    return " enter a valid PhoneNumber "
                } else {
                    return null
                }
            case "email":
                if (!value) {
                    return "E-mail  is require"
                }else if (!value.match(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)) {
                    return "enter  a valid  E-mail "
                } else {
                    return null
                }
            default:
                return  null
        }
    };
    contactValidation = () => {
        const {firstName, lastName, email, phone} = this.state
        const allError = {}
        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone
        }
        Object.keys(data).forEach(key => {
            const error = this.errorValidation(key, data[key]);
            if (error) {
                allError[key] = error;
            }});
        if (Object.keys(allError)) {
            this.setState({contactError:allError})
        } else {

        }
    }

    render() {
        const {firstName,lastName,email,phone,contactError} = this.state
        return (
            <div>
                <BreadCrumb heading="Home" subHeading="Contact-Us"/>
                <section className="padding-80px-tb">
                    <div className="container">
                        <div className="row">
                            {/*<!-- goolge map location starts -->*/}
                            <div className="col-xl-6 col-md-6">
                                <div className="mapouter position-relative map-style1">
                                    <div className="gmap_canvas">
                                        <iframe title="map" id="gmap_canvas"
                                                src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=17&ie=UTF8&iwloc=&output=embed"/>
                                        <Link target="_Blank" to="https://www.pureblack.de"/>
                                        <i className="fas fa-2x fa-map-marker-alt text-black top-50p left-0 right-0 text-center position-absolute"/>
                                    </div>
                                </div>
                            </div>
                            {/*<!-- goolge map location ends -->*/}
                            {/*<!--  company contact info starts -->*/}
                            <div className="col-xl-6 col-md-6 d-flex sm-margin-top-20px">
                                <div className="border-radius-5 comment-form">
                                    {/*<!-- form starts -->*/}
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6 col-12 margin-bottom-10px">
                                            <label htmlFor="exampleInputName" className="text-black">First Name</label>
                                            <input className="form-control border-radius-5"  value={firstName}onChange={this.handleChange} id="exampleInputName"
                                                   type="text" name="firstName" placeholder="Your first name"/>
                                            <span className= "text-danger">{contactError.firstName}</span>
                                        </div>
                                        <div className="col-xl-6 col-md-6 col-12 margin-bottom-10px">
                                            <label htmlFor="exampleInputLname" className="text-black">Last Name</label>
                                            <input className="form-control border-radius-5"  value={lastName} onChange={this.handleChange}id="exampleInputLname"
                                                   type="text" name="lastName" placeholder="Your last name"/>
                                            <span className= "text-danger">{contactError.lastName}</span>
                                        </div>
                                        <div className="col-xl-6 col-md-6 col-12 margin-bottom-10px">
                                            <label htmlFor="exampleInputEmail1" className="text-black">E-mail</label>
                                            <input className="form-control border-radius-5"  value={email} onChange={this.handleChange} type="email"
                                                   id="exampleInputEmail1" name="email" placeholder="Your email address"/>
                                            <span className= "text-danger">{contactError.email}</span>
                                        </div>
                                        <div className="col-xl-6 col-md-6 col-12 margin-bottom-15px">
                                            <label htmlFor="exampleInputPhone" className="text-black">Phone No</label>
                                            <input className="form-control border-radius-5"  value = {phone} onChange={this.handleChange}id="exampleInputPhone"
                                                   type="tel" name="phone" placeholder="Phone no"/>
                                            <span className= "text-danger">{contactError.phone}</span>
                                        </div>
                                        <div className="col-xl-12 col-md-12 commentstyle-1 margin-bottom-15px">
                                            <input className="comment border-radius-5 form-control" name="description"
                                                   placeholder="Write your comment"/>
                                        </div>
                                        <div className="col-xl-12 col-md-12 margin-bottom-15px">
                                            <div className="text-left">
                                                <Link to="#" onClick={ this.contactValidation} className="btn btn-style1 hoverstyle1">Submit</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<!-- form ends -->*/}
                                    <div className="border-bottom-medium-dark margin-bottom-10px"/>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                            {/*<!-- contact info starts -->*/}
                                            <div className="contact-info">
                                                <h5 className="font-weight-600 text-sky-blue text-large sm-text-extra-large">Contact
                                                    Info</h5>
                                                <p className="margin-bottom-10px sm-text-large sm-margin-bottom-10px">There
                                                    are many variations of passages of Lorem Ipsum available, but the
                                                    majority have suffered alteration in some.</p>
                                            </div>
                                            {/*<!-- contact info ends -->*/}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="border-bottom-medium-dark margin-bottom-10px"/>
                                                    <h5 className="font-weight-600 margin-bottom-10px text-large sm-text-extra-large">How
                                                        we can help you?</h5>
                                                </div>
                                                {/*<!-- email starts -->*/}
                                                <div
                                                    className="col-xl-4 col-lg-4 col-md-12 border-right-medium-dark md-border-right-0px">
                                                    <div className="contact-icon">
                                                        <i className="far fa-envelope text-sky-blue vertical-align-middle text-extra-large"/>
                                                    </div>
                                                    <div className="address">
                                                        <a href="mailto:demo@demo.com"
                                                           className="text-black hoverstyle1 sm-text-large">demo@demo.com</a>
                                                    </div>
                                                </div>
                                                {/*<!-- email ends -->*/}
                                                {/*<!-- phone starts -->*/}
                                                <div
                                                    className="col-xl-4 col-lg-3 col-md-12 border-right-medium-dark md-border-right-0px">
                                                    <div className="contact-icon">
                                                        <i className="fas fa-phone-volume text-sky-blue text-extra-large"/>
                                                    </div>
                                                    <div className="address">
                                                        <a href="tel:+6494461709"
                                                           className="text-black hoverstyle1 sm-text-large">+6494461709</a>
                                                    </div>
                                                </div>
                                                {/*<!-- phone ends -->*/}
                                                {/*<!-- location starts -->*/}
                                                <div className="col-xl-4 col-lg-5 col-md-12">
                                                    <div className="contact-icon">
                                                        <i className="fas fa-map-marker-alt text-sky-blue text-extra-large"/>
                                                    </div>
                                                    <div className="address sm-text-large">Demo Store On Road 10 United
                                                        States
                                                    </div>
                                                </div>
                                                {/*<!-- location starts -->*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<!--  company contact info ends -->*/}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default ContactUs






