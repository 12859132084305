import React from "react";
import BreadCrumb from "../GlobalComponents/BreadCrumb";
import PageDescription from "../globalUtility/jsonData/description/PageDescription"

class PrivacyPolicy extends React.Component {
    render() {
        const privacyPolicy = (PageDescription && PageDescription.privacyPolicy) || []
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Home" subHeading="Privacy Policy"/>
                {/* breadcrumb section end */}
                <section className="padding-80px-tb">
                    <div className="container">
                        <div className="row privacy-policy-content">
                            {/* policy rule starts */}
                            <div className="col-12">
                                <ul className="border-radius-5">
                                    <li className="text-sky-blue">
                                        <p className="text-black sm-text-large">{privacyPolicy[0]}</p>
                                    </li>
                                    <li className="text-sky-blue">
                                        <p className="text-black sm-text-large">{privacyPolicy[1]}</p>
                                    </li>
                                    <li className="text-sky-blue">
                                        <p className="text-black sm-text-large">{privacyPolicy[2]}</p>
                                    </li>
                                    <li className="text-sky-blue">
                                        <p className="text-black sm-text-large">{privacyPolicy[3]}</p>
                                    </li>
                                    <li className="text-sky-blue sm-text-large">
                                        <p className="text-black">{privacyPolicy[4]}</p>
                                    </li>
                                </ul>
                            </div>
                            {/* policy rule ends */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default PrivacyPolicy
