import React from 'react';
import {connect} from "react-redux";
import { Switch, Route} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"
import Header from "./Layout/Header";
import Slider from "./Layout/Slider";
import ServiceSection from "./Layout/ServiceSection";
import Deals from "./Layout/Deals";
import ExclusiveProducts from "./Layout/ExclusiveProducts";
import Footer from "./Layout/Footer";
import SaleAndNewsLetter from "./Layout/SaleAndNewsLetter";
import BestBrands from "./Layout/BestBrands";
import DealOfTheDay from "./Layout/DealOfTheDay";
import Categories from "./Components/Category/Categories";
import CartPage from "./Pages/CartPage";
import Checkout from "./Pages/Checkout";
import ForgetPassword from "./Pages/ForgetPassword";
import OrderComplete from "./Pages/OrderComplete";
import TrackOrder from "./Pages/TrackOrder";
import SignIn from "./Pages/SignIn";
import SignOut from "./Pages/SignOut";
import SignUp from "./Pages/SignUp";
import WishList from "./Pages/WishList";
import ComingSoon from "./Pages/ComingSoon";
import PageNotFound from "./Pages/PageNotFound";
import Cancellation from "./Pages/Cancellation";
import FrequentlyAskedQuestions from "./Pages/FrequentlyAskedQuestions";
import NewsLetters from "./Pages/NewsLetters";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import SiteMap from "./Pages/SiteMap";
import TermsAndConditions from "./Pages/TermsAndConditions";
import RoutesList from "./globalUtility/RoutesList";
import ContactUs from "./Pages/ContactUs"
import ScrollToTop from "./globalUtility/ScrollToTop";
import Home1 from "./Layout/home1/Home1";
import NewsLettersPopUp from "./Layout/home1/NewsLettersPopUp"
import {ADD_TO_COMPARE_PRODUCT, SET_PRODUCT_LIST} from "./constants/actionTypes";
import WebSetting from "./Layout/WebSetting";
import {productList} from "./globalUtility/jsonData/product/ProductDataList";

class App extends React.Component {

    componentDidMount(){

        let productListdata = localStorage.getItem("productList");
        const compare = localStorage.getItem("compareList")

        const compareListData = JSON.parse(compare) || []
        let commonProductListData = JSON.parse(productListdata) || []
        if(!commonProductListData.length){
            localStorage.setItem('productList', JSON.stringify(productList));
        }
        this.props.setCompareList(compareListData)
        if(!(this.props.productListData && this.props.productListData.length)){
            this.props.setProductList({productList})
        }
    }

    componentDidUpdate() {
        window.loadJQuery()
    }

    render() {
        let url = window.location.href.split("/")
        const urlPath = url && url[3]
        const notHeaderFooter = ["coming-soon"]
        const isHeader = (notHeaderFooter.indexOf(urlPath) !== -1) ? null : <Header/>
        const isFooter = (notHeaderFooter.indexOf(urlPath) !== -1) ? null : <Footer/>
        const routes = RoutesList
        return (
            <div>
              <ScrollToTop/>
              <WebSetting/>
              <ToastContainer
                  className="toast-container"
                  autoClose={8000} />
              {isHeader}
              <Switch>
                <Route exact path="/">
                  <NewsLettersPopUp/>
                  <Slider/>
                  <ServiceSection/>
                  <Deals/>
                  <ExclusiveProducts/>
                  <DealOfTheDay/>
                  <BestBrands/>
                  <SaleAndNewsLetter/>
                </Route>
                <Route exact path="/category/:type">
                  <Categories/>
                </Route>
                <Route exact path="/contact-us">
                  <ContactUs/>
                </Route>
                <Route exact path="/home1">
                  <NewsLettersPopUp/>
                  <Home1/>
                </Route>
                {
                  routes && routes.blogsRoutes.map((route, i) => (
                      <Route
                          key={i}
                          exact={route.exact}
                          path={route.path}
                          render={props => (
                              <route.component {...props} />
                          )}
                      />
                  ))
                }

                {
                  routes && routes.productsRoutes.map((route, i) => (
                      <Route
                          key={i}
                          exact={route.exact}
                          path={route.path}
                          render={props => (
                              <route.component {...props} />
                          )}
                      />
                  ))
                }
                <Route exact path="/product/:id">
                  <CartPage/>
                </Route>

                <Route exact path="/cart-page">
                  <CartPage/>
                </Route>
                <Route exact path="/checkout">
                  <Checkout/>
                </Route>
                <Route exact path="/forget-password">
                  <ForgetPassword/>
                </Route>
                <Route exact path="/order-complete">
                  <OrderComplete/>
                </Route>
                <Route exact path="/track-order">
                  <TrackOrder/>
                </Route>
                <Route exact path="/sign-in">
                  <SignIn/>
                </Route>
                <Route exact path="/sign-out">
                  <SignOut/>
                </Route>
                <Route exact path="/sign-up">
                  <SignUp/>
                </Route>
                <Route exact path="/wishlist">
                  <WishList/>
                </Route>
                <Route exact path="/page-not-found">
                  <PageNotFound/>
                </Route>
                <Route exact path="/coming-soon">
                  <ComingSoon/>
                </Route>
                <Route exact path="/cancellation">
                  <Cancellation/>
                </Route>
                <Route exact path="/faq's">
                  <FrequentlyAskedQuestions/>
                </Route>
                <Route exact path="/newsletters">
                  <NewsLetters/>
                </Route>
                <Route exact path="/privacy-policy">
                  <PrivacyPolicy/>
                </Route>
                <Route exact path="/site-map">
                  <SiteMap/>
                </Route>
                <Route exact path="/terms-and-conditions">
                  <TermsAndConditions/>
                </Route>
                <Route>
                  <PageNotFound/>
                </Route>
              </Switch>
              {isFooter}
            </div>
        )
  }
}

const mapDispatchToProps = dispatch => ({
  setProductList: (productData) => dispatch({type: SET_PRODUCT_LIST, productData}),
  setCompareList: (productData) => dispatch({type: ADD_TO_COMPARE_PRODUCT, compareListData: productData}),
});

export default connect(
    null,
    mapDispatchToProps
)(App)
