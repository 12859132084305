                          /*Index*/
import macroLogo from '../images/logo.png'

import sliderImage1 from '../images/sliderimage1.jpg'
import sliderImage2 from '../images/sliderimage2.jpg'
import sliderImage3 from '../images/sliderimage3.jpg'

import bagsImage from '../images/bags.jpg'
import watch1 from '../images/watch1.jpg'
import watch2 from '../images/watch2.jpg'
import shoesBig2 from '../images/shoesbig2.jpg'
import shoes1 from '../images/shoes1.jpg'
import shoes2 from '../images/shoes2.jpg'
import sungLass1 from '../images/sunglass1.jpg'
import sungLass2 from '../images/sunglass2.jpg'
import sungLassBig from '../images/sunglassbig.jpg'
import shoeImg from '../images/shoe-img.jpg'
import newsLetter from '../images/newsletter-img.jpg'
import bestBrand1 from '../images/brand-1.png'
import bestBrand2 from '../images/brand-2.png'
import bestBrand3 from '../images/brand-3.png'
import bestBrand4 from '../images/brand-4.png'
import bestBrand5 from '../images/brand-5.png'
import bestBrand6 from '../images/brand-6.png'
import dealImage1 from '../images/deal-img1.jpg'
import dealImage2 from '../images/deal-img2.jpg'
import proDetailImg2 from '../images/pro-detail-img2.jpg'
import proDetailImg3 from '../images/pro-detail-img3.jpg'
import proDetailImg4 from '../images/pro-detail-img4.jpg'
import proDetailImg5 from '../images/pro-detail-img5.jpg'

import product1 from '../images/product-1.jpg'
import product2 from '../images/product-2.jpg'
import product3 from '../images/product-3.jpg'
import product4 from '../images/product-4.jpg'
import product5 from '../images/product-5.jpg'
import product6 from '../images/product-6.jpg'
import product7 from '../images/product-7.jpg'
import product8 from '../images/product-8.jpg'
import product9 from '../images/product-9.jpg'
import product10 from '../images/product-10.jpg'
import product11 from '../images/product-11.jpg'
import product12 from '../images/product-12.jpg'

import emptySearch from '../images/empty-search.jpg'
import footerBgImg from '../images/footer-bg-img.jpg'
import dealBg from '../images/deal-bg.jpg'

                          /*product*/
import proDetailParallex from '../images/pro-detail-parallex.jpg'
import relatedProImg1 from '../images/related-pro-img1.jpg'
import relatedProImg2 from '../images/related-pro-img2.jpg'
import relatedProImg3 from '../images/related-pro-img3.jpg'
import productDetail1 from '../images/pro-detail-img1.jpg'

                          /*Category*/
import BreadCrumb from '../images/footerimage.jpg'
import cloth1 from '../images/clothes-1.jpg'
import cloth2 from '../images/clothes-2.jpg'
import cloth3 from '../images/clothes-3.jpg'
import cloth4 from '../images/clothes-4.jpg'
import cloth5 from '../images/clothes-5.jpg'
import cloth6 from '../images/clothes-6.jpg'
import cloth7 from '../images/clothes-7.jpg'
import cloth8 from '../images/clothes-8.jpg'
import footWear1 from '../images/footwear-1.jpg'
import footWear2 from '../images/footwear-2.jpg'
import footWear3 from '../images/footwear-3.jpg'
import footWear4 from '../images/footwear-4.jpg'
import footWear5 from '../images/footwear-5.jpg'
import footWear6 from '../images/footwear-6.jpg'
import footWear7 from '../images/footwear-7.jpg'
import footWear8 from '../images/footwear-8.jpg'
import kitchen1 from '../images/kitchen-1.jpg'
import kitchen2 from '../images/kitchen-2.jpg'
import kitchen3 from '../images/kitchen-3.jpg'
import kitchen4 from '../images/kitchen-4.jpg'
import kitchen5 from '../images/kitchen-5.jpg'
import kitchen6 from '../images/kitchen-6.jpg'
import kitchen7 from '../images/kitchen-7.jpg'
import kitchen8 from '../images/kitchen-8.jpg'
import accessories1 from '../images/accessories-1.jpg'
import accessories2 from '../images/accessories-2.jpg'
import accessories3 from '../images/accessories-3.jpg'
import accessories4 from '../images/accessories-4.jpg'
import accessories5 from '../images/accessories-5.jpg'
import accessories6 from '../images/accessories-6.jpg'
import accessories7 from '../images/accessories-7.jpg'
import accessories8 from '../images/accessories-8.jpg'
import furniture1 from '../images/furniture-1.jpg'
import furniture2 from '../images/furniture-2.jpg'
import furniture3 from '../images/furniture-3.jpg'
import furniture4 from '../images/furniture-4.jpg'
import furniture5 from '../images/furniture-5.jpg'
import furniture6 from '../images/furniture-6.jpg'
import furniture7 from '../images/furniture-7.jpg'
import furniture8 from '../images/furniture-8.jpg'


                            /* Pages */
import wishList1 from '../images/wishlist-image1.png'
import wishList2 from '../images/wishlist-image2.png'
import completed from '../images/completed.jpg'
import signOut from '../images/sign-out.png'
import comingSoon from '../images/coming-soon.jpg'

                            /* Blog */
import blog1 from '../images/blog-1.jpg'
import blog2 from '../images/blog-2.jpg'
import blog3 from '../images/blog-img3.jpg'
import blog4 from '../images/blog-img4.jpg'
import blog5 from '../images/blog-img5.jpg'
import blog6 from '../images/blog-img6.jpg'
import blogSlider1 from '../images/sidebar-img1.jpg'
import blogSlider2 from '../images/sidebar-img2.jpg'
import recentBlog1 from '../images/recent-blog1.jpg'
import recentBlog2 from '../images/recent-blog2.jpg'
import blogDetailMain from '../images/blog-details-main.jpg'
import blogComment1 from '../images/rounded1.png'
import blogComment2 from '../images/rounded2.png'
import blogComment3 from '../images/rounded3.png'
import relatedBlog1 from '../images/related-blog1.jpg'
import relatedBlog2 from '../images/related-blog2.jpg'
import relatedBlog3 from '../images/related-blog3.jpg'

                          // Best Offer
import sidebarImage from '../images/sidebar-image.jpg'
import banner3 from '../images/top-offer-banner3.jpg'
import sliderImage4 from "../images/sliderimage4.jpg"
import sliderImage5 from "../images/sliderimage5.jpg"
import sliderImage6 from "../images/sliderimage6.jpg"
import banner1 from "../images/top-offer-banner1.jpg"
import banner2 from "../images/top-offer-banner2.jpg"
import bags1 from "../images/bags1.jpg"
import bags2 from "../images/bags2.jpg"
import bags3 from "../images/bags3.jpg"
import bags4 from "../images/bags4.jpg"
import shoesBig22 from "../images/shoesbig22.jpg"
import newsletterBg1 from "../images/newsletter-bg1.jpg"
import imgAvatar from "../images/img_avatar.png"



const imageUtil = {
        images: {
            macroLogo: macroLogo,
            sliderImage1: sliderImage1,
            sliderImage2: sliderImage2,
            sliderImage3: sliderImage3,
            bagsImage: bagsImage,
            watch1: watch1,
            watch2: watch2,
            shoesBig2: shoesBig2,
            shoeImg: shoeImg,
            shoes1: shoes1,
            shoes2: shoes2,
            sungLass1: sungLass1,
            sungLass2: sungLass2,
            sungLassBig: sungLassBig,
            product1: product1,
            product2: product2,
            product3: product3,
            product4: product4,
            product5: product5,
            product6: product6,
            product7: product7,
            product8: product8,
            product9: product9,
            product10: product10,
            product11: product11,
            product12: product12,
            productDetail1: productDetail1,
            newsLetter: newsLetter,
            bestBrand1: bestBrand1,
            bestBrand2: bestBrand2,
            bestBrand3: bestBrand3,
            bestBrand4: bestBrand4,
            bestBrand5: bestBrand5,
            bestBrand6: bestBrand6,
            dealImage1: dealImage1,
            dealImage2: dealImage2,
            BreadCrumb: BreadCrumb,
            cloth1: cloth1,
            cloth2: cloth2,
            cloth3: cloth3,
            cloth4: cloth4,
            cloth5: cloth5,
            cloth6: cloth6,
            cloth7: cloth7,
            cloth8: cloth8,
            footWear1: footWear1,
            footWear2: footWear2,
            footWear3: footWear3,
            footWear4: footWear4,
            footWear5: footWear5,
            footWear6: footWear6,
            footWear7: footWear7,
            footWear8: footWear8,
            kitchen1: kitchen1,
            kitchen2: kitchen2,
            kitchen3: kitchen3,
            kitchen4: kitchen4,
            kitchen5: kitchen5,
            kitchen6: kitchen6,
            kitchen7: kitchen7,
            kitchen8: kitchen8,
            accessories1: accessories1,
            accessories2: accessories2,
            accessories3: accessories3,
            accessories4: accessories4,
            accessories5: accessories5,
            accessories6: accessories6,
            accessories7: accessories7,
            accessories8: accessories8,
            furniture1: furniture1,
            furniture2: furniture2,
            furniture3: furniture3,
            furniture4: furniture4,
            furniture5: furniture5,
            furniture6: furniture6,
            furniture7: furniture7,
            furniture8: furniture8,
            wishList1: wishList1,
            wishList2: wishList2,
            completed: completed,
            signOut: signOut,
            comingSoon: comingSoon,
            blog1:blog1,
            blog2:blog2,
            blog3:blog3,
            blog4:blog4,
            blog5:blog5,
            blog6:blog6,
            blogSlider1:blogSlider1,
            blogSlider2:blogSlider2,
            recentBlog1:recentBlog1,
            recentBlog2:recentBlog2,
            proDetailImg2: proDetailImg2,
            proDetailImg3: proDetailImg3,
            proDetailImg4: proDetailImg4,
            proDetailImg5: proDetailImg5,
            proDetailParallex: proDetailParallex,
            relatedProImg1: relatedProImg1,
            relatedProImg2: relatedProImg2,
            relatedProImg3: relatedProImg3,
            blogDetailMain: blogDetailMain,
            blogComment1: blogComment1,
            blogComment2: blogComment2,
            blogComment3: blogComment3,
            relatedBlog1: relatedBlog1,
            relatedBlog2: relatedBlog2,
            relatedBlog3: relatedBlog3,
            sidebarImage: sidebarImage,
            emptySearch: emptySearch,
            banner1: banner1,
            banner2: banner2,
            banner3: banner3,
            sliderImage4: sliderImage4,
            sliderImage5: sliderImage5,
            sliderImage6: sliderImage6,
            footerBgImg: footerBgImg,
            bags1:bags1,
            bags2:bags2,
            bags3:bags3,
            bags4:bags4,
            shoesBig22:shoesBig22,
            dealBg:dealBg,
            newsletterBg1: newsletterBg1,
            imgAvatar: imgAvatar,
        },
}

export default imageUtil;

