import React from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import BreadCrumb from "../GlobalComponents/BreadCrumb"
import {Link} from "react-router-dom"
import {toast} from "react-toastify"
import titleUpperCase from "../globalUtility/commonHelper";
import {connect} from "react-redux";
import {handelItemToCart} from "../actions/cartAction";

const ConfirmationModal = (props) => {
    const {isModal, className} = props;

    return (
        <div>
            <Modal isOpen={isModal} toggle={props.handelModal} className={className}>
                <ModalHeader toggle={props.handelModal}>Confirmation</ModalHeader>
                <ModalBody style={{color: "black"}}>
                    You want to empty your cart
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => props.onEmptyCart()}>Yes</Button>{' '}
                    <Button color="secondary" onClick={props.handelModal}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

class CartPage extends React.Component {
    componentWillMount() {
        const cart = JSON.parse(localStorage.getItem('cart') ) || []
        this.setState({
            cart,
            isModal: false,
        }, () => this.totalCount())
    }

    totalCount = () => {
        const {cart} = this.state
        let total = 0
        cart && cart.length && cart.forEach(a => {
            total += ((a.salePrice && a.salePrice.toFixed(2)) * a.count)
        })
        this.setState({ total })
    }

    countChange = (type, item, index) => {
        const {cart} = this.state
        if (type === "minus") {
            if (item.count === 1) {
                toast.warn(`Minimum 1 items required`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            } else {
                cart[index].count = cart[index].count - 1
            }
        } else {
            if (item.count === item.stock) {
                toast.warn(`Product is now out of stock`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            } else {
                cart[index].count = cart[index].count + 1
            }
        }
        this.props.handelCart(cart)
        localStorage.setItem("cart" , JSON.stringify(cart))
        this.setState({ cart }, () => this.totalCount())
    }

    handelModal = () => {
        this.setState({
            isModal: !this.state.isModal
        })
    }

    onEmptyCart = () => {
        localStorage.setItem("cart" , JSON.stringify([]))
        toast.error(`All Product Removed Successfully from cart`, {
            position: toast.POSITION.TOP_RIGHT
        })
        this.props.handelCart([])
        this.setState({
            cart: []
        }, () => this.handelModal())
    }

    onRemove = (index) => {
        const {cart} = this.state
        cart.splice(index, 1)
        localStorage.setItem("cart" , JSON.stringify(cart))
        this.setState({cart},()=>{this.totalCount()})
        this.props.handelCart(cart)
        toast.error(`Product Removed Successfully`, {
            position: toast.POSITION.TOP_RIGHT
        })
    }

    render() {
        const {cart, total, isModal} = this.state
        return (
            <>
                <ConfirmationModal isModal={isModal} handelModal={this.handelModal} onEmptyCart={() => this.onEmptyCart()}/>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Pages" subHeading="Cart page"/>
                {/* breadcrumb section end */}
                {/* cart page section starts */}
                <section className="padding-80px-tb">
                    {/* cart details starts */}
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                <div className="table-responsive">
                                    {/* table starts */}
                                    {
                                        (cart && cart.length) > 0  &&
                                            <>
                                                <table className="table table-bordered no-margin-bottom">
                                                    <thead>
                                                    {/* heading starts */}
                                                    <tr className="text-center">
                                                        <th>Product Name</th>
                                                        <th>Unit Price</th>
                                                        <th>Quantity</th>
                                                        <th>Sub Total</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    {/* heading ends */}
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        cart && cart.map((d, i) =>
                                                            <tr className="text-center" key={i}>
                                                                <td>
                                                                    {/* buy product image starts */}
                                                                    <Link to={`/product/${d.id}`}><img src={d.pictures[0]} width={100} height={100} alt="product"/></Link>
                                                                    {/* buy product image ends */}
                                                                    {/* buy product description starts */}
                                                                    <div className="added-product">
                                                                        <Link to={`/product/${d.id}`} className="text-black font-weight-600 sm-text-large">
                                                                            {titleUpperCase(d.productTitle)}
                                                                        </Link>
                                                                    </div>
                                                                    {/* buy product description ends */}
                                                                </td>
                                                                {/* product price starts */}
                                                                <td className="vertical-align-middle sm-text-large">
                                                                    ${d.salePrice && d.salePrice.toFixed(2)}
                                                                </td>
                                                                {/* product price ends */}
                                                                {/* qty starts */}
                                                                <td className="vertical-align-middle">
                                                                    <div className="qty-input qty-input-style1 d-inline-block">
                                                                        <i className="less title-small" onClick={() => this.countChange("minus", d, i)}>-</i>
                                                                        <input type="text" value={d.count} onChange={()=>{}} />
                                                                        <i className="more title-small" onClick={() => this.countChange("add", d, i)}>+</i>
                                                                    </div>
                                                                </td>
                                                                {/* qty ends */}
                                                                {/* product sub total starts */}
                                                                <td className="vertical-align-middle font-weight-600 sm-text-large">
                                                                    ${(d.salePrice && d.salePrice.toFixed(2) * (d.count || 1)).toFixed(2)}
                                                                </td>
                                                                {/* product sub total ends */}
                                                                {/* action starts */}
                                                                <td className="vertical-align-middle">
                                                                    {/* trash icon starts */}
                                                                    <div className="delet-icon" onClick={() => this.onRemove(i)}>
                                                                        <i className="fas fa-times hoverstyle1"/>
                                                                    </div>
                                                                    {/* trash icon ends */}
                                                                </td>
                                                                {/* action ends */}
                                                            </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                                {/* cart details ends */}
                                                {/* action buttons starts */}
                                                <div className="container cart-option">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-12 p-0">
                                                            <div className="text-left sm-text-center continue-shopping">
                                                                <Link to="/products/grids-page" className="btn btn-style1">
                                                                    <i className="fas fa-arrow-alt-circle-left"/>Continue Shopping
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="col-lg-4 col-md-4 col-12 p-0 d-flex sm-justify-content-center">
                                                            <div className="d-inline-block">
                                                                <input type="text" name="coupon"
                                                                       className="text-medium form-control border-radius-5" placeholder="Enter your coupon"/>
                                                            </div>
                                                            <div className="d-inline-block margin-left-5px">
                                                                <button className="btn btn-style1 z-index-7">Coupon</button>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-12 p-0">
                                                            <div className="text-right sm-text-center emp-cart">
                                                                <Button className="btn btn-style1" onClick={() => this.handelModal()}><i className="fas fa-eraser" />Empty cart</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* action buttons ends */}
                                                <div className="container">
                                                    <div className="row">
                                                        {/* delivery details starts */}
                                                        <div className="col-lg-6 col-md-6 no-padding-left sm-padding-left-15px del-option">
                                                            <div className="delivery-info">
                                                                {/* heading starts */}
                                                                <h6 className="font-weight-600 text-left border-bottom-khaki margin-bottom-10px display-inline-block text-large md-title-mdall sm-text-extra-large text-medium">
                                                                    Estimate Delivery Time &amp; Details
                                                                </h6>
                                                                <p className="text-left margin-bottom-10px text-medium sm-text-large">*Choose your destination for delivery</p>
                                                                {/* heading ends */}
                                                                {/* form starts */}
                                                                <div className="cart-page-form">
                                                                    {/* select country starts */}
                                                                    <select name="Country" className="form-control border-radius-5">
                                                                        <option value="India">India</option>
                                                                        <option value="Sri-Lanka">Sri-lanka</option>
                                                                        <option value="Mauritius">Mauritius</option>
                                                                        <option value="Singapore">Singapore</option>
                                                                        <option value="Indonesia">Indonesia</option>
                                                                    </select>
                                                                    {/* select country ends */}
                                                                    {/* pincode starts */}
                                                                    <div className="padding-top-15px">
                                                                        <input type="text" name="pin-code"
                                                                               className="sm-margin-bottom-10px form-control border-radius-5 p-2"
                                                                               placeholder="Enter your pincode"/>
                                                                    </div>
                                                                    {/* pincode ends */}
                                                                    <p className="text-danger margin-top-10px no-margin-bottom sm-text-large">*Enter your pincode to check the delivery time</p>
                                                                    {/* faster delivery options starts */}
                                                                    <p className="text-green margin-top-10px no-margin-bottom sm-text-large">Faster delivery</p>
                                                                    <input type="radio" name="gender" defaultValue="male"
                                                                           className="sm-text-large position-relative top-2 margin-right-5px"/>Within 2-3 days
                                                                    <span className="d-inline-block sm-text-large">$50.00</span>
                                                                    <p className="d-inline-block m-0 font-weight-600 sm-text-large">(tax-incl)</p>
                                                                    <p className="text-green margin-top-10px no-margin-bottom sm-text-large">Economy delivery</p>
                                                                    <input type="radio" name="gender" defaultValue="male"
                                                                           className="sm-text-large position-relative top-2 margin-right-5px"/>Within 6 days
                                                                    <span className="d-inline-block sm-text-large">$20.00</span>
                                                                    <p className="d-inline-block m-0 font-weight-600 sm-text-large">(tax-incl)</p>
                                                                    {/* faster delivery options ends */}
                                                                    <button className="btn btn-style1 d-block margin-top-15px" type="Submit">Submit</button>
                                                                </div>
                                                                {/* form ends */}
                                                            </div>
                                                        </div>
                                                        {/* delivery details ends */}
                                                        {/* order summery starts */}
                                                        <div
                                                            className="col-lg-6 col-md-6 mt-lg-0 sm-margin-top-15px no-padding-right sm-padding-right-15px del-option">
                                                            <div className="delivery-info">
                                                                <h6 className="text-left font-weight-600 margin-bottom-10px border-bottom-khaki display-inline-block text-large md-title-mdall sm-text-extra-large text-medium">
                                                                    Order Summary</h6>
                                                                <div className="font-weight-600 text-left margin-bottom-10px border-bottom-medium-gray padding-bottom-10px md-text-large sm-text-large">
                                                                    Sub Total:
                                                                    <div className="display-inline-block float-right">
                                                                        <span className="font-weight-600 md-text-large md-no-padding-left sm-text-large">
                                                                            {`$${total && total.toFixed(2)}`}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="font-weight-600 text-left margin-bottom-10px border-bottom-medium-gray padding-bottom-10px md-text-large sm-text-large">Shipping
                                                                    Charge:
                                                                    <div className="display-inline-block float-right">
                                                                        <span className="font-weight-600 md-text-large md-no-padding-left sm-text-large">$12.00</span>
                                                                    </div>
                                                                </div>
                                                                <div className="font-weight-600 text-left md-text-large sm-text-large">Grand Total:
                                                                    <div className="display-inline-block float-right">
                                                                        <span className="font-weight-600 md-text-large md-no-padding-left sm-text-large">{`$${(total + 12).toFixed(2)}`}</span>
                                                                    </div>
                                                                </div>
                                                                <Link to="/checkout" className="btn btn-style1 margin-top-15px chec-out">Checkout
                                                                    <i className="fas fa-hand-point-right"/>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        {/* order summery ends */}
                                                    </div>
                                                </div>
                                                {/* cart details ends */}
                                            </>
                                    }
                                    {/* table ends */}
                                </div>
                            </div>
                        </div>
                        {
                            cart.length <= 0 &&
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="no-pro-available">
                                        <p className="alert-msg">There are no product in your cart</p>
                                    </div>
                                    <div className="go-link">
                                        <Link to="/products/grids-page" className="btn btn-style1">Shop Now</Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
                {/* cart page section ends */}
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    handelCart: (item) => dispatch(handelItemToCart(item))
})

export default connect(
    null,
    mapDispatchToProps
)(CartPage)
