import React from "react";
import imageUtil from "../globalUtility/imageHelper";
import {Link} from 'react-router-dom'

class ComingSoon extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            minutes: 0,
            seconds: 0,
            hours: 0,
            days: 0,
        }
    }
    componentDidMount() {
        let date = new Date();
        date.setDate(date.getDate() + 2);
        let countDownDate = new Date(date).getTime();
        setInterval(() => {
            let now = new Date().getTime();
            let distance = countDownDate - now;
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                minutes: minutes,
                days: days,
                hours: hours,
                seconds: seconds
            })
        }, 1000)
    }
    render() {
        const images = imageUtil.images;
        const{seconds, hours, minutes, days} =this.state;
        return(
            <>
                {/* coming soon section starts */}
                <section className="height-100vh cover-background coming-soon" style={{backgroundImage: `url(${images.comingSoon})`}}>
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-lg-12">
                                <div className="coming-soon-top">
                                    {/* heading starts */}
                                    <h3 className="text-center font-weight-600 text-white text-black sm-text-extra-large">Coming Soon<span className="text-sky-blue">!!!</span></h3>
                                    {/* heading ends */}
                                    <h4 className="text-medium text-white sm-text-large">Find Out When We Open</h4>
                                    {/* timer starts */}
                                    <div className="text-center timer-style1 padding-top-10px sm-clear-both">
                                        <div id="counter-event" data-enddate="2019/12/25 00:00:00" className="alt-font width-100">
                                            <div className="counter-container">
                                                <div className="text-center timer-style1">
                                                    <div id="counter-event" data-enddate="2019/12/25 00:00:00" className="width-100">
                                                        <div className="counter-container">
                                                            <div className="counter-box first">
                                                                <div className="number">{days}</div>
                                                                <span>Days</span>
                                                            </div>
                                                            <div className="counter-box">
                                                                <div className="number">{hours}</div>
                                                                <span>Hours</span>
                                                            </div>
                                                            <div className="counter-box">
                                                                <div className="number">{minutes}</div>
                                                                <span>Minutes</span>
                                                            </div>
                                                            <div className="counter-box last">
                                                                <div className="number">{seconds}</div>
                                                                <span>Seconds</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* timer ends */}
                                    <div className="com-soon">
                                        {/* email starts */}
                                        <div className="com-search">
                                            <input type="Email" name="email" data-field="required" id="email" placeholder="Your email address" className="border-none border-radius-5 sm-text-large" />
                                        </div>
                                        {/* email ends */}
                                        {/* subscribe starts */}
                                        <div className="com-sub-btn">
                                            <Link to='#' className="btn-style1">Subscribe</Link>
                                        </div>
                                        {/* subscribe ends */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* coming soon section ends */}
            </>
        )
    }
}

export default ComingSoon;