import React from "react";

class ServiceSection extends React.Component{
    render() {
        return(
            <section className="padding-40px-tb bg-light-gray1">
                <div className="container">
                    <div className="row service-section text-center">
                        {/* sevice1 starts */}
                        <div className="col-xl-3 col-lg-3 col-md-3 col-6 service-content sm-margin-bottom-20px">
                            <div className="service-box">
                                <i className="fas fa-truck" />
                                <h6 className="servicetext text-black text-medium font-weight-600 main-font margin-top-10px sm-text-large">24*7 Home Delivery Service</h6>
                            </div>
                        </div>
                        {/* sevice1 ends */}
                        {/* sevice2 starts */}
                        <div className="col-xl-3 col-lg-3 col-md-3 col-6 service-content sm-margin-bottom-20px">
                            <div className="service-box">
                                <i className="fas fa-dollar-sign" />
                                <h6 className="servicetext text-black text-medium font-weight-600 main-font margin-top-10px sm-text-large">Your Money Is Safe With Us</h6>
                            </div>
                        </div>
                        {/* sevice2 ends */}
                        {/* sevice3 starts */}
                        <div className="col-xl-3 col-lg-3 col-md-3 col-6 service-content">
                            <div className="service-box">
                                <i className="far fa-check-circle" />
                                <h6 className="servicetext text-black text-medium font-weight-600 main-font margin-top-10px sm-text-large">Our Product Is 100% Authentic</h6>
                            </div>
                        </div>
                        {/* sevice3 ends */}
                        <div className="col-xl-3 col-lg-3 col-md-3 col-6 service-content">
                            <div className="service-box">
                                <i className="fas fa-undo" />
                                <h6 className="servicetext text-black text-medium font-weight-600 main-font margin-top-10px sm-text-large">Free Returns Within 30 days</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ServiceSection
