import imageUtil from "../../imageHelper";

const images = imageUtil.images

export const productList = [
    {
        id: 1,
        productTitle: "Stylish Handbags",
        category: "women",
        subCategory: "purse",
        brandName: "Mammon",
        pictures: [images.product1, images.product2, images.product3, images.product4],
        shortDetails: "Mammon Women's stylish Handbags (LR-bib-blk)",
        description: "it contains two shoulder strap so you can carry it a shoulder bag as well lease allow little size deviation because everyone has their own measurement method.",
        rating: 1,
        stock: 16,
        price: 1200.00,
        discount: 50,
        salePrice: 200.15,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["M", "L", "XL"],
        tags: [],
        colors: ["#fde5e0", "#3B00FF", "#35FF00"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 2,
        productTitle: "Leather Formal Shoes",
        category: "men",
        subCategory: "footwear",
        brandName: "bata",
        pictures: [images.product2, images.product1, images.product3, images.product4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 5,
        price: 2220.00,
        discount: 50,
        salePrice: 1200.30,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#baac9f"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 3,
        productTitle: "Golden Mirror",
        category: "women",
        subCategory: "goggles",
        brandName: "Mammon",
        pictures: [images.product3, images.product2, images.product1, images.product4],
        shortDetails: "Golden Pink Golden Mirror Full Rim Wayfarer Shape",
        description: "golden Pink Golden Mirror Full Rim Wayfarer Shape Medium (Size-53) Vincent Chase NOMADES VC S11109 - C2 Sunglasses",
        rating: 3,
        stock: 23,
        price: 1700.00,
        discount: 50,
        salePrice: 700.00,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: [],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 4,
        productTitle: "Fastrack Economy 2013",
        category: "men",
        subCategory: "watch",
        brandName: "Fastrack",
        pictures: [images.product4, images.product2, images.product3, images.product1],
        shortDetails: "Watch",
        description: "fastrack, a well renowned fashion brand brings an extravagant array of wrist watches for you this season. The Fastrack Economy 2013 Analog Black Dial Men's Watch has brought a significant raised the level of fashion trends in the era of mens accessories. The device is packed with excellent features and new technology to ensure that it gives you the best performance at all times.",
        rating: 5,
        stock: 42,
        price: 3500.00,
        discount: 50,
        salePrice: 2800.00,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: [],
        tags: [],
        colors: ["#6d5c4e", "#3B00FF", "#FF000E", "#00E4FF"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 5,
        productTitle: "Digital Still Camera",
        category: "electric",
        subCategory: "camera",
        brandName: "philips",
        pictures: [images.product5, images.product6, images.product7, images.product8],
        shortDetails: "Watch",
        description: "the ESee digital still camera lets you make high quality panorama pictures with photo stitching and captures 8 times closer to action with optical zoom. Get video in great details and make perfect shot with auto mode.",
        rating: 3,
        stock: 12,
        price: 55000.00,
        discount: 50,
        salePrice: 80000.00,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: [],
        tags: [],
        colors: ["#b1d5ed", "#3B00FF", "#4fc3f7"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 6,
        productTitle: "Boat Headphones",
        category: "electric",
        subCategory: "Headphones",
        brandName: "boat",
        pictures: [images.product6, images.product5, images.product7, images.product8],
        shortDetails: "Boat Rockerz 400 On-Ear Bluetooth Headphones (Carbon Black)",
        description: "now just stop worrying about wires hanging from here and there. Wired is passé. Foldable/ Collapsible: Designed for people always on the move, easy storage and easy to carry. ",
        rating: 0,
        stock: 43,
        price: 1400.00,
        discount: 50,
        salePrice: 700.00,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: [],
        tags: [],
        colors: ["#101010", "#4B612F", "#3B00FF"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 7,
        productTitle: "Anolog Men's Watch",
        category: "men",
        subCategory: "watch",
        brandName: "Titan",
        pictures: [images.product7, images.product5, images.product6, images.product8],
        shortDetails: "Swisstyle Anolog Black Dial Men's Watch-SS-GR06612-BLK-CH",
        description: "Swisstyle Anolog Black Dial Men's Watch-SS-GR06612-BLK-CH",
        rating: 5,
        stock: 12,
        price: 7000.00,
        discount: 50,
        salePrice: 6700.00,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: [],
        tags: [],
        colors: ["#955a22"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 8,
        productTitle: "black leisure shorts",
        category: "men",
        subCategory: "clothes",
        brandName: "jockey",
        pictures: [images.product8, images.product5, images.product6, images.product7],
        shortDetails: "jockey graphite & black leisure shorts",
        description: "Fabric Details : 80% Cotton, 20% Polyester Premium Combed Cotton Rich fabric Straight Fit Durable broad waistband with flat inner drawstring",
        rating: 5,
        stock: 75,
        price: 2000.00,
        discount: 50,
        salePrice: 1300.00,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: true,
        size: [],
        tags: [],
        colors: ["#baac9f"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 9,
        productTitle: "Men's V Neck Cotton T-Shirts",
        category: "kids",
        subCategory: "clothes",
        brandName: "Lee Cooper",
        pictures: [images.cloth1, images.product10, images.product11, images.product12],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 54,
        price: 45,
        discount: 50,
        salePrice: 40,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["New"],
        colors: ["#b5ff00", "#3B00FF", "#0eff00"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 10,
        productTitle: "Round Neck Full Sleeves T-Shirt",
        category: "kids",
        subCategory: "clothes",
        brandName: "Lee Cooper",
        pictures: [images.cloth2, images.product10, images.product11, images.product12],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 3,
        price: 100,
        discount: 2,
        salePrice: 90,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#3B00FF", "#00FAFF", "#FF0093"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 11,
        productTitle: "Overdyed Cargo Utility Shorts",
        category: "kids",
        subCategory: "clothes",
        brandName: "Lee Cooper",
        pictures: [images.cloth3, images.product10, images.product11, images.product12],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 8,
        price: 240,
        discount: 23,
        salePrice: 200,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Best Feature"],
        colors: ["#D4A7C0", "#FF0093", "#3B00FF"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 12,
        productTitle: "Winter T-Shirts For Mens",
        category: "women",
        subCategory: "clothes",
        brandName: "Lee Cooper",
        pictures: [images.cloth4, images.product10, images.product11, images.product12],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 0,
        stock: 13,
        price: 254,
        discount: 50,
        salePrice: 200,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#3b2427", "#3B00FF", "#00BCD4"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 13,
        productTitle: "men's Cotton T-Shirt",
        category: "kids",
        subCategory: "clothes",
        brandName: "Lee Cooper",
        pictures: [images.cloth5, images.product1, images.product3, images.product4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 7,
        price: 100,
        discount: 50,
        salePrice: 50,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#baac9f", "#795548", "#E64A19"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 14,
        productTitle: "Men's Cotton Hooded T-Shirt",
        category: "men",
        subCategory: "clothes",
        brandName: "Lee Cooper",
        pictures: [images.cloth6, images.product1, images.product3, images.product4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 12,
        price: 25,
        discount: 50,
        salePrice: 23,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Trade"],
        colors: ["#d4c4fb", "#b5ff00", "#3B00FF"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 15,
        productTitle: "men's Cotton T-Shirt",
        category: "kids",
        subCategory: "clothes",
        brandName: "Lee Cooper",
        pictures: [images.cloth7, images.product1, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 12,
        price: 45,
        discount: 50,
        salePrice: 40,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#3B00FF", "#b5ff00", "#d4c4fb"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 16,
        productTitle: "Women's Crepe Jumpsuit",
        category: "women",
        subCategory: "clothes",
        brandName: "Lee Cooper",
        pictures: [images.cloth8, images.cloth7, images.cloth6, images.product4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 3,
        stock: 55,
        price: 9000,
        discount: 50,
        salePrice: 8500,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: [],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 17,
        productTitle: "Men's Brown Sandals",
        category: "all",
        subCategory: "footwear",
        brandName: "bata",
        pictures: [images.footWear1, images.product1, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 77,
        price: 111,
        discount: 50,
        salePrice: 100,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["New"],
        colors: ["#4caf50", "#3B00FF", "#9a8efc"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 18,
        productTitle: "Strap Slingback Sandals",
        category: "all",
        subCategory: "footwear",
        brandName: "bata",
        pictures: [images.footWear2, images.product1, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 32,
        price: 100,
        discount: 20,
        salePrice: 80,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#3B00FF", "#955a22", "#9a8efc"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 19,
        productTitle: "Rubber Clogs For Men",
        category: "all",
        subCategory: "footwear",
        brandName: "bata",
        pictures: [images.footWear3, images.product1, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 5,
        stock: 12,
        price: 85,
        discount: 12,
        salePrice: 70,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Best Feature"],
        colors: ["#3b2427", "#3B00FF", "#8ed1fc"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 20,
        productTitle: "Converse Unisex Sneakers",
        category: "all",
        subCategory: "footwear",
        brandName: "bata",
        pictures: [images.footWear4, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 8,
        price: 190,
        discount: 12,
        salePrice: 180,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#3b2427", "#008b02", "#0693e3"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 21,
        productTitle: "Under Armour Men's Shoes",
        category: "all",
        subCategory: "footwear",
        brandName: "bata",
        pictures: [images.footWear5, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 4,
        price: 400,
        discount: 50,
        salePrice: 350,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#3B00FF", "#ff5722", "#0693e3"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 22,
        productTitle: "Slingback Heeled Sandals",
        category: "all",
        subCategory: "footwear",
        brandName: "bata",
        pictures: [images.footWear6, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 1,
        stock: 2,
        price: 200,
        discount: 50,
        salePrice: 220,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Trade"],
        colors: ["#7986cb", "#006b76", "#FF0093"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 23,
        productTitle: "Slip-on Gusset Shoes",
        category: "all",
        subCategory: "footwear",
        brandName: "bata",
        pictures: [images.footWear7, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 3,
        stock: 6,
        price: 285,
        discount: 50,
        salePrice: 250,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#b2dfdb", "#3B00FF", "#1b1919"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 24,
        productTitle: "Shoes For Men & Boys",
        category: "all",
        subCategory: "footwear",
        brandName: "bata",
        pictures: [images.footWear8, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 44,
        price: 400,
        discount: 50,
        salePrice: 300,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#101010", "#00ff6c", "#abb8c3"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 25,
        productTitle: "Cold Whole Juicer",
        category: "all",
        subCategory: "kitchen",
        brandName: "philips",
        pictures: [images.kitchen1, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 23,
        price: 120,
        discount: 50,
        salePrice: 100,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["New"],
        colors: ["#7986cb", "#3B00FF", "#9CFF00"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 26,
        productTitle: "Vegetable Plants",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen2, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 56,
        price: 6800,
        discount: 50,
        salePrice: 6000,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#955a22", "#7986cb", "#FF0093"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 27,
        productTitle: "Tea Kettle With Cups",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen3, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 345,
        price: 6900,
        discount: 50,
        salePrice: 6400,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Best Feature"],
        colors: ["#7b64ff", "#0693e3", "#3B00FF"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 28,
        productTitle: "Wooden Spoon",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen4, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 4,
        price: 450,
        discount: 50,
        salePrice: 350,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#955a22", "#0693e3", "#7986cb"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 29,
        productTitle: "Tea Plant",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen5, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 1,
        stock: 456,
        price: 100,
        discount: 50,
        salePrice: 80,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#7b64ff", "#3B00FF", "#7986cb"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 30,
        productTitle: "Plastic Small Bowl",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen6, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 45,
        price: 68,
        discount: 50,
        salePrice: 60,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Trade"],
        colors: ["#3b2427", "#955a22", "#00ffff"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 31,
        productTitle: "Plastic Box",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen7, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 345,
        price: 95,
        discount: 50,
        salePrice: 80,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#b1d5ed", "#197979", "#673ab7"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ]
    },
    {
        id: 32,
        productTitle: "Silver Spoon",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen8, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 34,
        price: 400,
        discount: 50,
        salePrice: 450,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: [],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 33,
        productTitle: "Smart Video Camera",
        category: "all",
        subCategory: "accessories",
        brandName: "philips",
        pictures: [images.accessories1, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 1,
        price: 7500,
        discount: 50,
        salePrice: 7000,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["New"],
        colors: ["#009688", "#9c27b0", "#ff9800"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 34,
        productTitle: "Mobile Back Cover",
        category: "all",
        subCategory: "accessories",
        brandName: "samsung",
        pictures: [images.accessories2, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 6,
        price: 9000,
        discount: 50,
        salePrice: 8500,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#ff1400", "#3B00FF", "#006b76"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 35,
        productTitle: "Smart Watch",
        category: "all",
        subCategory: "accessories",
        brandName: "Titan",
        pictures: [images.accessories3, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 2,
        price: 6500,
        discount: 50,
        salePrice: 6000,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Best Feature"],
        colors: ["#101010", "#0693e3", "#ffc107"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 36,
        productTitle: " Mobile Cover",
        category: "all",
        subCategory: "accessories",
        brandName: "samsung",
        pictures: [images.accessories4, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 56,
        price: 12000,
        discount: 50,
        salePrice: 11500,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#101010", "#3B00FF", "#ff9800"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 37,
        productTitle: "HeadPhone",
        category: "all",
        subCategory: "accessories",
        brandName: "boat",
        pictures: [images.accessories5, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 1,
        stock: 3,
        price: 9999,
        discount: 50,
        salePrice: 8888,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#101010", "#9c27b0", "#ffc107"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 38,
        productTitle: "Lens For Mobile",
        category: "all",
        subCategory: "accessories",
        brandName: "samsung",
        pictures: [images.accessories6, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 5,
        price: 7777,
        discount: 50,
        salePrice: 6666,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Trade"],
        colors: ["#101010", "#0f704b", "#3B00FF"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 39,
        productTitle: " Men's Watch",
        category: "all",
        subCategory: "accessories",
        brandName: "Titan",
        pictures: [images.accessories7, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 5,
        stock: 3,
        price: 5555,
        discount: 50,
        salePrice: 4444,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#955a22", "#0f704b", "#9c27b0"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 40,
        productTitle: "Polaroid 300 Instant Camera",
        category: "all",
        subCategory: "accessories",
        brandName: "Lee Cooper",
        pictures: [images.accessories8, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 1,
        stock: 4,
        price: 6800,
        discount: 40,
        salePrice: 6000,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#b1d5ed", "#009688", "#ffeb3b"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 41,
        productTitle: "Dining Table",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture1, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 1,
        stock: 654,
        price: 9400,
        discount: 12,
        salePrice: 9000,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["New"],
        colors: ["#955a22", "#009688", "#ffeb3b"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 42,
        productTitle: "Day Bed",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture2, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 45,
        price: 7800,
        discount: 40,
        salePrice: 7000,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#955a22", "#ff9800", "#ffeb3b"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 43,
        productTitle: "Black Fabric Seat Sofa",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture3, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 756,
        price: 9000,
        discount: 40,
        salePrice: 8500,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Best Feature"],
        colors: ["#3b2427", "#955a22", "#9c27b0"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 44,
        productTitle: "Dura Comfort Stool",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture4, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 56,
        price: 9000,
        discount: 12,
        salePrice: 8500,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#101010", "#009688", "#607d8b"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 45,
        productTitle: "Plastic Dining Table",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture5,  images.footWear3, images.product3, images.furniture4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 567,
        price: 1258,
        discount: 2,
        salePrice: 1200,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#b1d5ed", "#9c27b0", "#3B00FF"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 46,
        productTitle: "Chair",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture6, images.footWear3, images.product3, images.furniture4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 34,
        price: 6500,
        discount: 10,
        salePrice: 6000,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Trade"],
        colors: ["#9c27b0", "#009688", "#607d8b"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 47,
        productTitle: "Outdoor Table",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture7, images.footWear3, images.product3, images.furniture6],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 324,
        price: 17000,
        discount: 40,
        salePrice: 15000,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#9c27b0", "#9c27b0", "#ff9800"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 48,
        productTitle: "Modern Sofa",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture8, images.footWear3, images.product3, images.furniture1],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 345,
        price: 94000,
        discount: 25,
        salePrice: 88000,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#955a22", "#607d8b", "#0f704b"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 49,
        productTitle: "Daily Wear Shoes",
        category: "men",
        subCategory: "footwear",
        brandName: "paragon",
        pictures: [images.relatedProImg1, images.footWear3, images.product3, images.furniture4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 5,
        stock: 345,
        price: 1000.00,
        discount: 50,
        salePrice: 700.00,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#101010", "#9c27b0", "#ff9800"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 50,
        productTitle: "Sports Gray Shoes",
        category: "men",
        subCategory: "footwear",
        brandName: "paragon",
        pictures: [images.relatedProImg2, images.footWear3, images.product3, images.furniture4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 1,
        stock: 23,
        price: 25000.00,
        discount: 50,
        salePrice: 24000.00,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#00bcd4", "#9c27b0", "#ff9800"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 51,
        productTitle: "Party Wear Shoes",
        category: "men",
        subCategory: "footwear",
        brandName: "paragon",
        pictures: [images.relatedProImg3, images.footWear3, images.product3, images.furniture4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 72,
        price: 8000.00,
        discount: 50,
        salePrice: 5000.00,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#9900ef", "#795548", "#71a11d"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 52,
        productTitle: "Day Bed",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture2, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 5,
        stock: 45,
        price: 10000,
        discount: 10,
        salePrice: 9000,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#955a22", "#c5cae9", "#9900ef"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 53,
        productTitle: "Modern Sofa",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture8, images.footWear3, images.product3, images.furniture1],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 345,
        price: 2500,
        discount: 7,
        salePrice: 2300,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#795548", "#955a22", "#c5cae9"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 54,
        productTitle: "Chair",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture6, images.footWear3, images.product3, images.furniture4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 5,
        stock: 34,
        price: 6500,
        discount: 10,
        salePrice: 6000,
        new: false,
        trade: false,
        bestFeature: false,
        sale: true,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Trade"],
        colors: ["#0043ff", "#71a11d", "#9900ef"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 55,
        productTitle: "Dining Table",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture1, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 1,
        stock: 654,
        price: 3333,
        discount: 10,
        salePrice: 2222,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: ["New"],
        colors: ["#955a22", "#00bfff", "#795548"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 56,
        productTitle: "Outdoor Table",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture7, images.footWear3, images.product3, images.furniture6],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 324,
        price: 17000,
        discount: 23,
        salePrice: 15000,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#c5cae9", "#71a11d", "#0043ff"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 57,
        productTitle: "Black Fabric Seat Sofa",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture3, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 756,
        price: 9777,
        discount: 40,
        salePrice: 8777,
        new: false,
        trade: false,
        bestFeature: false,
        sale: true,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Best Feature"],
        colors: ["#3b2427", "#00bfff", "#795548"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 58,
        productTitle: "Plastic Dining Table",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture5,  images.footWear3, images.product3, images.furniture4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 3,
        stock: 12,
        price: 245,
        discount: 72,
        salePrice: 145,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#b1d5ed", "#c5cae9", "#0043ff"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 59,
        productTitle: "Dura Comfort Stool",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture4, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 13,
        price: 9000,
        discount: 12,
        salePrice: 8500,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#101010", "#795548", "#00bfff"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 60,
        productTitle: "Day Bed",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture2, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 45,
        price: 2345,
        discount: 23,
        salePrice: 1234,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#955a22", "#9900ef", "#0043ff"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 61,
        productTitle: "Modern Sofa",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture8, images.footWear3, images.product3, images.furniture1],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 345,
        price: 345,
        discount: 25,
        salePrice: 234,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#955a22", "#795548", "#d4c4fb"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 62,
        productTitle: "Dining Table",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture1, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 1,
        stock: 654,
        price: 3333,
        discount: 33,
        salePrice: 2222,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["New"],
        colors: ["#955a22", "#3200ff", "#0043ff"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 63,
        productTitle: "Chair",
        category: "all",
        subCategory: "furniture",
        brandName: "Lee Cooper",
        pictures: [images.furniture6, images.footWear3, images.product3, images.furniture4],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 34,
        price: 6500,
        discount: 10,
        salePrice: 6000,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Trade"],
        colors: ["#9900ef", "#71a11d", "#795548"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 64,
        productTitle: "Cold Whole Juicer",
        category: "all",
        subCategory: "kitchen",
        brandName: "philips",
        pictures: [images.kitchen1, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 3,
        stock: 2,
        price: 8500,
        discount: 50,
        salePrice: 6500,
        new: true,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: ["New"],
        colors: [],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 65,
        productTitle: "Vegetable Plants",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen2, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 56,
        price: 2345,
        discount: 50,
        salePrice: 1234,
        new: false,
        trade: false,
        bestFeature: false,
        sale: true,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#955a22", "#71a11d", "#3200ff"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 66,
        productTitle: "Tea Kettle With Cups",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen3, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 345,
        price: 123,
        discount: 12,
        salePrice: 100,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Best Feature"],
        colors: ["#0043ff", "#795548", "#3200ff"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 67,
        productTitle: "Wooden Spoon",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen4, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 3,
        price: 2300,
        discount: 50,
        salePrice: 2000,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#955a22", "#3200ff", "#00bfff"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 68,
        productTitle: "Tea Plant",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen5, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 34,
        price: 6000,
        discount: 50,
        salePrice: 5500,
        new: false,
        trade: true,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#0043ff", "#795548", "#71a11d"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 69,
        productTitle: "Plastic Small Bowl",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen6, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 45,
        price: 3400,
        discount: 12,
        salePrice: 3000,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: ["Trade"],
        colors: ["#3b2427", "#0043ff", "#3200ff"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 70,
        productTitle: "Plastic Box",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen7, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 4,
        stock: 12,
        price: 2300,
        discount: 12,
        salePrice: 2000,
        new: false,
        trade: false,
        bestFeature: false,
        sale: false,
        isPopular: false,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#b1d5ed", "#9900ef", "#00bfff"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 71,
        productTitle: "Silver Spoon",
        category: "all",
        subCategory: "kitchen",
        brandName: "Lee Cooper",
        pictures: [images.kitchen8, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 43,
        price: 3000,
        discount: 12,
        salePrice: 2900,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#9900ef", "#0043ff", "#71a11d"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 72,
        productTitle: "Hand Bag",
        category: "all",
        subCategory: "clothes",
        brandName: "Lee Cooper",
        pictures: [images.dealImage1, images.footWear3, images.product3, images.cloth5],
        shortDetails: "Lee Cooper Men's Lc1461eblack Leather Formal Shoes",
        description: "Experiment with style by wearing this pair of formal shoes from the coveted brand, Lee Cooper. It is crafted from superior quality material that makes it long lasting. Besides, the lace up fastening that makes it convenient to wear.",
        rating: 2,
        stock: 43,
        price: 3000,
        discount: 12,
        salePrice: 2900,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#8ed1fc", "#8dbfb6", "#00bcd4"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
    {
        id: 73,
        productTitle: "HP Bluetooth Speaker",
        category: "all",
        subCategory: "accessories",
        brandName: "Lee Cooper",
        pictures: [images.dealImage2, images.footWear3, images.product3, images.cloth5],
        shortDetails: "The rechargeable lithium battery gives you up to 8 hours of uninterrupted playtime",
        description: "Effortlessly bring your favorite music along for the ride with the ultra-portable Bluetooth speaker in black. Just clip it and carry on. The durable design and long-lasting battery ensure your music is always with you.",
        rating: 2,
        stock: 43,
        price: 3000,
        discount: 12,
        salePrice: 2900,
        new: false,
        trade: false,
        bestFeature: true,
        sale: false,
        isPopular: true,
        size: ["6UK", "7UK", "8UK"],
        tags: [],
        colors: ["#101010", "#955a22", "#71a11d"],
        variants: [
            {color: "yellow", images: ""},
            {color: "gray", images: ""},
            {color: "green", images: ""}
        ],
        customerReview: []
    },
]

export default {
    productList
}
