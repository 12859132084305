import {ADD_TO_CART_PRODUCT, END_ADD_TO_CART} from "../constants/actionTypes";
import {toast} from "react-toastify";

export const addItemToCart = (data) => {
    return (dispatch) => {
        try {
            const allCart = JSON.parse(localStorage.getItem('cart')) || []
            const isExists = allCart && allCart.length && allCart.find(d => d.id === data.id)
            if (isExists && Object.keys(isExists).length) {
                const idx = allCart.findIndex(f => f.id === isExists.id)
                allCart[idx].count = allCart[idx].count + (data.count ? data.count : 1)
            } else {
                allCart.push({...data, count: data.count ? data.count : 1})
            }

            toast.success(`Item has been added to cart`, {
                position: toast.POSITION.TOP_RIGHT
            });
            localStorage.setItem('cart', JSON.stringify(allCart))
            dispatch({type: ADD_TO_CART_PRODUCT, allCart: allCart});

        } catch (error) {
            dispatch({type: END_ADD_TO_CART, errorMsg: error});
        }
    }
};

export const handelItemToCart = (cart) => {
    return (dispatch) => {
        try {
            dispatch({type: ADD_TO_CART_PRODUCT, allCart: cart});
        } catch (error) {
            dispatch({type: END_ADD_TO_CART, errorMsg: error});
        }
    }
};
