import React from "react";
import BreadCrumb from "../GlobalComponents/BreadCrumb";
import PageDescription from "../globalUtility/jsonData/description/PageDescription"

class Cancellation extends React.Component {
    render() {
        const Description = (PageDescription && PageDescription.cancelation) || []
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Home" subHeading="Cancellation"/>
                {/* breadcrumb section end */}
                <section className="padding-80px-tb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 cancellation-content center-col">
                                <p className="no-margin-bottom sm-text-large border-radius-5">{Description}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Cancellation
