import BlogData from "./jsonData/blog/BlogDataList";

const titleUpperCase = (item) => {
    return item && item.charAt(0).toUpperCase() + item.slice(1)
}
export default titleUpperCase

export const blogComments = () => {
    const data = localStorage.getItem("data");
    const commonData = JSON.parse(data) || {}
    const allComments = (commonData && commonData.data && commonData.data.comments) || []
    BlogData.blogList.forEach(g => {
        const comments = (allComments && allComments.length) ? allComments.filter(f => f.blogId === g.id) : []
        g.comment = (comments && comments.length) ? comments.length : 0
    })
    return BlogData
}

export const blogDetailComments = (blog) => {
    const data = localStorage.getItem("data");
    const commonData = JSON.parse(data) || {}
    const allComments = (commonData && commonData.data && commonData.data.comments) || []
    const blogDetailsData = (allComments && allComments.length) ? allComments.filter(d => d.blogId === blog.id) : []
    return blogDetailsData
}
