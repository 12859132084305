import React from "react";
import SideBar from "../Common/SideBar";
import BlogsListFull from "../Common/BlogsListFull";
import BreadCrumb from "../../../GlobalComponents/BreadCrumb";
import Pagination from "../../../GlobalComponents/Pagination";
import {blogComments} from "../../../globalUtility/commonHelper";

class BlogsLeftSidebarFull extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            blogList: [],
        }
    }

    componentWillMount() {
        const data = blogComments()
        this.setState({ data })
    }

    onChangePage = (pageOfItems) => {
        this.setState({
            blogList: pageOfItems
        });
    }

    handelCategories = (item) =>{
        const data = blogComments()
        let category = item.toString()
        const getData = data.blogList.filter(p => p.category === category)
        this.setState({
            data:{
                blogList: getData || []
            }
        })
    }

    render() {
        const {blogList, data} = this.state

        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Blogs" subHeading="Blogs Left Sidebar Full"/>
                {/* breadcrumb section end */}
                <section className="blog-page padding-80px-tb blog-detailsstyle1">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left sidebar starts */}
                            <SideBar handelCategories={this.handelCategories}/>
                            {/* left sidebar ends */}
                            <div className="col-xl-9 col-lg-9 col-md-8">
                                <BlogsListFull blogList={blogList}/>
                                <Pagination
                                    pageSize={6}
                                    sortType=""
                                    items={data.blogList}
                                    onChangePage={this.onChangePage} />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default BlogsLeftSidebarFull
