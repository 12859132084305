const PageDescription = {
    cancelation: [
       "If you are not 100% satisfied with your purchase, you can return the product and get a full refund or exchange the product for another one, be it similar or not.You can return a product for up to 30 days from the date you purchased it.Any product you return must be in the same condition you received it and in the original packaging. Please keep the receipt."
    ],
    privacyPolicy : [
       "If you are not 100% satisfied with your purchase, " +
       "you can return the product and get a full refund or exchange " +
       "the product for another one, be it similar or not.You can return " +
       "a product for up to 30 days from the date you purchased it.",

        "If you are not 100% satisfied with your purchase, " +
        "you can return the product and get a full refund or exchange the product for another one.",

        "If you are not 100% satisfied with your purchase, " +
        "you can return the product and get a full refund or exchange the product for another one.",

        "If you are not 100% satisfied with your purchase, you can return the product and get a full refund or exchange the product for another one, be it similar or not." +
        "You can return a product for up to 30 days from the date you purchased it.Any product you return must be in the same condition you received it and in the original packaging." +
        " Please keep the receipt." ,

        "If you are not 100% satisfied with your purchase," +
        " you can return the product and get a full refund or exchange the product for another one, " +
        "be it similar or not.You can return a product for up to 30 days from the date you purchased it."
    ],
    faqs: [
        {
            title: "Can I offer both products and services on Macro?",
            description: "No, Macro will handle shipping of your products. All you need to do is pack the product and keep it ready for dispatch. " +
                         "Our logistics partner will pick up the product from you and deliver it to the customer."
        },
        {
            title: "Do I need to courier my products to Macro?",
            description: "No, Macro will handle shipping of your products. All you need to do is pack the product and keep it ready for dispatch. " +
                         "Our logistics partner will pick up the product from you and deliver it to the customer."
        },
        {
            title: "Will I get charged for listing products?",
            description: "No, Macro will handle shipping of your products. All you need to do is pack the product and keep it ready for dispatch. " +
                         "Our logistics partner will pick up the product from you and deliver it to the customer."
        },
        {
            title: "Who takes care of the delivery of my products?",
            description: "No, Macro will handle shipping of your products. All you need to do is pack the product and keep it ready for dispatch. " +
                         "Our logistics partner will pick up the product from you and deliver it to the customer."
        }
    ],
    termsAndConditions : [
        {
            title:"1.0 Payment Conditions",
            description: "The Terms of the Agreement commence on the date the Client enrolled for Services and will end when terminated by either party with the Terms" +
                         "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout" +
                         "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout" +
                         "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout"
        },
        {
            title: "2.0 About Returns",
            description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout." +
                         " The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, " +
                         "and a search for 'lorem ipsum' will uncover many web sites still in their infancy." +
                         " Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
        },
        {
            title: "3.0 Disciplinery advice",
            description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. " +
                         "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, " +
                         "as opposed to using 'Content here, content here', " +
                         "making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy." +
                         " Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. " +
                         "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English." +
                         " Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). " +
                         "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. " +
                         "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident," +
                         " sometimes on purpose (injected humour and the like)."
        }
    ],
    siteMapData: [
        {
            title: "Home",
            details: [
                {
                    id: 1,
                    title: "Home",
                    path: "/"
                }
            ]
        },
        {
            id: 2,
            title: "product",
            details: [
                {
                    title: "Product Comparison",
                    path: "/products/product-comparision"
                },
                {
                    title: "Product Details",
                    path: "/product/2"
                }
            ]
        },
        {
            id: 3,
            title: "Contact Us",
            details: [
                {
                    title: "Contact Us",
                    path: "/contact-us"
                }
            ]
        },
        {
            id: 4,
            title: "Grids Pages",
            details: [
                {
                    title: "Grids Page",
                    path: "/products/grids-page"
                },
                {
                    title: "Grids Page Full",
                    path: "/products/grids-page-full"
                },
                {
                    title: "Grids Left Sidebar",
                    path: "/products/grids-left-sidebar"
                },
                {
                    title: "Grids Left Sidebar Full",
                    path: "/products/list-page-left-sidebar-full"
                },
                {
                    title: "Grids Right Sidebar",
                    path: "/products/list-page-right-sidebar"
                },
                {
                    title: "Grids Right Sidebar Full",
                    path: "/products/list-page-right-sidebar-full"
                }
            ]
        },
        {
            id: 5,
            title: "List Pages",
            details: [
                {
                    title: "List Page",
                    path: "/products/list-page"
                },
                {
                    title: "List Page Full",
                    path: "/products/list-page-full"
                },
                {
                    title: "List Left Sidebar",
                    path: "/products/list-page-left-sidebar"
                },
                {
                    title: "List Left Sidebar Full",
                    path: "/products/list-page-left-sidebar-full"
                },
                {
                    title: "List Right Sidebar",
                    path: "/products/list-page-right-sidebar"
                },
                {
                    title: "List Right Sidebar Full",
                    path: "/products/list-page-right-sidebar-full"
                }
            ]
        },
        {
            id: 6,
            title: "Blogs",
            details: [
                {
                    title: "Blogs Page",
                    path: "/blogs/blogs"
                },
                {
                    title: "Blogs Full",
                    path: "/blogs/blogs-full"
                },
                {
                    title: "Blogs Left Sidebar",
                    path: "/blogs/blogs-left-sidebar"
                },
                {
                    title: "Blogs Left Sidebar Full",
                    path: "/blogs/blogs-left-sidebar-full"
                },
                {
                    title: "Blogs Right Sidebar",
                    path: "/blogs/blogs-right-sidebar"
                },
                {
                    title: "Blogs Right Sidebar Full",
                    path: "/blogs/blogs-right-sidebar-full"
                }

            ]
        },
        {
            id: 7,
            title: "Pages",
            details: [
                {
                    title: "Cart page",
                    path: "/cart-page"
                },
                {
                    title: "Checkout",
                    path: "/checkout"
                },
                {
                    title: "Forget Password",
                    path: "/forget-password"
                },
                {
                    title: "Order Complete",
                    path: "/order-complete"
                },
                {
                    title: "Track Order",
                    path: "/track-order"
                },
                {
                    title: "Sign In",
                    path: "/sign-in"
                },
                {
                    title: "Sign Out",
                    path: "/sign-out"
                },
                {
                    title: "Sign Up",
                    path: "/sign-up"
                },
                {
                    title: "Wishlist",
                    path: "/wishlist"
                },
                {
                    title: "Page Not Found",
                    path: "/page-not-found"
                },
                {
                    title: "Coming Soon",
                    path: "/coming-soon"
                }
            ]
        },
        {
            id: 8,
            title: "Blog Details",
            details: [
                {
                    title: "Blog Details Page",
                    path: "/blogs/blog-details"
                },
                {
                    title: "Blog Details Full",
                    path: "/blogs/blog-details-full"
                },
                {
                    title: "Blogdetails Left Sidebar",
                    path: "/blogs/blogdetails-left-sidebar"
                },
                {
                    title: "Blogdetails Left Sidebar Full",
                    path: "/blogs/blogdetails-left-sidebar-full"
                },
                {
                    title: "Blogdetails Right Sidebar",
                    path: "/blogs/blogdetails-right-sidebar"
                },
                {
                    title: "Blogdetails Right Sidebar Full",
                    path: "/blogs/blogdetails-right-sidebar-full"
                }

            ]
        },
        {
            id: 9,
            title: "Blogs",
            details: [
                {
                    title: "Blogs Page",
                    path: "/blogs/blogs"
                },
                {
                    title: "Blogs Full",
                    path: "/blogs/blogs-full"
                },
                {
                    title: "Blogs Left Sidebar",
                    path: "/blogs/blogs-left-sidebar"
                },
                {
                    title: "Blogs Left Sidebar Full",
                    path: "/blogs/blogs-left-sidebar-full"
                },
                {
                    title: "Blogs Right Sidebar",
                    path: "/blogs/blogs-right-sidebar"
                },
                {
                    title: "Blogs Right Sidebar Full",
                    path: "/blogs/blogs-right-sidebar-full"
                }

            ]
        }
    ]
}
export default PageDescription