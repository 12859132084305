import React from "react";
import BreadCrumb from "../GlobalComponents/BreadCrumb";
import PageDescription from "../globalUtility/jsonData/description/PageDescription"

class TermsAndConditions extends React.Component {
    render() {
        const termsAndConditions = (PageDescription && PageDescription.termsAndConditions) || []
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Home" subHeading="Terms & Conditions"/>
                {/* breadcrumb section end */}
                <section className="padding-80px-tb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 terms-con">
                                <div className="border-radius-5 terms-con-wrap">
                                    <div className="d-block text-black mt-3">
                                        {/* payment conditions title and content starts */}
                                        <h6 className="margin-bottom-5px font-weight-600 main-font md-title-mdall sm-text-extra-large">{termsAndConditions[0].title}</h6>
                                        <p className="padding-left-20px sm-text-large">{termsAndConditions[0].description}</p>
                                        {/* payment conditions title and content ends */}
                                    </div>
                                    {/* about returns title and content starts */}
                                    <div className="d-block text-black mt-3">
                                        <h6 className="margin-bottom-5px font-weight-600 main-font md-title-mdall sm-text-extra-large">{termsAndConditions[1].title}</h6>
                                        <p className="padding-left-20px sm-text-large">{termsAndConditions[1].description}</p>
                                    </div>
                                    {/* about returns title and content ends */}
                                    {/* disciplinery advice title and content starts */}
                                    <div className="d-block text-black mt-3">
                                        <h6 className="margin-bottom-5px font-weight-600 main-font md-title-mdall sm-text-extra-large">{termsAndConditions[2].title}</h6>
                                        <p className="padding-left-20px sm-text-large">{termsAndConditions[2].description}</p>
                                    </div>
                                    {/* disciplinery advice title and content starts */}
                                    {/* update date start */}
                                    <p className="text-gray">Last updated: 16<sup>th</sup> december 2019</p>
                                    {/* update date end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default TermsAndConditions
