import React from "react";
import {withRouter, Link} from "react-router-dom"
import ProductViewModal from "../../../globalUtility/ProductViewModal";
import {productList} from "../../../globalUtility/jsonData/product/ProductDataList";
import BreadCrumb from "../../../GlobalComponents/BreadCrumb";
import Pagination from "../../../GlobalComponents/Pagination";
import ProductSort from "../Common/ProductSort";
import Rating from "../Common/Rating";
import titleUpperCase from "../../../globalUtility/commonHelper";
import {addItemToCart} from "../../../actions/cartAction";
import {connect} from "react-redux";
import {addItemToWish} from "../../../actions/wishListAction";

class GridsPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            productDetails: {},
            isModal: false,
            productDataList: [],
            searchDataList: [],
            productSortDataList: [],
            searchText: "",
            sortType: ""
        }
    }

    onSearch = (searchText) => {
        const allData = productList
        if (searchText) {
            const record = (allData && allData.length && allData.filter(
                obj =>
                    ["shortDetails", "productTitle", "category", "subCategory", "brandName"].some(key => (
                        obj[key] && obj[key].toLowerCase().includes(searchText.toLowerCase())
                    ))
            )) || []
            this.setState({
                searchDataList: record,
                searchText: searchText || "",
                sortType: ""
            })
        } else {
            this.setState({
                searchText: ""
            })
        }
    }

    componentWillMount() {
        let activePage = window.location.href.split("/")[4]
        this.setState({
            productDataList: productList,
            isGridPageFull: activePage === "grids-page-full" || false
        })
        const {location} = this.props
        const searchText = (location.state && location.state.searchText) || ""
        this.onSearch(searchText)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {location} = nextProps
        const searchText = (location.state && location.state.searchText) || ""
        this.onSearch(searchText)
    }

    handelModal = (item) => {
        this.setState({
            isModal: !this.state.isModal,
            productPreview: item.productTitle ? item : {}
        })
    }

    handelActiveImg = (img, i, index) => {
        const {productDataList} = this.state
        productDataList[i].activeImg = img
        productDataList[i].activeImgIndex = index
        this.setState({
            productDataList
        })
    }

    handelSorting = (data, type) => {
        this.setState({
            productDataList: [],
        },() => {
            this.setState({
                sortType: type,
                searchText: "",
                productSortDataList: data,
            })
        })
    }

    onChangePage = (pageOfItems) => {
        this.setState({
            productDataList: pageOfItems
        });
    }

    render() {
        const {isModal, productPreview, productDataList, sortType, productSortDataList, searchText, searchDataList, isGridPageFull} = this.state

        return(
            <div>
                <BreadCrumb heading="Products" subHeading={isGridPageFull ? "Product Grids Full" : "Product Grids"} />
                <section className="exproducts-style1 padding-80px-tb grid-page">
                    <div className={isGridPageFull ? "container-fluid" : "container"}>
                        <div className="border-bottom-medium-dark2 margin-bottom-15px">
                            <div className="col-md-12">
                                <div className="row">
                                    {/* grid & list button starts */}
                                    <div className="col-lg-6 col-md-4 col-6 grid-list padding-bottom-15px no-padding-left border-radius-5 sm-display-flex sm-align-items-center view-mode">
                                        <Link to="/products/grids-page" className="btn small-btn-gl active">
                                            <i className="fa fa-th-large" />
                                        </Link>
                                        <Link to="/products/list-page" className="btn small-btn-gl"><i className="fa fa-bars" /></Link>
                                    </div>
                                    {/* grid & list button ends */}
                                    {/* sort by starts */}
                                    <ProductSort
                                        productDataList={productList}
                                        handelSorting={this.handelSorting}
                                    />
                                    {/* sort by ends */}
                                </div>
                            </div>
                        </div>
                        {/*quick view starts */}
                        {
                            isModal &&
                            <ProductViewModal
                                isModal={isModal}
                                productPreview={productPreview}
                                handelModal={this.handelModal}/>
                        }
                        {/*quick view ends */}
                        <div className="row grid-pro-main">
                            {/* product starts */}
                            {
                                productDataList.map((item, index) => {
                                    return (
                                        <div key={index} className="col-xl-3 col-lg-3 col-md-4 col-6 grid-pro-info">
                                            <div className="product-info">
                                                <div className="content-overlay">
                                                    {/* product image starts */}
                                                    <div className="product-img">
                                                        <div className="tab-content">
                                                            <div className="tab-pane active">
                                                                <img src={item.activeImg ? item.activeImg : item.pictures[0]}
                                                                     alt="product-detail"
                                                                     className="img-fluid" />
                                                            </div>
                                                        </div>
                                                        {item.new && <div className="new-offer bg-green">New</div>}
                                                        {item.bestFeature && <div className="new-offer bg-light-blue">Best Feature</div>}
                                                        {item.trade  && <div className="new-offer bg-light-red">Trade</div>}
                                                    </div>
                                                    {/* product image ends */}
                                                    <div className="content position-absolute opacity-none">
                                                        {/* icons starts */}
                                                        <ul className="no-padding-left">
                                                            <li>
                                                                <span onClick={() => this.props.addToCart(item)}
                                                                      className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block"><i className="fas fa-shopping-bag" />
                                                                </span>
                                                            </li>
                                                            <li>
                                                            <span onClick={()=>this.handelModal(item)}
                                                               className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block cursor-pointer"><i className="fas fa-eye" />
                                                            </span>
                                                            </li>
                                                            <li>
                                                                <span onClick={() => this.props.addToWish(item)}
                                                                   className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block"><i className="fas fa-heart" />
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        {/* icons ends */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="g-pro-detail">
                                                {/* product description starts */}
                                                <div className="protext text-center">
                                                    <Link to={`/product/${item.id}`} className="d-inline-block text-dark-gray font-weight-600 hoverstyle1">
                                                        {titleUpperCase(item.productTitle)}
                                                    </Link>
                                                    <p className="pricetext font-weight-600">
                                                        ${item.salePrice && item.salePrice.toFixed(2)}
                                                    </p>
                                                </div>
                                                {/* product ratings starts */}
                                                <Rating ratingStars={item.rating}/>
                                                {/* product description ends */}
                                                <div className="small-img">
                                                    {/* product image ends */}
                                                    <ul className="preview-thumbnail nav nav-tabs">
                                                        {
                                                            item.pictures.map((img, i) =>(
                                                                <li key={i}>
                                                                    <Link to="#" className={item.activeImgIndex === i ? "active show" : ""} onClick={()=> this.handelActiveImg(img, index, i)} data-toggle="tab">
                                                                        <img src={img} alt="product-detail" className="i mg-fluid w-100" />
                                                                    </Link>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                    {/* product below image end */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Pagination
                            pageSize={8}
                            sortType={sortType}
                            items={sortType ? productSortDataList : searchText ? searchDataList : productList}
                            onChangePage={this.onChangePage} />
                    </div>
                </section>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item)),
    addToWish: (data) => dispatch(addItemToWish(data)),
})

export default withRouter(connect(
    null,
    mapDispatchToProps
)(GridsPage))
