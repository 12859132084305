import React from "react";
import SideBar from "../Common/SideBar";
import BlogsList from "../Common/BlogsList";
import BreadCrumb from "../../../GlobalComponents/BreadCrumb";
import Pagination from "../../../GlobalComponents/Pagination";
import {blogComments} from "../../../globalUtility/commonHelper";

class BlogsLeftSidebar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            blogList: [],
        }
    }

    componentWillMount() {
        const data = blogComments()
        let categoryType = (this.props.location && this.props.location.state && this.props.location.state.categoryType)
        if(categoryType && categoryType.length){
            this.handelCategories(categoryType)
        }else {
            this.setState({ data })
        }
    }

    onChangePage = (pageOfItems) => {
        this.setState({
            blogList: pageOfItems
        });
    }

    handelCategories = (item) =>{
        const data = blogComments()
        let category = item.toString()
        const getData = data.blogList.filter(p => p.category === category)
        this.setState({
            data:{
                blogList: getData || []
            }
        })
    }

    render() {
        const {blogList, data} = this.state
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Blogs" subHeading="Blogs Left Sidebar"/>
                {/* breadcrumb section end */}
                <section className="blog-page padding-80px-tb blog-detailsstyle1">
                    <div className="container">
                        <div className="row">
                            {/* left sidebar starts */}
                            <SideBar handelCategories={this.handelCategories}/>
                            {/* left sidebar ends */}
                            <div className="col-xl-9 col-lg-9 col-md-8">
                                {/* blogs starts */}
                                <BlogsList blogList={blogList}/>
                                {/* blogs ends */}
                                <Pagination
                                    pageSize={9}
                                    sortType=""
                                    items={data.blogList}
                                    onChangePage={this.onChangePage} />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default BlogsLeftSidebar
