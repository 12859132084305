import React from "react";
import BreadCrumb from "../GlobalComponents/BreadCrumb";

class TrackOrder extends React.Component {
    render() {
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Pages" subHeading="Track Order"/>
                {/* breadcrumb section end */}
                {/* track order section starts */}
                <section className="padding-80px-tb sm-margin-left-right-15px">
                    <div className="container bg-gray border-radius-5 order-status-style1 ">
                        <div className="row">
                            <div className="col-md-12 margin-top-15px">
                                <div className="float-md-left track-drt">
                                    {/* orderid starts */}
                                    <div className="track-or-text font-weight-600 d-inline-block margin-bottom-10px sm-no-margin-bottom sm-text-large">Tracking your order id-
                                        <span className="text-black p-1 bg-white border-radius-5">WLMD945</span>
                                    </div>
                                    {/* orderid ends */}
                                    {/* updated page details starts */}
                                    <div className="track-update text-gray d-inline-block font-weight-600 pl-1 sm-text-large">Updated: <span className="text-black">2 hours ago</span></div>
                                    {/* updated page details ends */}
                                    {/* Expected Delivery starts */}
                                    <div>
                                        <p className="tr-del d-inline-block font-weight-600 border-top-light sm-no-margin-bottom sm-text-large">Expected delivery: <span className="text-light-green font-weight-600 margin-left-5px">Monday, june 2 ,2019, by 8:00 pm</span></p>
                                    </div>
                                    {/* Expected Delivery ends */}
                                </div>
                                {/* order status starts  */}
                                <div className="float-md-right track-drt margin-bottom-10px mb-lg-0 sm-text-large">
                                    <span className="tr-or-st d-inline-block font-weight-600 btn-group-vertical">Status:</span>
                                    <div className="status-od p-2 rounded-circle bg-light-green d-inline-block btn-group-vertical" />
                                    <span className="tr-or-st d-inline-block font-weight-600 btn-group-vertical">Out for delivery</span>
                                </div>
                                {/* order status ends  */}
                            </div>
                        </div>
                        <div className="row border-top-black bg-white order-map-line">
                            {/* order on progress showing section starts */}
                            <div className="col-xl-8 center-col col-12 text-md-center d-flex d-md-block flex-column position-relative margin-top-40px lg-margin-top-30px md-margin-top-25px sm-margin-top-20px">
                                <div className="d-inline-block text-center ord-rec-after mr-0 mr-md-5">
                                    <div className="bg-sky-blue-light p-4 rounded-circle d-inline-block"><i className="far fa-handshake text-white fa-2x" /></div>
                                    <h6 className="d-block mt-1 text-medium font-weight-600 sm-text-large">Order Received</h6>
                                </div>
                                <div className="d-inline-block text-center order-status-round ship-before ship-after ml-0 ml-md-5 mr-0 mr-md-5 mt-5 mt-md-0">
                                    <div className="bg-sky-blue-light p-4 rounded-circle d-inline-block"><i className="fas fa-box-open text-white fa-2x" /></div>
                                    <h6 className="d-block mt-1 text-medium font-weight-600 sm-text-large">Shipped</h6>
                                </div>
                                <div className="d-inline-block text-center order-status-round o4d-before o4d-after ml-0 ml-md-5 mr-0 mr-md-5 mt-5 mt-md-0">
                                    <i className="fas fa-caret-down d-block" />
                                    <div className="bg-sky-blue-light p-4 rounded-circle d-inline-block"><i className="fas fa-truck-pickup text-white fa-2x" /></div>
                                    <h6 className="d-block mt-1 text-medium font-weight-600 sm-text-large">Out For Delivery</h6>
                                </div>
                                <div className="d-inline-block text-center order-status-round delivered-before ml-0 ml-md-5 mt-5 mt-md-0">
                                    <div className="bg-gray p-4 rounded-circle d-inline-block"><i className="far fa-check-circle text-black fa-2x" /></div>
                                    <h6 className="d-block mt-1 text-medium font-weight-600 sm-text-large">Delivered</h6>
                                </div>
                            </div>
                            {/* order on progress showing section ends */}
                        </div>
                        <div className="row bg-white track-content">
                            <div className="col-md-12 no-padding-lr margin-top-40px lg-margin-top-30px md-margin-top-25px sm-margin-top-20px">
                                {/* tracking details starts */}
                                <details>
                                    <summary className="w-100 track-title text-left text-black text-large sm-text-extra-large border-radius-5 outline-none padding-10px">Tracking Details</summary>
                                    <div className="row bg-gray no-margin border-radius-5">
                                        <div className="col-12 no-padding trackdetails-style1">
                                            <div className="row no-margin">
                                                <div className="w-100 d-inline-block">
                                                    <div className="margin-top-10px border-bottom-medium-dark2">
                                                        <h6 className="d-inline-block w-50 float-left text-medium font-weight-600 padding-left-15px margin-bottom-10px sm-text-large">Thursday, june 4, 2018 at 8:00 pm, Los Angeles USA.</h6>
                                                        <p className="d-inline-block w-50 text-medium font-weight-600 margin-bottom-10px sm-text-large md-padding-left-15px sm-padding-left-5px sm-position-relative sm-bottom-3">Out for delivery</p>
                                                        <div className="clearfix" />
                                                    </div>
                                                    <div className="margin-top-10px border-bottom-medium-dark2">
                                                        <h6 className="d-inline-block w-50 float-left text-medium font-weight-600 padding-left-15px margin-bottom-10px sm-text-large">Thursday, june 4, 2018 at 4:30 pm, Los Angeles USA.</h6>
                                                        <p className="d-inline-block w-50 text-medium font-weight-600 margin-bottom-10px sm-text-large md-padding-left-15px sm-padding-left-5px sm-position-relative sm-bottom-3">Out for delivery</p>
                                                        <div className="clearfix" />
                                                    </div>
                                                    <div className="margin-top-10px border-bottom-medium-dark2">
                                                        <h6 className="d-inline-block w-50 float-left text-medium font-weight-600 padding-left-15px margin-bottom-10px sm-text-large">Wednesday, june 3, 2018 at 9:30 am, Los Angeles USA.</h6>
                                                        <p className="d-inline-block w-50 text-medium font-weight-600 margin-bottom-10px sm-text-large md-padding-left-15px sm-padding-left-5px">Package arrived at courier facility</p>
                                                        <div className="clearfix" />
                                                    </div>
                                                    <div className="margin-top-10px">
                                                        <h6 className="d-inline-block w-50 float-left text-medium font-weight-600 padding-left-15px margin-bottom-10px sm-text-large">Monday, june 1, 2018 at 2:30 pm, Austin USA.</h6>
                                                        <p className="d-inline-block w-50 text-medium font-weight-600 margin-bottom-10px sm-text-large md-padding-left-15px sm-padding-left-5px">Package has left seller facility and is in transit to carrier</p>
                                                        <div className="clearfix" />
                                                    </div>
                                                </div>
                                                {/* print link starts */}
                                                <i className="fas fa-print hoverstyle1 title-small p-3 position-absolute bg-black bottom-0 right-0 text-white rounded-circle" />
                                                {/* print link ends */}
                                            </div>
                                        </div>
                                    </div>
                                </details>
                                {/* tracking details ends */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* track order section ends */}
            </>
        )
    }

}

export default TrackOrder;
