import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Rating from "../Components/Product/Common/Rating";
import titleUpperCase from "./commonHelper";
import {addItemToCart} from "../actions/cartAction";
import {connect} from "react-redux";
import {addItemToWish} from "../actions/wishListAction";
import {productCompareAction} from "../actions/productCompareAction";

class ProductViewModal extends React.Component{
    render() {
        const { isModal, productPreview, handelModal } = this.props
        return(
            <Modal size="lg" isOpen={isModal} toggle={handelModal}>
                <ModalHeader toggle={handelModal}>Product Quickview</ModalHeader>
                <ModalBody>
                    <div className="modal-body1">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <img src={(productPreview && productPreview.activeImg) || (productPreview.pictures && productPreview.pictures[0])}
                                     className="img-fluid border-radius-5" alt={productPreview.pictures && productPreview.pictures[0]} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                {/* product name starts */}
                                <div className="produc-name-details margin-bottom-10px sm-margin-top-15px">
                                    <h6 className="d-inline-block title-small font-weight-600 lg-text-extra-large md-title-mdall sm-text-extra-large">
                                        {titleUpperCase((productPreview && productPreview.productTitle) || "")}
                                    </h6>
                                </div>
                                {/* product name ends */}
                                {/* product price starts */}
                                <div className="font-weight-600 text-large margin-bottom-10px md-text-large sm-text-large">
                                    ${(productPreview.salePrice && productPreview.salePrice.toFixed(2)) || 0}
                                    <span className="text-decoration-line-through text-medium text-gray pl-2 md-text-medium sm-text-medium">
                                    ${(productPreview.price && productPreview.price.toFixed(2)) || 0}
                                </span>
                                </div>
                                {/* product price ends */}
                                {/* product availablity starts */}
                                <div className="product-availablity margin-bottom-10px sm-text-large">
                                    <span className="display-inline-block font-weight-600">Availablity:</span>&nbsp;
                                    <span className="display-inline-block font-weight-600 text-green">In Stocks</span>
                                </div>
                                {/* product availablity ends */}
                                {/* product colors availablity starts */}
                                {
                                    (productPreview && productPreview.colors && productPreview.colors.length) > 0 &&
                                    <div className="d-inline-block margin-bottom-10px sm-text-large">
                                        <span className="d-inline-block font-weight-600">Colors Availablity :</span>
                                        <div className="d-inline-block vertical-align-texttop">&nbsp;
                                            {
                                                ((productPreview && productPreview.colors) || []).map((m, index) => {
                                                    return(
                                                        <span key={index} style={{backgroundColor: m}} className="mr-1 p-2 d-inline-block rounded" />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {/* product colors availablity ends */}
                                {/* product ratings starts */}
                                <Rating ratingStars={productPreview.rating}/>
                                {/* product ratings ends */}
                                {/* product description starts */}
                                <div className="product-description">
                                    <p className="sm-text-large">
                                        {productPreview.description || ""}
                                    </p>
                                </div>
                                {/* product description ends */}
                                <div className="d-inline-block">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="d-inline-block">
                                        <span onClick={() => this.props.addToCart(productPreview)} className="btn btn-style1 ml-2">
                                            <i className="fas fa-shopping-bag pr-2" />ADD TO CART
                                        </span>
                                        </div>
                                        {/* product add to cart ends */}
                                    </div>
                                </div>
                                {/* links starts  */}
                                <div className="mt-3">
                                    <span onClick={() => this.props.addToWish(productPreview)}>
                                        <i className="fas fa-plus-square text-black" />&nbsp;
                                        <span className="mt-2 hoverstyle1 font-weight-600 text-decoration-underline text-black-ni sm-text-large">Add To Wishlist</span>
                                    </span>
                                    <span onClick={() => this.props.addToCompareProduct(productPreview)}>
                                        <i className="fas fa-plus-square text-black ml-3" />&nbsp;
                                        <span className="mt-2 hoverstyle1 font-weight-600 text-decoration-underline text-black-ni sm-text-large">Add To Compare</span>
                                    </span>
                                </div>
                                {/* links ends  */}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={handelModal}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item)),
    addToCompareProduct: (item) => dispatch(productCompareAction(item)),
    addToWish: (data) => dispatch(addItemToWish(data)),
})

export default connect(
    null,
    mapDispatchToProps
)(ProductViewModal)
