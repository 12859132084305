import React from "react";
import {Link} from "react-router-dom"
import imageUtil from "../globalUtility/imageHelper";

class Footer extends React.Component {
    render() {
        const images = imageUtil.images
        let url = window.location.href.split("/")
        const urlPath = url && url[3]
        return(
            <div>
                {/* footer section starts */}
                <section className="footercontents padding-80px-tb bg-black" style={{backgroundImage: `url(${urlPath === "home1" ? images.footerBgImg : ""})`}}>
                    <div className="container">
                        <div className="row paddingforfooter">
                            <div className="col-xl-3 col-lg-3 col-md-6 text-left sm-margin-bottom-20px">
                                {/* footer logo starts */}
                                <img src={images.macroLogo} alt="img" />
                                {/* footer logo ends */}
                                {/* footer description starts */}
                                <p className="padding-top-20px text-gray letter-spacing-half sm-text-large">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                {/* footer description ends */}
                                <div className="d-flex align-items-center">
                                    {/* footer social icons starts */}
                                    <div className="social-icon display-inline-block title-small">
                                        <h5 className="main-font text-large font-weight-600 text-sky-blue">Follow Us</h5>
                                        <a href="https://web.facebook.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
                                        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter" /></a>
                                        <a href="https://plus.google.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-google-plus-g" /></a>
                                        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
                                    </div>
                                    {/* footer social icons ends */}
                                </div>
                            </div>
                            {/* customer support section starts */}
                            <div className=" col-xl-3 col-lg-3 col-md-6 text-left sm-margin-bottom-10px md-margin-bottom-20px">
                                {/* customer support starts */}
                                <div className="foot-customer letter-spacing-half sm-display-none">
                                    <h5 className="main-font text-large font-weight-600 text-sky-blue">Customer Support</h5>
                                    <Link to="/cancellation" className="text-gray padding-top-20px hoverstyle1 d-block margin-bottom-15px">Cancellation</Link>
                                    <Link to="/faq's" className="text-gray hoverstyle1 d-block margin-bottom-15px">Faq’s</Link>
                                    <Link to="/privacy-policy" className="text-gray pt-0 hoverstyle1 d-block margin-bottom-15px">Privacy Policy</Link>
                                    <Link to="/site-map" className="text-gray  hoverstyle1 d-block margin-bottom-15px">Sitemap</Link>
                                    <Link to="/terms-and-conditions" className="text-gray hoverstyle1 d-block">Terms &amp; Conditions</Link>
                                </div>
                                {/* customer support ends */}
                                {/* responsive customer support starts */}
                                <div className="panel-group panel bg-black-dark-rgba padding-10px border-radius-5 position-relative sm-padding-0px d-none sm-display-block sm-line-height-0" id="accordion1">
                                    <div className="panel-title text-large">
                                        <a data-toggle="collapse" data-parent="#accordion1" href="#collapse1" className="text-sky-blue collapsed main-font text-large font-weight-600 sm-no-letter-spacing sm-text-extra-large" aria-expanded="false">Customer Support
                                            {/* plus icon starts */}
                                            <i className="fas fa-plus-circle position-absolute right-1p sm-right-3p sm-top-5px top-15" />
                                            {/* plus icon ends */}
                                        </a>
                                    </div>
                                    <div id="collapse1" className="panel-collapse in collapse bg-black sm-no-letter-spacing sm-text-large">
                                        <Link to="/track-order" className="text-gray padding-top-30px hoverstyle1 d-block margin-bottom-30px">Track package</Link>
                                        <Link to="/cancellation" className="text-gray hoverstyle1 d-block margin-bottom-30px">Cancellation</Link>
                                        <Link to="/faq's" className="text-gray hoverstyle1 d-block margin-bottom-30px">Faq’s</Link>
                                        <Link to="/privacy-policy" className="text-gray pt-0 hoverstyle1 d-block margin-bottom-30px">Privacy Policy</Link>
                                        <Link to="/site-map" className="text-gray  hoverstyle1 d-block margin-bottom-30px">Sitemap</Link>
                                        <Link to="/terms-and-conditions" className="text-gray hoverstyle1 d-block margin-bottom-30px">Terms &amp; Conditions</Link>
                                    </div>
                                </div>
                                {/* responsive customer support ends */}
                            </div>
                            {/* customer support section ends */}
                            {/* my account section starts */}
                            <div className="col-xl-3 col-lg-3 col-md-6 text-left foot-contact sm-margin-bottom-10px">
                                {/* my account starts */}
                                <div className="foot-customer letter-spacing-half sm-display-none">
                                    <h5 className="main-font text-large font-weight-600 text-sky-blue">My Account</h5>
                                    <Link to="/sign-in" className="text-gray padding-top-20px hoverstyle1 d-block margin-bottom-15px">My Account</Link>
                                    <Link to="/track-order" className="text-gray hoverstyle1 d-block margin-bottom-15px">Track package</Link>
                                    <Link to="/wishlist" className="text-gray hoverstyle1 d-block margin-bottom-15px">WishList</Link>
                                    <Link to="/newsletters" className="text-gray pt-0 hoverstyle1 d-block margin-bottom-15px">Newsletter</Link>
                                </div>
                                {/*  my account ends */}
                                {/* responsive my account starts */}
                                <div className="panel-group	panel bg-black-dark-rgba padding-10px border-radius-5 position-relative sm-padding-0px sm-line-height-0" id="accordion2">
                                    <div className="panel-title text-large">
                                        <a data-toggle="collapse" data-parent="#accordion2" href="#collapse2" className="text-sky-blue collapsed main-font text-large font-weight-600 sm-no-letter-spacing d-none sm-display-block sm-text-extra-large" aria-expanded="false">My Account
                                            {/* plus icon starts */}
                                            <i className="fas fa-plus-circle position-absolute right-1p sm-right-3p sm-top-5px top-15" />
                                            {/* plus icon ends */}
                                        </a>
                                    </div>
                                    <div id="collapse2" className="panel-collapse in collapse bg-black sm-no-letter-spacing sm-text-large">
                                        <Link to="/sign-in" className="text-gray padding-top-30px hoverstyle1 d-block margin-bottom-30px">My Account</Link>
                                        <Link to="#" className="text-gray hoverstyle1 d-block margin-bottom-30px">Order History</Link>
                                        <Link to="/wishlist" className="text-gray hoverstyle1 d-block margin-bottom-30px">WishList</Link>
                                        <Link to="/newsletters" className="text-gray pt-0 hoverstyle1 d-block margin-bottom-30px">Newsletter</Link>
                                        <Link to="/site-map" className="text-gray hoverstyle1 d-block margin-bottom-30px">Sitemap</Link>
                                    </div>
                                </div>
                                {/* responsive my account ends */}
                            </div>
                            {/* my account section ends */}
                            {/* store information section starts */}
                            <div className="col-xl-3 col-lg-3 col-md-6 text-left">
                                {/* store information starts */}
                                <div className="foot-customer letter-spacing-half sm-display-none">
                                    <h5 className="main-font text-large font-weight-600 text-sky-blue">Store Information</h5>
                                    <div className="padding-top-10px">
                                        <div className="store-icon display-inline-block">
                                            <i className="fas fa-map-marker-alt text-white display-table-cell-vertical-middle text-extra-large" />
                                        </div>
                                        <div className="display-inline-block text-gray padding-top-10px">Demo Store On Road 10.<br />United States</div>
                                    </div>
                                    <div className="padding-top-10px">
                                        <div className="store-icon display-inline-block">
                                            <i className="fas fa-phone-volume text-white vertical-align-middle text-extra-large" />
                                        </div>
                                        <div className="display-inline-block ">
                                            <a href="tel:+6494461709" className="text-gray hoverstyle1 vertical-align-middle">+6494461709</a>
                                        </div>
                                    </div>
                                    <div className="padding-top-10px">
                                        <div className="store-icon display-inline-block">
                                            <i className="far fa-envelope text-white vertical-align-middle text-extra-large" />
                                        </div>
                                        <div className="display-inline-block sm-no-letter-spacing">
                                            <a href="mailto:demo@demo.com" className="text-gray hoverstyle1 vertical-align-middle">demo@demo.com</a>
                                        </div>
                                    </div>
                                    <div className="padding-top-10px">
                                        <div className="store-icon display-inline-block">
                                            <i className="fab fa-skype text-white vertical-align-middle text-extra-large" />
                                        </div>
                                        <div className="display-inline-block ">
                                            <a href="skype:-Js Kelin-?chat" className="text-gray hoverstyle1 vertical-align-middle">Jd Kelin-Skype</a>
                                        </div>
                                    </div>
                                </div>
                                {/* store information ends */}
                                {/* responsive store information starts */}
                                <div className="panel-group	 panel bg-black-dark-rgba padding-10px border-radius-5 position-relative sm-padding-0px d-none sm-display-block" id="accordion3">
                                    <div className="panel-title text-large">
                                        <a data-toggle="collapse" data-parent="#accordion3" href="#collapse3" className="text-sky-blue collapsed main-font sm-no-letter-spacing text-large font-weight-600 text-sky-blue sm-text-extra-large" aria-expanded="false">Store Information
                                            {/* plus icon starts */}
                                            <i className="fas fa-plus-circle position-absolute right-1p sm-right-3p sm-top-5px top-15" />
                                            {/* plus icon ends */}
                                        </a>
                                    </div>
                                    <div id="collapse3" className="panel-collapse in collapse sm-no-letter-spacing bg-black sm-text-large">
                                        <div className="padding-top-10px">
                                            <div className="store-icon display-inline-block">
                                                <i className="fas fa-map-marker-alt text-white display-table-cell-vertical-middle text-extra-large" />
                                            </div>
                                            <div className="display-inline-block text-gray padding-top-10px">Demo Store On Road 10.<br />United States</div>
                                        </div>
                                        <div className="padding-top-10px">
                                            <div className="store-icon display-inline-block">
                                                <i className="fas fa-phone-volume text-white vertical-align-middle text-extra-large" />
                                            </div>
                                            <div className="display-inline-block">
                                                <a href="tel:+6494461709" className="text-gray hoverstyle1 vertical-align-middle">+6494461709</a>
                                            </div>
                                        </div>
                                        <div className="padding-top-10px">
                                            <div className="store-icon display-inline-block">
                                                <i className="far fa-envelope text-white vertical-align-middle text-extra-large" />
                                            </div>
                                            <div className="display-inline-block">
                                                <a href="mailto:demo@demo.com" className="text-gray hoverstyle1 vertical-align-middle">demo@demo.com</a>
                                            </div>
                                        </div>
                                        <div className="padding-top-10px">
                                            <div className="store-icon display-inline-block">
                                                <i className="fab fa-skype text-white vertical-align-middle text-extra-large" />
                                            </div>
                                            <div className="display-inline-block">
                                                <a href="mailto:demo@demo.com" className="text-gray hoverstyle1 vertical-align-middle">Jd Kelin-Skype</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* responsive store information ends */}
                            </div>
                            {/* store information section ends */}
                        </div>
                    </div>
                </section>
                <footer className="bg-sky-blue-light py-2">
                    <div className="container">
                        <div className="row ">
                            {/* footer copyright texts starts */}
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 copy-right-content letter-spacing-half">
                                <span className="copyright text-white">Copyright <i className="far fa-copyright" /> 2019 All Rights Reserved</span>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 letter-spacing-half text-right sm-text-center">
                                <div className="payment-icon display-inline-block">
                                    <Link to="#">
                                        <i className="fab fa-cc-visa pr-2 socialhover-style1 text-white title-greatersmall" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-cc-mastercard pr-2 socialhover-style1 text-white title-greatersmall" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-cc-amazon-pay pr-2 socialhover-style1 text-white title-greatersmall" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-cc-paypal socialhover-style1 text-white title-greatersmall" />
                                    </Link>
                                </div>
                            </div>
                            {/* footer copyright texts ends */}
                        </div>
                    </div>
                </footer>
                {/* footer section ends */}
            </div>
        )
    }

}

export default Footer;
