import React from "react"
import imageUtil from "../../../globalUtility/imageHelper";
import { Link } from "react-router-dom";
import {productList} from "../../../globalUtility/jsonData/product/ProductDataList";
import titleUpperCase from "../../../globalUtility/commonHelper";

class ProductSideBar extends React.Component{

    state ={
        currentAmt: 10000,
        colorsListProduct: [],
        brandList: [],
        activeColorIndex: ''
    }

    componentDidMount() {
        const strings = productList

        const allBrand = strings.map(l => l.brandName)
        const allCategory = strings.map(h => h.category)

        let allColors = []
        strings.forEach((item, index) => {
            item.colors.filter((item, index) => {
                allColors.push(item)
            });
        })

        let uniqueColor = allColors.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        })

        let allBrandList = allBrand.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        })

        let allCategoryList = allCategory.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        })

        if(uniqueColor.length > 0){
            this.setState({
                colorsListProduct: uniqueColor,
                allBrandList: allBrandList || [],
                allCategoryList: allCategoryList
            })
        }
    }

    handelChange = (e) =>{
        const { value} = e.target
        this.setState({
            currentAmt: value
        },()=>{
            this.props.handelPriceRangeComplete(value)
        })
    }

    handelColor = (item, index) =>{
        this.setState({
            activeColorIndex: index
        })
        this.props.handelGetColorProductData(item)
    }

    handelBrandCategory = (item, index, category) =>{
        this.setState({
            activeBrandCategoryIndex: index,
        })
        this.props.handelGetCategoriesProduct(item, "brand")
    }

    handelClothCategory = (item, index) =>{
        this.setState({
            activeClothCategoryIndex: index,
        })
        this.props.handelGetCategoriesProduct(item, "category")
    }

    render() {
        const {colorsListProduct, allBrandList, activeColorIndex, activeClothCategoryIndex, activeBrandCategoryIndex} = this.state
        const images = imageUtil.images
        const clothCategory = ["men", "women", "kids"]
        return(
            <div className="col-xl-3 col-lg-3 col-md-4 left-gl-sidebar">
                <div className="categorie">
                    {/* categories grids starts */}
                    <div className="sidebar-content">
                        {/* heading starts */}
                        <div className="padding-bottom-15px">
                            <h6 className="border-bottom-khaki d-inline-block font-weight-600 main-font text-extra-large md-title-mdall sm-text-extra-large">Categories</h6>
                        </div>
                        {/* heading ends */}
                        {/* clothing starts */}
                        <div className="panel" id="accordion4">
                            <div className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion4" href="#collapse4" className="text-black hoverstyle1 text-medium font-weight-600 md-text-large">Clothing
                                    <i className="fas fa-angle-down float-right padding-right-5px padding-top-5px" />
                                </a>
                            </div>
                            <div id="collapse4" className="panel-collapse collapse in">
                                <ul className="list-unstyled text-medium mb-0 sm-text-large">
                                    {
                                        clothCategory.map((item , index) =>{
                                            return(
                                                <li key={index}>
                                                    <span onClick={()=>this.handelClothCategory(item, index)}
                                                          className={`text-black hoverstyle1 letter-spacing-0 ${activeClothCategoryIndex === index ? 'border-bottom-khaki' : ''}`}>
                                                        {titleUpperCase(item)}
                                                    </span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        {/* clothing ends */}
                        {/* best brands starts */}
                        <div className="panel" id="accordion5">
                            <div className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion5" href="#collapse5" className="text-black hoverstyle1 text-medium main-font font-weight-600">Best Brands
                                    <i className="fas fa-angle-down float-right padding-right-5px padding-top-5px" />
                                </a>
                            </div>
                            <div id="collapse5" className="panel-collapse collapse in">
                                <ul className="list-unstyled text-medium mb-0 sm-text-large">
                                    {
                                        (allBrandList || []).map((item, index) =>{
                                            return(
                                                <li key={index}>
                                                    <span onClick={()=> this.handelBrandCategory(item, index)}
                                                          className={`text-black hoverstyle1 letter-spacing-0 ${activeBrandCategoryIndex === index ? 'border-bottom-khaki' : ''}`}>
                                                        {titleUpperCase(item)}
                                                    </span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        {/* best brands ends */}
                        {/* furniture starts */}
                        <div className="panel">
                            <Link to="/category/furniture" className="text-black text-medium hoverstyle1 main-font font-weight-600 md-text-large">
                                Furniture
                            </Link>
                        </div>
                        {/* furniture end */}
                        {/* categories grids ends */}
                    </div>
                    {/* price grids starts */}
                    <div className="sidebar-content">
                        <div className="padding-bottom-15px">
                            <h6 className="border-bottom-khaki d-inline-block font-weight-600 text-extra-large md-title-mdall sm-text-extra-large">
                                Price
                            </h6>
                        </div>
                        <div className="slidecontainerstyle1">
                            <input type="range"
                                   min={10}
                                   max={10000}
                                   defaultValue={10000}
                                   onChange={this.handelChange}
                                   className="slider bg-gray w-100"
                                   id="myRange" />
                            <p className="font-weight-600 text-meduim mb-0">Value: ${this.state.currentAmt} <span id="value" /></p>
                        </div>
                    </div>
                    {/* price grids ends */}
                    {/* tags grids starts */}
                    <div className="sidebar-content">
                        <div className="padding-bottom-15px">
                            <h6 className="border-bottom-khaki d-inline-block font-weight-600 main-font text-extra-large md-title-mdall sm-text-extra-large">Tags</h6>
                        </div>
                        <div className="tag">
                            {/* hash tag starts */}
                            <ul className="no-padding no-margin-bottom tags-product sm-text-large">
                                <li className="margin-bottom-10px d-inline-block border-radius-5">
                                    <Link to="#" className="text-black font-weight-600">#i-phone</Link>
                                </li>
                                <li className="margin-bottom-10px d-inline-block border-radius-5">
                                    <Link to="#" className="text-black font-weight-600">#valentine</Link>
                                </li>
                                <li className="margin-bottom-10px d-inline-block border-radius-5">
                                    <Link to="#" className="text-black font-weight-600">#mwc</Link>
                                </li>
                                <li className="d-inline-block border-radius-5">
                                    <Link to="#" className="text-black font-weight-600">#puppy</Link>
                                </li>
                                <li className="d-inline-block border-radius-5">
                                    <Link to="#" className="text-black font-weight-600">#RAF100</Link>
                                </li>
                                <li className="d-inline-block border-radius-5">
                                    <Link to="#" className="text-black font-weight-600">#nex</Link>
                                </li>
                            </ul>
                            {/* hash tag ends */}
                        </div>
                    </div>
                    {/* tags grids ends */}
                    {/* color grids starts */}
                    <div className="sidebar-content">
                        {/* color heading starts */}
                        <div className="padding-bottom-15px">
                            <h6 className="border-bottom-khaki d-inline-block font-weight-600 main-font text-extra-large md-title-mdall sm-text-extra-large">Select By Colors</h6>
                        </div>
                        {/* color heading ends */}
                        <div className="d-inline-flex flex-column">
                            <div className="display-inline-block">
                                {
                                    colorsListProduct.map((item, index) =>{
                                        return(
                                            <div key={index} style={{backgroundColor: item}}
                                                 onClick={()=>this.handelColor(item, index)}
                                                 className={`border-radius-5 margin-top-5px margin-right-7px padding-10px d-inline-block float-left color-active ${activeColorIndex === index ? 'current' : ''}`} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {/* color grids ends */}
                    <div className="sidebar-content">
                        <div className="padding-bottom-15px">
                            <h6 className="border-bottom-khaki d-inline-block font-weight-600 main-font text-extra-large md-title-mdall sm-text-extra-large">Best Offer</h6>
                        </div>
                        {/* grids image starts */}
                        <div className="blog-img">
                            <figure className="no-margin-bottom">
                                <img src={images.sidebarImage} className="img-fluid border-radius-5" alt="img" />
                            </figure>
                        </div>
                        {/* grids image ends */}
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductSideBar
