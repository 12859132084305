import React from "react"

const ProductSort = ({productDataList, handelSorting}) =>{

    const onSelect = (e) => {
        const { value } = e.target

        switch (value) {
            case "price_low_to_high":
                const priceLowToHigh = productDataList.sort((a, b) => parseFloat(a.salePrice) - parseFloat(b.salePrice));
                return handelSorting(priceLowToHigh, value)
            case "price_high_to_low":
                const priceHighToLow = productDataList.sort((a, b) => parseFloat(b.salePrice) - parseFloat(a.salePrice));
                return handelSorting(priceHighToLow, value)
            case "rating_high_to_low":
                const ratingHighToLow = productDataList.sort((a, b) => parseFloat(a.rating) - parseFloat(b.rating));
                return handelSorting(ratingHighToLow, value)
            case "rating_low_to_high":
                const ratingLowToHigh = productDataList.sort((a, b) => parseFloat(b.rating) - parseFloat(a.rating));
                return handelSorting(ratingLowToHigh, value)
            case "ascending_order":
                const ascendingOrder = productDataList.sort((a, b) => {
                    const nameA = a && a.productTitle && a.productTitle.toLowerCase()
                    const nameB = b && b.productTitle && b.productTitle.toLowerCase()
                    if (nameA < nameB)
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0
                })
                return handelSorting(ascendingOrder, value)
            case "descending_order":
                const descendingOrder = productDataList.sort((a, b) => {
                    const nameA = a && a.productTitle && a.productTitle.toLowerCase()
                    const nameB = b && b.productTitle && b.productTitle.toLowerCase()
                    if (nameA > nameB)
                        return -1
                    if (nameA < nameB)
                        return 1
                    return 0
                })
                return handelSorting(descendingOrder, value)
            case "popular":
                const popular = productDataList.filter(a => (a.isPopular === true))
                return handelSorting(popular, value)
            default:
                return handelSorting(productDataList, value)
        }
    }

    return (
        <div className="col-lg-6 col-md-8 col-6 padding-bottom-10px sm-text-left text-right no-padding-right sm-display-flex sm-align-items-center sort-by">
            <div className="grid-list-content border-radius-5 d-inline-block sm-display-none">
                <h6 className="font-weight-600 main-font text-medium">Sort By</h6>
            </div>
            <div className="dropdown sort-by-dropdown d-inline-block">
                <div className="select-wrapper">
                    <select onChange={onSelect} className="form-control text-extra-medium font-weight-600">
                        <option value="default_list">Default</option>
                        <option value="ascending_order">Name (A - Z)</option>
                        <option value="descending_order">Name (Z - A)</option>
                        <option value="price_low_to_high">Price (Low &amp; High)</option>
                        <option value="price_high_to_low">Price (High &amp; Low)</option>
                        <option value="rating_low_to_high">Rating (Highest)</option>
                        <option value="rating_high_to_low">Rating (Lowest)</option>
                        <option value="popular">Popular</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default ProductSort
