import React from "react"
import imageUtil from "../globalUtility/imageHelper"
import BreadCrumb from "../GlobalComponents/BreadCrumb"
import {Link} from "react-router-dom"

class OrderComplete extends React.Component{
    render() {
        const images = imageUtil.images
        return (
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Pages" subHeading="Order Complete"/>
                {/* breadcrumb section end */}
                {/* order complete section strats */}
                <section className="padding-80px-tb order-complete">
                    <div className="container">
                        {/* thank you heading starts */}
                        <div className="row">
                            <div className="col-lg-12 col-md-12 co-order">
                                <div className="text-center padding-30px bg-gray border-radius-5 sm-padding-15px">
                                    <i className="fas fa-check-circle fa-2x text-green title-small lg-text-extra-large md-title-mdall sm-text-extra-large" />
                                    <h3 className="font-weight-600 title-small lg-text-extra-large md-title-mdall sm-text-extra-large">Thank You For Your Order</h3>
                                    <p className="margin-bottom-5px sm-text-large">Payment Is Successfully Processsed And Your Order Is On The Way</p>
                                    <h5 className="font-weight-600 text-extra-large lg-text-large2 md-text-large sm-text-large">
                                        Your order id is : {Math.floor((Math.random() * 100000) + 1)}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        {/* thank you heading ends */}
                        <div className="row margin-top-40px lg-margin-top-30px md-margin-top-25px sm-margin-top-20px sm-title-large">
                            <div className="col-md-6 col-12 or-co">
                                {/* image starts */}
                                <div className="text-center">
                                    <img src={images.completed} className="img-fluid border-radius-5" alt="" />
                                </div>
                                {/* image ends */}
                            </div>
                            {/* order placed starts */}
                            <div className="col-md-6 col-12 or-co d-flex align-items-center justify-content-center">
                                <div className="sm-margin-top-20px text-center">
                                    <h6 className="font-weight-600 text-extra-large lg-text-large2 md-text-large sm-text-large">Your Order Has Been Placed!</h6>
                                    <p className="sm-text-large margin-bottom-10px">You will shortly receive order confirmation through email!</p>
                                    {/* print reciept starts */}
                                    <div className="print-rec display-inline-block">
                                        <i className="fas fa-print" />
                                        <span className="font-weight-600 hoverstyle1 text-decoration-underline sm-text-large">Print Receipt</span>
                                    </div>
                                    {/* print reciept ends */}
                                    {/* track shipment starts */}
                                    <Link to="/track-order" className="btn-style1 display-inline-block">
                                        <i className="fas fa-paper-plane" />Track Shipment
                                    </Link>
                                    {/* track shipment ends */}
                                    <div className="col-md-12">
                                        <div className="delivery-sec">
                                            <h6 className="font-weight-600 text-extra-large lg-text-large2 md-text-large sm-text-large">Expected Date of Delivery</h6>
                                            <p className="text-gray no-margin-bottom sm-text-large">December 22, 2019</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* order placed ends */}
                        </div>
                    </div>
                </section>
                {/* order complete section ends */}

            </>
        );
    }
}

export default OrderComplete;
