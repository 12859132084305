import React from "react";
import {withRouter} from "react-router-dom"
import BreadCrumb from "../GlobalComponents/BreadCrumb"
import {Link} from "react-router-dom"
import {toast} from "react-toastify"
import titleUpperCase from "../globalUtility/commonHelper";
import {handelItemToCart} from "../actions/cartAction";
import {connect} from "react-redux";

class Checkout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            addressLine1: "",
            addressLine: "",
            pincode: "",
            email: "",
            phone: "",
            error: {},
            cart: [],
            paymentOption: "stripe",
            showPaymetOption: false
        };
    }

    componentWillMount() {
        const cart = JSON.parse(localStorage.getItem('cart') ) || []
        this.paypalPayment()
        this.setState({
            cart
        }, () => this.totalCount())
    }

    totalCount = () => {
        const {cart} = this.state
        let total = 0
        cart && cart.length && cart.forEach(a => {
            total += (a.salePrice * a.count)
        })
        this.setState({ total })
    }

    paypalPayment = () => {
        window.paypal.Buttons({
                style: {
                    layout: 'horizontal',
                    color: 'blue',
                    tagline: false,
                    height: 39
                },
                // Set up the transaction
                createOrder: function(data, actions) {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: "0.01"
                                }
                            }
                        ]
                    });
                },

                // Finalize the transaction
                onApprove: function(data, actions) {
                    return actions.order.capture().then(function(details) {
                        // Show a success message to the buyer
                        this.props.history.push({
                            pathname: "/order-complete",
                        })
                    });
                }
            })
            .render("#paypal-button-container");
    }

    errorValidation = (name, value) => {
        switch (name) {
            case "firstName":
                if (!value) {
                    return "First Name is require"
                } else {
                    return null
                }
            case "lastName":
                if (!value) {
                    return "Last Name is require"
                } else {
                    return null
                }
            case "phone":
                if (!value) {
                    return "PhoneNumber  is require"
                } else if (!value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
                    return "Enter a valid Phone "
                } else {
                    return null
                }
            case "addressLine":
                if (!value) {
                    return "Address line 1 is require"
                } else {
                    return null
                }
            case "email":
                if (!value) {
                    return "Email is require"
                } else {
                    return null
                }
            case "pincode":
                if (!value) {
                    return "Pin Code is required"
                } else if (value.length < 4) {
                    return "Pin Code is inValid"
                } else {
                    return null
                }
            default:
                return  null
        }
    };

    validation = () => {
        const{firstName, lastName, addressLine, pincode, email, phone} = this.state

        const allError = {}
        const data = {
            firstName: firstName,
            lastName: lastName,
            addressLine: addressLine,
            pincode: pincode,
            email: email,
            phone: phone
        }

        Object.keys(data).forEach(key => {
            const error = this.errorValidation(key, data[key]);
            if (error) {
                allError[key] = error;
            }
        });

        if (Object.keys(allError).length > 0) {
            this.setState({error: allError})
            return
        }

        if(!Object.keys(allError).length){
            this.setState({
                showPaymetOption: true
            })
        }

    }

    removeValidation = () => {
        this.setState({error:{}})
    }

    handleChange = (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: value,
            error: {
                ...this.state.error,
                [name]:  this.errorValidation(name, value)
            }
        },() => {
            if (this.state.paymentOption === "paypal") {
                this.paypalPayment()
            }
        })
    }

    handleSubmit = () => {
        const { paymentOption} = this.state;

        if(paymentOption === "stripe"){
            let handler = window.StripeCheckout.configure({
                key: 'pk_test_ys2Ish6MY9McLS4fFJwat1Rw007FFKEKxh',
                locale: 'auto',
                token: (token) => {
                    // You can access the token ID with `token.id`.
                    // Get the token ID to your server-side code for use.
                    if(token.id){
                        this.props.history.push({
                            pathname: "/order-complete"
                        })
                    }
                }
            });

            handler.open({
                name: 'Macro store',
                label: "Place Order",
                description: 'Online Fashion Store'
            });
        }else if(paymentOption === 'cashOnDelivery'){
            this.props.history.push({
                pathname: "/order-complete",
            })
        }
    }

    countChange = (type, item, index) => {
        const {cart} = this.state
        if (type === "minus") {
            if (item.count === 1) {
                toast.warn(`Minimum 1 items required`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            } else {
                cart[index].count = cart[index].count - 1
            }
        } else {
            if (item.count === item.stock) {
                toast.warn(`Product is now out of stock`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            } else {
                cart[index].count = cart[index].count + 1
            }
        }
        localStorage.setItem("cart" , JSON.stringify(cart))
        this.props.handelCart(cart)
        this.setState({ cart }, () => this.totalCount())
    }

    render() {
        const{firstName, lastName, addressLine1, addressLine, pincode, email, phone, error, paymentOption, cart, total, showPaymetOption} = this.state
        return(
            <>
                {/* breadcrumb section start */}
                <BreadCrumb heading="Pages" subHeading="Checkout"/>
                {/* breadcrumb section end */}
                {/* checkout section starts */}
                <section className="padding-80px-tb check-out-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {/* review cart starts */}
                                <details open className="cart-check">
                                    <summary className="w-100 text-left text-black border-radius-5 border-gray2 padding-10px outline-none text-large sm-text-extra-large">Review Cart</summary>
                                    {/* heading starts */}
                                    {/* <div className="row margin-top-15px margin-left-10px margin-right-10px sm-display-none">
                                        <div className="col-xl-6 col-lg-6 col-md-5 border-gray2 bg-white">
                                            <div className="review-cart-title text-center">
                                                <h6 className="text-medium font-weight-600">Item Name</h6>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 border-gray2 border-left-none border-right-none bg-white">
                                            <div className="review-cart-title text-center">
                                                <h6 className="font-weight-600 text-medium">Unit Price</h6>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-3 border-gray2 border-right-none bg-white">
                                            <div className="review-cart-title text-center">
                                                <h6 className="font-weight-600 text-medium">Quantity</h6>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 border-gray2 bg-white">
                                            <div className="review-cart-title text-center">
                                                <h6 className="font-weight-600 text-medium">Sub Total</h6>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* heading ends */}
                                    {/* product details starts */}
                                    <div className="table-responsive check-page">
                                        {/* table starts */}
                                        {
                                            cart && cart.length ?
                                                <table className="table table-bordered no-margin-bottom">
                                                    <thead>
                                                    {/* heading starts */}
                                                    <tr className="text-center">
                                                        <th>Item Name</th>
                                                        <th>Unit Price</th>
                                                        <th>Quantity</th>
                                                        <th>Sub Total</th>
                                                    </tr>
                                                    {/* heading ends */}
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        cart && cart.map((item, i) => {
                                                            return (
                                                                <tr className="text-center" key={i}>
                                                                    <td>
                                                                        {/* buy product image starts */}
                                                                        <Link to={`/product/${item.id}`}><img src={item.pictures[0]} width={100} height={100} alt=""/></Link>
                                                                        {/* buy product image ends */}
                                                                        {/* buy product description starts */}
                                                                        <div className="added-product">
                                                                            <Link to={`/product/${item.id}`} className="text-black font-weight-600 sm-text-large">
                                                                                {titleUpperCase(item.productTitle)}
                                                                            </Link>
                                                                        </div>
                                                                        {/* buy product description ends */}
                                                                    </td>
                                                                    {/* product price starts */}
                                                                    <td className="vertical-align-middle sm-text-large">
                                                                        ${item.salePrice && item.salePrice.toFixed(2)}
                                                                    </td>
                                                                    {/* product price ends */}
                                                                    {/* qty starts */}
                                                                    <td className="vertical-align-middle">
                                                                        <div className="qty-input qty-input-style1 d-inline-block">
                                                                            <i className="less title-small" onClick={() => this.countChange("minus", item, i)}>-</i>
                                                                            <input type="text" value={(item.count)} onChange={()=>{}} />
                                                                            <i className="more title-small" onClick={() => this.countChange("add", item, i)}>+</i>
                                                                        </div>
                                                                    </td>
                                                                    {/* qty ends */}
                                                                    {/* product sub total starts */}
                                                                    <td className="vertical-align-middle font-weight-600 sm-text-large">
                                                                        ${(item.salePrice * (item.count || 1)).toFixed(2)}
                                                                    </td>
                                                                    {/* product sub total ends */}
                                                                    {/* action starts */}

                                                                    {/* action ends */}
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                                : <p className="text-center">No Product found in your cart</p>
                                        }

                                        {/* table ends */}
                                    </div>
                                    {/* product details ends */}
                                    <div className="detail-checkout">
                                        <div className="coupan-code text-green sm-text-large">Coupon successfully applied*</div>
                                        <div className="total-amount">
                                            <h6 className="font-weight-600 text-large sm-text-extra-large total-amt">
                                                <span>Total:</span>
                                                <span className="text-black border-bottom-khaki">{`$${total && total.toFixed(2)}`}</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="clearfix" />
                                </details>
                                {/* review cart ends */}
                                {/* billing information starts */}

                                <details className="cart-check">
                                    <summary className="w-100 text-left text-black border-radius-5 border-gray2 padding-10px outline-none text-large sm-text-extra-large">
                                        Billing Information
                                    </summary>
                                    {/* form starts */}
                                    <div className="form-action">
                                        {/* customer information starts */}
                                        <div className="row margin-left-0px margin-right-0px">
                                            <div className="col-md-6 bill-form">
                                                <label className="sm-text-large">
                                                    First Name<span className="text-danger">*</span>
                                                </label>
                                                <input type="text"
                                                       name="firstName"
                                                       value={firstName}
                                                       onChange={this.handleChange}
                                                       placeholder="First name"
                                                       className="text-extra-medium sm-text-medium form-control border-radius-5" />
                                                <span className="text-danger">{error.firstName}</span>
                                            </div>
                                            <div className="col-md-6 bill-form">
                                                <label className="sm-text-large">
                                                    Last Name<span className="text-danger">*</span>
                                                </label>
                                                <input type="text"
                                                       name="lastName"
                                                       value={lastName}
                                                       onChange={this.handleChange}
                                                       placeholder="Last name"
                                                       className="text-extra-medium sm-text-medium form-control border-radius-5" />
                                                <span className="text-danger">{error.lastName}</span>
                                            </div>
                                            <div className="col-md-6 bill-form">
                                                <label className="sm-text-large">
                                                    Address line 1<span className="text-danger">*</span>
                                                </label>
                                                <input type="text"
                                                       name="addressLine"
                                                       value={addressLine}
                                                       onChange={this.handleChange}
                                                       placeholder="Address line 1"
                                                       className="text-extra-medium sm-text-medium form-control border-radius-5" />
                                                <span className="text-danger">{error.addressLine}</span>
                                            </div>
                                            <div className="col-md-6 bill-form">
                                                <label className="sm-text-large">Address line 2</label>
                                                <input type="text"
                                                       name="addressLine1"
                                                       value={addressLine1}
                                                       onChange={this.handleChange}
                                                       placeholder="Address line 2"
                                                       className="text-extra-medium sm-text-medium form-control border-radius-5" />
                                            </div>
                                            <div className="col-md-4 bill-form">
                                                <label className="sm-text-large">
                                                    Pin Code<span className="text-danger">*</span>
                                                </label>
                                                <input type="text"
                                                       name="pincode"
                                                       value={pincode}
                                                       onChange={this.handleChange}
                                                       placeholder="Pincode"
                                                       className="text-extra-medium sm-text-medium form-control border-radius-5" />
                                                <span className="text-danger">{error.pincode}</span>
                                            </div>
                                            <div className="col-md-4 bill-form">
                                                <label className="sm-text-large">
                                                    E-mail<span className="text-danger">*</span>
                                                </label>
                                                <input type="text"
                                                       name="email"
                                                       value={email}
                                                       onChange={this.handleChange}
                                                       placeholder="Email"
                                                       className="text-extra-medium sm-text-medium form-control border-radius-5" />
                                                <span className="text-danger">{error.email}</span>
                                            </div>
                                            <div className="col-md-4 bill-form">
                                                <label className="sm-text-large">
                                                    Phone<span className="text-danger">*</span>
                                                </label>
                                                <input type="tel"
                                                       name="phone"
                                                       value={phone}
                                                       onChange={this.handleChange}
                                                       placeholder="Phone no"
                                                       className="text-extra-medium sm-text-medium form-control border-radius-5" />
                                                <span className="text-danger">{error.phone}</span>
                                            </div>
                                            <div className="col-md-12 bill-form">
                                                <div className="bill-action-button">
                                                    <button className="btn btn-style1" onClick={this.validation}>Submit</button>
                                                    <button className="btn btn-style1" onClick={() => this.removeValidation()}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* customer information ends */}
                                    </div>
                                    {/* form ends */}
                                </details>
                                {/* billing information ends */}
                                {/* payment method starts */}
                                {
                                    showPaymetOption &&
                                    <details>
                                        <summary className="w-100 text-left text-black border-gray2 border-radius-5 padding-10px outline-none text-large sm-text-extra-large">Payment Method</summary>
                                        <div className="margin-left-10px margin-right-10px margin-top-15px">
                                            <div className="payment-mathod">
                                                {/* credit/debit option starts */}
                                                <div className="margin-bottom-10px">
                                                    <input type="radio"
                                                           value="stripe"
                                                           name="paymentOption"
                                                           checked={paymentOption === 'stripe'}
                                                           onChange={this.handleChange}
                                                           className="position-relative top-2" />
                                                    <span className="padding-left-5px font-weight-600 sm-text-large">Credit/Debit Card</span>
                                                    <div className="payment-th-card d-inline-block padding-left-5px vertical-align-middle title-large sm-title-middle2">
                                                        <i className="fab fa-cc-visa" />
                                                        <i className="fab fa-cc-mastercard" />
                                                    </div>
                                                </div>
                                                {/* credit/debit option ends */}
                                                {/* paypal option starts */}
                                                <div className="margin-bottom-10px">
                                                    <input type="radio"
                                                           value="paypal"
                                                           name="paymentOption"
                                                           checked={paymentOption === 'paypal'}
                                                           onChange={this.handleChange}
                                                           className="position-relative top-2"/>
                                                    <span className="padding-left-5px font-weight-600 sm-text-large">Paypal</span>
                                                    <div className="d-inline-block padding-left-5px vertical-align-middle title-large sm-title-middle2">
                                                        <i className="fab fa-cc-paypal" />
                                                    </div>
                                                </div>
                                                {/* paypal option ends */}
                                                {/* cash on devivery option starts */}
                                                <div className="margin-bottom-15px sm-margin-bottom-10px">
                                                    <input type="radio"
                                                           value="cashOnDelivery"
                                                           name="paymentOption"
                                                           checked={paymentOption === 'cashOnDelivery'}
                                                           onChange={this.handleChange}
                                                           className="position-relative top-2"/>
                                                    <span className="padding-left-5px font-weight-600 sm-text-large">Cash On Delivery</span>
                                                    <div className="d-inline-block padding-left-5px vertical-align-middle title-large sm-title-middle2">
                                                        <i className="fas fa-hand-holding-usd" />
                                                    </div>
                                                </div>
                                                <div className="margin-bottom-15px">
                                                    <input type="checkbox" defaultChecked="checked" className="position-relative top-2" />
                                                    <span className="font-weight-600 padding-left-5px sm-text-large">Have A Billing Address Same As Shipping Address</span>
                                                </div>
                                                {
                                                    cart.length > 0 &&
                                                    <div className="order-button">
                                                        {
                                                            paymentOption === "paypal" ?
                                                                <div id="paypal-button-container"/> :
                                                                <button className="btn btn-style1" onClick={this.handleSubmit}>Place Order</button>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </details>
                                }
                                {/* payment method ends */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* checkout section ends */}
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    handelCart: (item) => dispatch(handelItemToCart(item))
})

export default withRouter(connect(
    null,
    mapDispatchToProps
)(Checkout));
