import imageUtil from "../../imageHelper";
const images = imageUtil.images

const BlogData = {
    blogList: [
        {
            id: 1,
            title: "learning Languages",
            image: images.blog1,
            date: 9,
            month: "Feb",
            like: 7,
            comment: 8,
            author: "Admin",
            category: "Media"
        },
        {
            id: 2,
            title: "party In Club",
            image: images.blog2,
            date: 9,
            month: "Feb",
            like: 56,
            comment: 68,
            author: "Admin",
            category: "Sports"
        },
        {
            id: 3,
            title: "support Sports",
            image: images.blog3,
            date: 9,
            month: "Feb",
            like: 234,
            comment: 8,
            author: "Admin",
            category: "Media"
        },
        {
            id: 4,
            title: "fitness In Gym",
            image: images.blog4,
            date: 9,
            month: "Feb",
            like: 56,
            comment: 2,
            author: "Admin",
            category: "Bollywood"
        },
        {
            id: 5,
            title: "morning Walk",
            image: images.blog5,
            date: 9,
            month: "Feb",
            like: 546,
            comment: 72,
            author: "Admin",
            category: "Political"
        },
        {
            id: 6,
            title: "boxing Fight",
            image: images.blog6,
            date: 9,
            month: "Feb",
            like: 34,
            comment: 8,
            author: "Admin",
            category: "Sports"
        },
        {
            id: 7,
            title: "Meeting",
            image: images.blog5,
            date: 9,
            month: "Feb",
            like: 23,
            comment: 2,
            author: "Admin",
            category: "Media"
        },
        {
            id: 8,
            title: "Learn Painting",
            image: images.blog6,
            date: 9,
            month: "Feb",
            like: 3,
            comment: 46,
            author: "Admin",
            category: "Political"
        },
        {
            id: 9,
            title: "Exam Test",
            image: images.blog1,
            date: 9,
            month: "Feb",
            like: 64,
            comment: 12,
            author: "Admin",
            category: "Political"
        },
        {
            id: 10,
            title: "Admin",
            image: images.blog2,
            date: 9,
            month: "Feb",
            like: 23,
            comment: 85,
            author: "Admin",
            category: "Bollywood"
        },
        {
            id: 11,
            title: "Design Website",
            image: images.blog3,
            date: 9,
            month: "Feb",
            like: 34,
            comment: 345,
            author: "Admin",
            category: "Sports"
        },
        {
            id: 12,
            title: "Work Out",
            image: images.blog4,
            date: 9,
            month: "Feb",
            like: 34,
            comment: 45,
            author: "Admin",
            category: "Media"
        },
        {
            id: 13,
            title: "Bar",
            image: images.blog2,
            date: 9,
            month: "Feb",
            like: 45,
            comment: 45,
            author: "Admin",
            category: "Bollywood"
        },
        {
            id: 14,
            title: "Meet The people",
            image: images.blog5,
            date: 9,
            month: "Feb",
            like: 34,
            comment: 23,
            author: "Admin",
            category: "Political"
        },
        {
            id: 15,
            title: "Develop Application",
            image: images.blog3,
            date: 9,
            month: "Feb",
            like: 34,
            comment: 45,
            author: "Admin",
            category: "Sports",
        },
        {
            id: 16,
            title: "Office Work",
            image: images.blog1,
            date: 9,
            month: "Feb",
            like: 5,
            comment: 98,
            author: "Admin",
            category: "Political"
        },
        {
            id: 17,
            title: "Yoga",
            image: images.blog4,
            date: 9,
            month: "Feb",
            like: 34,
            comment: 77,
            author: "Admin",
            category: "Media"
        },
        {
            id: 18,
            title: "Drawing Ideas",
            image: images.blog6,
            date: 9,
            month: "Feb",
            like: 24,
            comment: 37,
            author: "Admin",
            category: "Sports"
        },
        {
            id: 19,
            title: "What Is Art? An Insight About Profoundness Of Art!",
            image: images.blogDetailMain,
            date: 19,
            month: "Jan",
            like: 44,
            comment: 68,
            author: "Admin",
            category: "Political"
        }
    ],
    relatedBlogs: [
        {
            id: 19,
            image: images.relatedBlog1,
            title: "what Is The Purpose Of Art?",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            id: 20,
            image: images.relatedBlog2,
            title: "performing Dance Art On International Stage!",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            id: 21,
            image: images.relatedBlog3,
            title: "art Is Rampant,Beauty. It's Everywhere",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        }
    ]
}

export default BlogData;
