import React from "react";
import {connect} from "react-redux";
import { Link, withRouter } from "react-router-dom"
import imageUtil from "../globalUtility/imageHelper";
import titleUpperCase from "../globalUtility/commonHelper";

class Header extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            cartList: [],
            wishList: [],
            searchText: ""
        }
    }

    componentDidMount() {
        const cart = JSON.parse(localStorage.getItem('cart') ) || []
        const wishList = JSON.parse(localStorage.getItem('wishList') ) || []
        this.setState({
            cartList: cart,
            wishList: wishList
        }, () => this.totalCount())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {cartList} = this.state
        const { countAddToCart, totalWishList } = this.props
        let cart = localStorage.getItem("cart")
        const wishList = JSON.parse(localStorage.getItem('wishList') ) || []
        let cartData = JSON.parse(cart) || []
        if (((cartData && cartData.length) !== (cartList && cartList.length)) || (prevProps.countAddToCart !== countAddToCart)) {
            this.setState({
                cartList: cartData || [],
            },()=>{
                this.totalCount()
            })
        }
        if(prevProps.totalWishList !== totalWishList){
            this.setState({
                totalWishListItems: wishList.length || 0,
            })
        }
    }

    totalCount = () => {
        const {cartList} = this.state
        let total = 0
        cartList && cartList.length && cartList.forEach(a => {
            total += a.salePrice * a.count
        })
        this.setState({ total })
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.searchText()
        }
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    searchText = () =>{
        this.props.history.push({
            pathname: `/products/grids-page`,
            state: {
                searchText: this.state.searchText || ""
            }
        })
    }

    render() {
        const {cartList, total, searchText, totalWishListItems} = this.state
        const images = imageUtil.images
        return(
            <header id="navbar" className="header-style1 position-absolute top-0 w-100 z-index-6 border-bottom-medium-dark">
                <div className="navbar p-0 navbar-expand-lg navbar-dark">
                    {/* logo starts */}
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-6 text-left">
                        <a href="/" className="navbar-brand mainlogo no-margin-right padding-10tb sm-padding-5px">
                            <img src={images.macroLogo} alt="logo" />
                        </a>
                    </div>
                    {/* logo ends */}
                    <div className="col-xl-5 col-lg-4 col-md-8 col-sm-4 col-4 display-inline-block text-right d-none md-display-block sm-no-padding-right">
                        {/* search starts */}
                        <div className="search display-inline-block padding-right-10px sm-display-none">
                            <input type="text"
                                className="searchinline"
                                name="searchText"
                                value={searchText}
                                onKeyDown={this.handleKeyDown}
                                onChange={(e) => this.onTextChange(e)}
                                placeholder="I Am Looking For.." />
                            <span onClick={this.searchText}>
                                <i className="fas fa-search text-black position-relative right-25" />
                            </span>
                        </div>
                        {/* search ends */}
                        {/* wishlist starts */}
                        <div className="w-list display-inline-block">
                            <Link to="/wishlist" className="wishlist text-transform-capitalize">
                                <i className="fas fa-heart text-sky-blue margin-right-5px text-medium2" />
                                <span className="font-weight-600">
                                    {totalWishListItems}<span className="ws-title">wishlist</span>
                                </span>
                            </Link>
                        </div>
                        {/* wishlist ends */}
                        <div className="sm-text-center display-inline-block sm-no-padding-right padding-xs-right show-cart">
                            <Link className="text-black new padding-top-20 padding-bottom-20px" to="#" id="cart">
                                <i className="fas fa-shopping-bag text-sky-blue position-relative margin-right-5px text-medium2" />
                                <span className="bagtext text-transform-capitalize font-weight-600">
                                    {(cartList && cartList.length) || 0}<span className="ca-item">items</span>
                                </span>
                            </Link>
                            {/* cart container starts */}
                            <div className="cartcontainer animated fadeIn cartcontainer-style1 text-left position-absolute">
                                {/* header shopping cart starts */}
                                <div className="shopping-cart float-right shadow bg-white position-relative border-radius-5 paddingtwenty">
                                    <div className="shopping-cart-header border-bottom-medium-dark padding-bottom-15px">
                                        <i className="fas fa-shopping-bag cart-icon text-black title-medium line-height-0 margin-right-7px float-left" />
                                        <span className="badge bg-sky-blue-light border-radius-5 text-white">
                                            {(cartList && cartList.length) || 0}
                                        </span>
                                        <div className="shopping-cart-total float-right font-weight-600">
                                            <span className="lighter-text">Total:</span>
                                            <span className="main-color-text text text-red">
                                                {`$${total && total.toFixed(2)}`}
                                            </span>
                                        </div>
                                    </div>
                                    {/* items lists starts */}
                                    <ul className="shopping-cart-items padding-top-20px padding-left-0px">
                                        {/* cart item start */}
                                        {
                                            (cartList && cartList.length) ? cartList.map((d, i) =>
                                                <li key={i} className="clearfix list-style-none border-bottom-medium-dark padding-bottom-20px">
                                                    <div className="cart-img">
                                                        <img src={d.pictures[0]} width={70} height={75} alt="item1" className="float-left margin-right-12px" />
                                                    </div>
                                                    <Link to={`/product/${d.id}`} className="item-name text-black font-weight-600 hoverstyle1 sm-text-medium">
                                                        {titleUpperCase(d.productTitle)}
                                                    </Link>
                                                    <div className="item-price font-weight-600 margin-right-8px sm-text-large">{`${d.salePrice && d.salePrice.toFixed(2)}`}
                                                        <span className="font-weight-500 text-brown text-small text-decoration-line-through margin-left-5px">{`${d.salePrice && d.salePrice.toFixed(2)}`}</span>
                                                    </div>
                                                    <div className="item-quantity text-black text-uppercase font-weight-600 text-small sm-text-mdall">Quantity:
                                                        <span className="badge margin-left-5px padding-5px bg-sky-blue-light border-radius-5 text-white">{d.count}</span>
                                                    </div>
                                                </li>
                                            ) : <>Cart is currently empty</>
                                        }
                                        {/* cart item end */}
                                    </ul>
                                    {/* items lists ends */}
                                    {/* checkout & cart page button starts */}
                                    <div className="text-center check-button">
                                        <Link to="/checkout" className="button small-btn text-center text-decoration-none hoverstyle1">Checkout</Link>
                                        <Link to="/cart-page" className="button small-btn text-center text-decoration-none hoverstyle1">View Cart</Link>
                                    </div>
                                    {/* checkout & cart page button ends */}
                                </div>
                                {/* header shopping cart ends */}
                            </div>
                            {/* cart container ends */}
                        </div>
                    </div>
                    {/* navbar starts */}
                    <div className="col-xl-5 col-lg-6 col-md-1 col-sm-2 col-2">
                        <div className="text-center">
                            <button className="navbar-toggler outline-none cursor-pointer border-0 md-position-relative md-top-2 sm-left-5px" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                                <span className="line" />
                                <span className="line" />
                                <span className="line" />
                            </button>
                            <div className="collapse navbar-collapse cursor-pointer toggle md-position-fixed md-left-0 md-container-fluid letter-spacing-half" id="collapsibleNavbar">
                                <ul className="navbar-nav md-bgcolor-black mx-auto">
                                    {/* navbar home starts */}
                                    <li className="dropdown md-container-fluid md-border-bottom-white padding-17px-tb md-no-padding-tb padding-right-25px lg-padding-right-20px md-textalign-left lg-text-medium">
                                        <Link to="/" className="dropdown-toggle md-padding-left-20px sm-text-large">Home</Link>
                                        <ul className="animated fadeIn dropdown-menu text-extra-medium" aria-labelledby="navbarDropdownMenuLink">
                                            <li><a href="/" className="dropdown-item">Home</a></li>
                                            <li><a href="/home1" className="dropdown-item">Home 1</a></li>
                                        </ul>
                                    </li>
                                    {/* navbar home ends */}
                                    {/* products starts */}
                                    <li className="md-container-fluid position-relative md-border-bottom-white padding-right-25px lg-padding-right-20px padding-17px-tb md-no-padding-tb md-border-bottom-medium-dark md-textalign-left lg-text-medium">
                                        <Link className="dropdown-toggle md-padding-left-20px sm-text-large" to="#">Products</Link>
                                        <ul className="animated fadeIn dropdown-menu text-extra-medium blog-dropdown" aria-labelledby="navbarDropdownMenuLink">
                                            <li><Link to="/products/product-comparision" className="dropdown-item">Product Comparision</Link></li>
                                            <li><Link to="/product/2" className="dropdown-item">Product Details</Link></li>
                                            <li className="dropdown-submenu">
                                                <Link className="dropdown-item ml-lg-0 dropdown-toggle" to="#">Grids Pages</Link>
                                                <ul className=" dropdown-menu dropdown-submenu-sub text-extra-medium top-0 left-100p">
                                                    <li><Link to="/products/grids-page" className="dropdown-item">Grids Page</Link></li>
                                                    <li><Link to="/products/grids-page-full" className="dropdown-item">Grids Page Full</Link></li>
                                                    <li><Link to="/products/grids-left-sidebar" className="dropdown-item">Grids Left Sidebar</Link></li>
                                                    <li><Link to="/products/grids-left-sidebar-full" className="dropdown-item">Grids Left Sidebar Full</Link></li>
                                                    <li><Link to="/products/grids-right-sidebar" className="dropdown-item">Grids Right Sidebar</Link></li>
                                                    <li><Link to="/products/grids-right-sidebar-full" className="dropdown-item border-bottom-none">Grids Right Sidebar Full</Link></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown-submenu">
                                                <Link className="dropdown-item ml-lg-0 dropdown-toggle border-bottom-none" to="#">List Pages</Link>
                                                <ul className="animated fadeIn dropdown-menu dropdown-submenu-sub text-extra-medium top-0 left-100p">
                                                    <li><Link to="/products/list-page" className="dropdown-item">List Page</Link></li>
                                                    <li><Link to="/products/list-page-full" className="dropdown-item">List Page Full</Link></li>
                                                    <li><Link to="/products/list-page-left-sidebar" className="dropdown-item">List Page Left Sidebar</Link></li>
                                                    <li><Link to="/products/list-page-left-sidebar-full" className="dropdown-item">List Page Left Sidebar Full</Link></li>
                                                    <li><Link to="/products/list-page-right-sidebar" className="dropdown-item">List Page Right Sidebar</Link></li>
                                                    <li><Link to="/products/list-page-right-sidebar-full" className="dropdown-item border-bottom-none">List Page Right Sidebar Full</Link></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* products ends */}
                                    {/* categories starts */}
                                    <li className="dropdown md-container-fluid md-border-bottom-white padding-17px-tb md-no-padding-tb padding-right-25px lg-padding-right-20px md-textalign-left lg-text-medium">
                                        <Link to="#" className="dropdown-toggle md-padding-left-20px sm-text-large">Categories</Link>
                                        <ul className="animated fadeIn dropdown-menu text-extra-medium" aria-labelledby="navbarDropdownMenuLink">
                                            <li><Link to="/category/clothes" className="dropdown-item">Clothes</Link></li>
                                            <li><Link to="/category/footwear" className="dropdown-item">Footwear</Link></li>
                                            <li><Link to="/category/kitchen" className="dropdown-item">Kitchen</Link></li>
                                            <li><Link to="/category/accessories" className="dropdown-item">Mobile Accessories</Link></li>
                                            <li><Link to="/category/furniture" className="dropdown-item border-bottom-none">Furniture</Link></li>
                                        </ul>
                                    </li>
                                    {/* categories ends */}
                                    {/* pages starts */}
                                    <li className="dropdown padding-right-25px lg-padding-right-20px md-container-fluid md-border-bottom-white padding-17px-tb md-no-padding-tb md-textalign-left lg-text-medium">
                                        <Link className="dropdown-toggle md-padding-left-20px sm-text-large" to="#" id="navbarDropdownMenuLink">Pages</Link>
                                        <ul className="animated fadeIn dropdown-menu text-extra-medium" aria-labelledby="navbarDropdownMenuLink">
                                            <li><Link to="/cart-page" className="dropdown-item" >Cart Page</Link></li>
                                            <li><Link to="/checkout" className="dropdown-item">Checkout</Link></li>
                                            <li><Link to="/forget-password" className="dropdown-item">Forget Password</Link></li>
                                            <li><Link to="/order-complete" className="dropdown-item">Order Complete</Link></li>
                                            <li><Link to="/track-order" className="dropdown-item">Track Order</Link></li>
                                            <li><Link to="/sign-in" className="dropdown-item">Sign In</Link></li>
                                            <li><Link to="/sign-out" className="dropdown-item">Sign Out</Link></li>
                                            <li><Link to="/sign-up" className="dropdown-item">Sign Up</Link></li>
                                            <li><Link to="/page-not-found" className="dropdown-item">Page Not Found</Link></li>
                                            <li><a href="/coming-soon" className="dropdown-item border-bottom-none">Coming Soon</a></li>
                                        </ul>
                                    </li>
                                    {/* pages ends */}
                                    {/* blogs starts */}
                                    <li className="md-container-fluid md-border-bottom-white padding-17px-tb md-no-padding-tb padding-right-25px lg-padding-right-20px position-relative md-textalign-left lg-text-medium">
                                        <Link className=" md-padding-left-20px dropdown-toggle sm-text-large" to="#">Blogs</Link>
                                        <ul className="animated fadeIn dropdown-menu text-extra-medium blog-dropdown" aria-labelledby="navbarDropdownMenuLink">
                                            <li className="dropdown-submenu">
                                                <Link className="dropdown-item dropdown-toggle" to="#">Blog Details</Link>
                                                <ul className="animated fadeIn dropdown-menu dropdown-submenu-sub text-extra-medium top-0 left-100p">
                                                    <li><Link to="/blogs/blog-details" className="dropdown-item">Blog Details</Link></li>
                                                    <li><Link to="/blogs/blog-details-full" className="dropdown-item">Blog Details Full</Link></li>
                                                    <li><Link to="/blogs/blogdetails-left-sidebar" className="dropdown-item">Blogdetails Left Sidebar</Link></li>
                                                    <li><Link to="/blogs/blogdetails-left-sidebar-full" className="dropdown-item">Blogdetails Left Sidebar Full</Link></li>
                                                    <li><Link to="/blogs/blogdetails-right-sidebar" className="dropdown-item">Blogdetails Right Sidebar</Link></li>
                                                    <li><Link to="/blogs/blogdetails-right-sidebar-full" className="dropdown-item border-bottom-none">Blogdetails Right Sidebar Full</Link></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown-submenu">
                                                <Link className="dropdown-item dropdown-toggle border-bottom-none" to="#">Blogs</Link>
                                                <ul className="animated fadeIn dropdown-menu dropdown-submenu-sub text-extra-medium top-0 left-100p">
                                                    <li><Link to="/blogs/blogs" className="dropdown-item">Blogs</Link></li>
                                                    <li><Link to="/blogs/blogs-full" className="dropdown-item">Blogs Full</Link></li>
                                                    <li><Link to="/blogs/blogs-left-sidebar" className="dropdown-item">Blogs Left Sidebar</Link></li>
                                                    <li><Link to="/blogs/blogs-left-sidebar-full" className="dropdown-item">Blogs Left Sidebar Full</Link></li>
                                                    <li><Link to="/blogs/blogs-right-sidebar" className="dropdown-item">Blogs Right Sidebar</Link></li>
                                                    <li><Link to="/blogs/blogs-right-sidebar-full" className="dropdown-item border-bottom-none">Blogs Right Sidebar Full</Link></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* blogs ends */}
                                    {/* contact us starts */}
                                    <li className="md-container-fluid padding-17px-tb md-no-padding-tb padding-right-25px lg-padding-right-20px md-textalign-left lg-text-medium">
                                        <Link to="/contact-us" className="md-padding-left-20px sm-text-large sm-padding-bottom-5px">Contact Us</Link>
                                    </li>
                                    {/* contact us ends */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* navbar ends */}
                    <div className="col-xl-5 col-lg-4 col-md-4 display-inline-block text-right md-display-none">
                        {/* search starts */}
                        <div className="search display-inline-block padding-right-10px lg-position-relative lg-top10">
                            <input type="text" className="searchinline"
                                name="searchText"
                                value={searchText}
                                onKeyDown={this.handleKeyDown}
                                onChange={(e) => this.onTextChange(e)}
                                placeholder="I Am Looking For.." />
                            <span onClick={this.searchText}>
                                <i className="fas fa-search text-black position-relative right-25" />
                            </span>
                        </div>
                        {/* search ends */}
                        {/* wishlist starts */}
                        <div className="display-inline-block">
                            <Link to="/wishlist" className="wishlist text-transform-capitalize">
                                <i className="fas fa-heart text-sky-blue margin-right-5px text-medium2" />
                                <span className="font-weight-600">{totalWishListItems} wishlist</span>
                            </Link>
                        </div>
                        {/* wishlist ends */}
                        <div className="sm-text-center display-inline-block show-cart">
                            {/* items starts */}
                            <Link className="text-black new padding-top-20 padding-bottom-20px" to="#">
                                <i className="fas fa-shopping-bag text-sky-blue position-relative margin-right-5px text-medium2" />
                                <span className="bagtext text-transform-capitalize font-weight-600">
                                    {cartList.length || 0} items
                                </span>
                            </Link>
                            {/* items ends */}
                            {/* cart container starts */}
                            <div className="cartcontainer cartcontainer-style1 text-left position-absolute">
                                {/* shopping cart starts */}
                                <div className="shopping-cart float-right shadow bg-white position-relative border-radius-5 paddingtwenty">
                                    <div className="shopping-cart-header border-bottom-medium-dark padding-bottom-15px">
                                        <i className="fas fa-shopping-bag cart-icon text-black title-medium line-height-0 margin-right-7px float-left" />
                                        <span className="badge bg-sky-blue-light border-radius-5 text-white">
                                            {cartList.length || 0}
                                        </span>
                                        <div className="shopping-cart-total float-right font-weight-600">
                                            <span className="lighter-text">Total:</span>
                                            <span className="main-color-text text text-red">{`$${total}`}</span>
                                        </div>
                                    </div>
                                    {/* items lists starts */}
                                    <ul className="shopping-cart-items padding-top-20px padding-left-0px">
                                        {/* cart item1 starts */}
                                        {
                                            (cartList && cartList.length) ? cartList.map((d, i) =>
                                                <li className="clearfix list-style-none border-bottom-medium-dark padding-bottom-20px" key={i}>
                                                    <div className="cart-img">
                                                        <img src={d.pictures[0]} width={70} height={75} alt="item1" className="float-left margin-right-12px" />
                                                    </div>
                                                    <Link to={`/product/${d.id}`} className="item-name text-black font-weight-600 hoverstyle1 sm-text-medium">
                                                        {titleUpperCase(d.productTitle)}
                                                    </Link>
                                                    <div className="item-price font-weight-600 margin-right-8px sm-text-large">{`$ ${d.salePrice && d.salePrice.toFixed(2)}`}
                                                        <span className="font-weight-500 text-brown text-small text-decoration-line-through margin-left-5px">{` ${d.price && d.price.toFixed(2)}`}</span>
                                                    </div>
                                                    <div className="item-quantity text-black text-uppercase font-weight-600 text-small sm-text-mdall">Quantity:
                                                        <span className="badge margin-left-5px padding-5px bg-sky-blue-light border-radius-5 text-white">{d.count}</span>
                                                    </div>
                                                </li>
                                            ) : <>Cart is currently empty</>
                                        }
                                    </ul>
                                    {/* items lists ends */}
                                    {/* checkout & cart page button starts */}
                                    <div className="text-center check-button">
                                        <Link to="/checkout" className="button small-btn text-center text-decoration-none hoverstyle1">Checkout</Link>
                                        <Link to="/cart-page" className="button small-btn text-center text-decoration-none hoverstyle1">View Cart</Link>
                                    </div>
                                    {/* checkout & cart page button ends */}
                                </div>
                                {/* shopping cart ends */}
                            </div>
                            {/* cart container ends */}
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

const mapStateToProps = (state) => {
    return({
        wishListDataItems: state.wishReducer.wishList || [],
        totalWishList: state.wishReducer.totalWishList || [],
        cartListData: (state.cartReducer && state.cartReducer.cartList) || {},
        countAddToCart: (state.cartReducer && state.cartReducer.countAddToCart),
    })
}

export default withRouter(connect(
    mapStateToProps,
    null
)(Header))
