import React from "react"
import BreadCrumb from "../GlobalComponents/BreadCrumb"
import {Link} from "react-router-dom"

class PageNotFound extends React.Component {
    render() {
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Pages" subHeading="404"/>
                {/* breadcrumb section end */}
                {/* page not found section starts */}
                <section className="padding-80px-tb page-not-found">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 center-col">
                                <div className="not-found-bottom-inner text-center">
                                    <h1>404</h1>
                                    <h4>Error - not found</h4>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                    <Link to="/" className="back-button text-black"><i className="fas fa-angle-double-left" />Back To Homepage</Link>
                                    <div className="not-found-search-form">
                                        <form action="#">
                                            <input type="text" name="search" id="search" placeholder="Search again....." />
                                            <button type="submit" className="btn btn-style1 subscribe-btn">Search</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* page not found section ends */}

            </>
        )
    }

}

export default PageNotFound;