import {combineReducers} from 'redux';
import { routerReducer } from 'react-router-redux';

import cartReducer from './cartReducer';
import productListReducer from './productListReducer';
import productCompareReducer from './productCompareReducer';
import wishReducer from "./wishReducer";

export default combineReducers({
    routing: routerReducer,
    cartReducer,
    productListReducer,
    productCompareReducer,
    wishReducer,
});
