import React from "react";
import {productList} from "../../../globalUtility/jsonData/product/ProductDataList";
import ProductViewModal from "../../../globalUtility/ProductViewModal";
import BreadCrumb from "../../../GlobalComponents/BreadCrumb";
import ProductSort from "../Common/ProductSort";
import {Link} from "react-router-dom";
import Pagination from "../../../GlobalComponents/Pagination";
import Rating from "../Common/Rating";
import ProductSideBar from "../Common/ProductSideBar";
import NotFoundData from "../../../Pages/NotFoundData";
import titleUpperCase from "../../../globalUtility/commonHelper";
import {addItemToCart} from "../../../actions/cartAction";
import {connect} from "react-redux";
import {addItemToWish} from "../../../actions/wishListAction";

class GridsLeftSidebar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            productDetails: {},
            isModal: false,
            productDataList: [],
            productSortDataList: [],
            colorProductList: [],
            isPriceRangeActive: false,
            isGridPageFull: false
        }
    }

    componentWillMount() {
        let activePage = window.location.href.split("/")[4]
        this.setState({
            productDataList: productList,
            isGridPageFull: activePage === "grids-left-sidebar-full" || false
        })
    }

    handelModal = (item) =>{
        this.setState({
            isModal: !this.state.isModal,
            productPreview: item.productTitle ? item : {}
        })
    }

    handelActiveImg = (img, i, index) =>{
        const {productDataList} = this.state
        productDataList[i].activeImg = img
        productDataList[i].activeImgIndex = index
        this.setState({
            productDataList
        })
    }

    handelSorting = (data, type) =>{
        this.setState({
            productDataList: [],
            isPriceRangeActive: false,
            isCategory: ""
        },()=> {
            this.setState({
                sortType: type,
                productSortDataList: data,
            })
        })
    }

    onChangePage = (pageOfItems) => {
        this.setState({
            productDataList: pageOfItems
        });
    }

    handelPriceRangeComplete = (item) =>{
        const data = productList.filter(p => p.salePrice <= item)
        this.setState({
            rangeProductList: data || [],
            isPriceRangeActive: true,
            isCategory: ""
        })
    }

    handelGetColorProductData = (color) =>{
        const data = productList.filter(p => p.colors.includes(color))

        this.setState({
            colorProductList: data || [],
            isPriceRangeActive: false,
            isCategory: ""
        })
    }

    handelGetCategoriesProduct = (item, key) =>{
        let brandData = []
        if(key === "brand"){
            brandData = productList.filter(h => h.brandName === item)
        }else if(key === "category"){
            brandData = productList.filter(h => h.category === item)
        }

        this.setState({
            categoriesProductList: brandData || [],
            isCategory: key || "",
            sortType: "",
            isPriceRangeActive: false
        })
    }

    render() {
        const {isModal, productPreview, productDataList, sortType, productSortDataList, rangeProductList, isPriceRangeActive,
               colorProductList, categoriesProductList, isCategory, isGridPageFull } = this.state
        const finalDataList = isPriceRangeActive ? rangeProductList :
                              sortType ? productSortDataList :
                              isCategory ? categoriesProductList :
                              colorProductList.length > 0 ? colorProductList : productList
        return(
            <div>
                <BreadCrumb heading="Products" subHeading={ isGridPageFull ? "Product Grids Left Sidebar Full" : "Product Grids Left Sidebar"}/>
                <section className="exproducts-style1 padding-80px-tb grid-page side-bar-page">
                    <div className={isGridPageFull ? "container-fluid" : "container"}>
                        <div className="row">
                            <ProductSideBar
                                handelGetColorProductData={this.handelGetColorProductData}
                                handelPriceRangeComplete={this.handelPriceRangeComplete}
                                handelGetCategoriesProduct={this.handelGetCategoriesProduct}/>
                            <div className="col-xl-9 col-lg-9 col-md-8 col-12 sm-margin-top-20px">
                                <div className="border-bottom-medium-dark2 margin-bottom-15px">
                                    <div className="col-md-12">
                                        <div className="row">
                                            {/* grid & list button starts */}
                                            <div className="col-lg-6 col-md-4 col-6 grid-list padding-bottom-15px no-padding-left border-radius-5 sm-display-flex sm-align-items-center view-mode">
                                                <Link to="/products/grids-left-sidebar" className="btn small-btn-gl active margin-right-5px"><i className="fa fa-th-large" /></Link>
                                                <Link to="/products/list-page-left-sidebar" className="btn small-btn-gl"><i className="fa fa-bars" /></Link>
                                            </div>
                                            {/* grid & list button ends */}
                                            {/* sort by starts */}
                                            <ProductSort
                                                productDataList={productList}
                                                handelSorting={this.handelSorting}
                                            />
                                            {/* sort by ends */}
                                        </div>
                                    </div>
                                </div>
                                {/* quick view starts */}
                                {
                                    isModal &&
                                    <ProductViewModal
                                        isModal={isModal}
                                        productPreview={productPreview}
                                        handelModal={this.handelModal}/>
                                }
                                {/* quick view ends */}
                                <div className="row grid-pro-main">
                                    {
                                        productDataList.map((item, index) =>{
                                            return(
                                                <div className="col-xl-3 col-lg-3 col-md-6 col-6 grid-pro-info" key={index}>
                                                    <div className="product-info">
                                                        <div className="content-overlay">
                                                            {/* product1 image starts */}
                                                            <div className="product-img">
                                                                <div className="tab-content">
                                                                    <div className="tab-pane active">
                                                                        <img src={item.activeImg ? item.activeImg : item.pictures[0]}
                                                                             alt="product-detail"
                                                                             className="img-fluid" />
                                                                    </div>
                                                                </div>
                                                                {item.new && <div className="new-offer bg-green">New</div>}
                                                                {item.bestFeature && <div className="new-offer bg-light-blue">Best Feature</div>}
                                                                {item.trade  && <div className="new-offer bg-light-red">Trade</div>}
                                                            </div>
                                                            {/* product1 image ends */}
                                                            <div className="content position-absolute opacity-none">
                                                                {/* icons starts */}
                                                                <ul className="no-padding-left">
                                                                    <li>
                                                                        <span onClick={() => this.props.addToCart(item)}
                                                                           className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block">
                                                                            <i className="fas fa-shopping-bag" />
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span onClick={() => this.handelModal(item)}
                                                                           className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block cursor-pointer">
                                                                            <i className="fas fa-eye" />
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span onClick={() => this.props.addToWish(item)}
                                                                           className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block">
                                                                            <i className="fas fa-heart" />
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                {/* icons ends */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="g-pro-detail">
                                                        {/* product1 description starts */}
                                                        <div className="protext text-center">
                                                            <Link to={`/product/${item.id}`} className="d-inline-block text-dark-gray font-weight-600 hoverstyle1">
                                                                {titleUpperCase(item.productTitle)}
                                                            </Link>
                                                            <p className="pricetext font-weight-600">
                                                                ${item.salePrice && item.salePrice.toFixed(2)}
                                                            </p>
                                                        </div>
                                                        {/* product ratings starts */}
                                                        <Rating ratingStars={item.rating}/>
                                                        {/* product1 description ends */}
                                                        <div className="small-img">
                                                            {/* product1 image ends */}
                                                            <ul className="preview-thumbnail nav nav-tabs">
                                                                {/* product below image 1 start */}
                                                                {
                                                                    item.pictures.map((img, i) =>(
                                                                        <li key={i}>
                                                                            <Link to="#" className={item.activeImgIndex === i ? "active show" : ""} onClick={()=> this.handelActiveImg(img, index, i)} data-toggle="tab">
                                                                                <img src={img} alt="product-detail" className="img-fluid w-100" />
                                                                            </Link>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                            {/* product below image end */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        !productDataList.length && <NotFoundData/>
                                    }
                                </div>
                                <Pagination
                                    pageSize={12}
                                    sortType={sortType}
                                    items={finalDataList || []}
                                    onChangePage={this.onChangePage} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item)),
    addToWish: (data) => dispatch(addItemToWish(data)),
})

export default connect(
    null,
    mapDispatchToProps
)(GridsLeftSidebar)
