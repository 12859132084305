import React from "react";
import imageUtil from "../../../globalUtility/imageHelper";
import SideBar from "../Common/SideBar";
import BlogDetailContent from "../Common/BlogDetailContent";
import BreadCrumb from "../../../GlobalComponents/BreadCrumb";
import {blogDetailComments} from "../../../globalUtility/commonHelper";

class BlogDetailsLeftSidebarFull extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            blog: {
                id: 19,
                image: imageUtil.images.blogDetailMain,
                title: "What Is Art? An Insight About Profoundness Of Art!"
            },
            comment: 0
        }
    }

    componentWillMount() {
        const {location} = this.props
        this.countComments()
        const data = ((location && location.state) && location.state.blog) || {}
        if (data && Object.keys(data).length) {
            this.setState({
                blog: data
            }, () => this.countComments())
        }
    }

    countComments = () => {
        const {blog} = this.state
        const blogData = blogDetailComments(blog)
        this.setState({
            comment: (blogData && blogData.length) ? blogData.length : 0
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {location} = this.props
        const data = ((nextProps && nextProps.location && nextProps.location.state && nextProps.location.state.blog)) || {}
        if ((location && location.state && location.state.blog && location.state.blog.image) !== (data && data.image)) {
            if (data && Object.keys(data).length) {
                this.setState({
                    blog: data
                })
            }
        }
    }

    render() {
        const {blog, comment} = this.state
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Blogs" subHeading="Blog Details Left Sidebar Full"/>
                {/* breadcrumb section end */}
                <section className="blog-detailsstyle1 padding-80px-tb">
                    <div className="container-fluid">
                        <div className="row">
                            {/* left sidebar starts */}
                            <SideBar isRedirect={true}/>
                            {/* left sidebar ends */}
                            <BlogDetailContent blog={blog} comment={comment}/>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default BlogDetailsLeftSidebarFull
