import React from "react"
import {Link} from "react-router-dom"
import blogUtil from "../../../globalUtility/jsonData/blog/BlogDataList"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import titleUpperCase from "../../../globalUtility/commonHelper";

const RelatedBlogs = () => {
    const relatedBlogs =  blogUtil.relatedBlogs
    return (
        <OwlCarousel className="owl-carousel owl-theme" loop items={1} autoplay autoplaySpeed={3000}>
            {
                relatedBlogs.map((item, index) => {
                    return(
                        <div key={index} className="item">
                            {/* blog1 starts */}
                            <div className="blog-wrap">
                                <div className="blog-wrapper-img">
                                    {/* related image starts */}
                                    <Link to={{ pathname: '/blogs/blogdetails-left-sidebar-full', state: { blog:{image: item.image, title: titleUpperCase(item.title)}} }}>
                                        <img src={item.image} className="img-fluid border-radius-5" alt="img" />
                                    </Link>
                                    {/* related image ends */}
                                </div>
                                <div className="tab-info-details">
                                    <h6 className="font-weight-600 text-large md-text-large sm-text-extra-large">
                                        {titleUpperCase(item.title)}
                                    </h6>
                                    <p className="sm-text-large">
                                        {titleUpperCase(item.description)}
                                    </p>
                                    <Link to={{ pathname: '/blogs/blogdetails-left-sidebar-full', state: { blog:{image: item.image, title: titleUpperCase(item.title)}} }} className="btn btn-style1 bg-sky-blue-light hoverstyle1 text-white">
                                        View Details
                                    </Link>
                                </div>
                            </div>
                            {/* blog1 ends */}
                        </div>
                    )
                })
            }

        </OwlCarousel>
    )
}

export default RelatedBlogs
