import React from "react";
import {productList} from "../../globalUtility/jsonData/product/ProductDataList";
import {Link} from "react-router-dom";
import ProductViewModal from "../../globalUtility/ProductViewModal";
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import titleUpperCase from "../../globalUtility/commonHelper";
import {addItemToCart} from "../../actions/cartAction";
import {connect} from "react-redux";
import {addItemToWish} from "../../actions/wishListAction";

class RelatedProducts extends React.Component{
    state = {
        isModal: false,
        productPreview: {},
        productDataList: []
    }

    componentWillMount() {
        const productData = productList.filter(p => p.id >= 11 && p.id <= 31) || []
        this.setState({
            productDataList: productData
        })
    }

    handelModal = (item) => {
        this.setState({
            isModal: !this.state.isModal,
            productPreview: item.productTitle ? item : {}
        })
    }

    render() {
        const {productDataList, productPreview, isModal} = this.state

        return(
            <section className="padding-bottom-80px lg-padding-top-40px md-padding-top-30px sm-display-none related-product-sec">
                {/* related products starts */}
                <ProductViewModal
                    isModal={isModal}
                    productPreview={productPreview}
                    handelModal={this.handelModal}
                />

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="margin-top-40px lg-margin-top-10px md-margin-top-10px sm-margin-top-20px padding-bottom-20px text-center">
                                {/* main heading starts */}
                                <h5 className="d-inline-block border-bottom-khaki font-weight-600 main-font sm-text-extra-large">Related Products</h5>
                                {/* main heading ends */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* product starts */}
                        {
                            <OwlCarousel className="owl-theme" loop items={3} navText={['<i class="fas fa-long-arrow-alt-left"/>','<i class="fas fa-long-arrow-alt-right"/>']} autoplay autoplayHoverPause autoplayTimeout={3000}>
                                {
                                    productDataList && productDataList.map((item, index) => {
                                        return(
                                            <div key={index} className="col-12">
                                                <div className="product-info">
                                                    <div className="content-overlay position-relative overflow-hidden">
                                                        {/* product image starts */}
                                                        <div className="product-img color-bg-b text-center">
                                                            <img src={item.pictures && item.pictures[0]} alt={item.pictures && item.pictures[0]} className="img-fluid" />
                                                            {item.new && <div className="new-offer bg-green">New</div>}
                                                        </div>
                                                        <div className="content position-absolute opacity-none">
                                                            <ul className="no-padding-left">
                                                                <li>
                                                                    <span onClick={() => this.props.addToCart(item)} className="text-fast-gray text-medium2 hoverstyle1 line-height-34 d-block">
                                                                        <i className="fas fa-shopping-bag" />
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span onClick={() => this.handelModal(item)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block">
                                                                        <i className="fas fa-eye" />
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span onClick={() => this.props.addToWish(item)}  className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block">
                                                                        <i className="fas fa-heart" />
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* product description starts */}
                                                <div className="protext text-center margin-top-10px">
                                                    <Link to={`/product/${item.id}`} className="d-inline-block text-dark-gray font-weight-600 hoverstyle1">
                                                        {titleUpperCase(item.productTitle)}
                                                    </Link>
                                                    <p className="pricetext font-weight-600">
                                                        ${item.salePrice && item.salePrice.toFixed(2)}
                                                    </p>
                                                </div>
                                                {/* product description ends */}
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item)),
    addToWish: (data) => dispatch(addItemToWish(data)),
})

export default connect(
    null,
    mapDispatchToProps
)(RelatedProducts)
