import React from "react"
import BlogsList from "../Common/BlogsList"
import BreadCrumb from "../../../GlobalComponents/BreadCrumb"
import Pagination from "../../../GlobalComponents/Pagination";
import {blogComments} from "../../../globalUtility/commonHelper";

class BlogsFull extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            blogList: [],
        }
    }

    componentWillMount() {
        const data = blogComments()
        this.setState({ data })
    }

    onChangePage = (pageOfItems) => {
        this.setState({
            blogList: pageOfItems
        });
    }

    render() {
        const {blogList, data} = this.state
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Blogs" subHeading="Blogs Full"/>
                {/* breadcrumb section end */}
                <section className="blog-page padding-80px-tb">
                    <div className="container-fluid">
                        <BlogsList blogList={blogList}/>
                        <Pagination
                            pageSize={6}
                            sortType=""
                            items={data.blogList}
                            onChangePage={this.onChangePage} />
                    </div>
                </section>
            </>
        )
    }
}

export default BlogsFull
