import React from "react";
import imageUtil from "../../globalUtility/imageHelper"
import { Link } from "react-router-dom";

class Deals2 extends React.Component {
    render() {
        const dataImage = imageUtil.images
        return (
            <div>
                {/*<!-- best deal section starts -->*/}
                <section className="padding-80px-tb best-deals-style1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 center-col margin-bottom-40px lg-margin-bottom-30px md-margin-bottom-25px sm-margin-bottom-20px text-center">
                                {/*<!-- best deals heading starts -->*/}
                                <h3 className="font-weight-800 main-font text-transform lg-title-medium md-title-medium sm-title-mdmore">Best Deals</h3>
                                <span className="md-text-large main-font font-weight-600 sm-text-large">Here's The Best Deals You Can Grab</span>
                                {/* <!-- best deals heading ends -->*/}
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row grid-all-item">
                            {/*<!-- product1 starts -->*/}
                            <div className="grid-item-img col-xl-3 col-lg-3 col-md-4 col-12">
                                {/*<!-- product1 image1 starts -->*/}
                                <div className="grid-image">
                                    <div className="best-images">
                                        <img src= {dataImage.bags1} alt="image1" className="img-fluid"/>
                                        <div className="shop-now-btn">
                                            <Link to="/category/footwear" className="s-now">Shop Now</Link>
                                            <div className="offer">
                                                <h6>Summer Sale -40% Off</h6>
                                                <span>Shoe Flat</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-image">
                                    <div className="best-images">
                                        <img src= {dataImage.bags2} alt="image1" className="img-fluid"/>
                                        <div className="shop-now-btn">
                                            <Link to="/category/accessories" className="s-now">Shop Now</Link>
                                            <div className="offer">
                                                <h6>Summer Sale -20% Off</h6>
                                                <span>Speaker Flat</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-item-img col-xl-6 col-lg-6 col-md-8">
                                <div className="best-images">
                                    <img src= {dataImage.shoesBig22} alt="image1" className="img-fluid"/>
                                    <div className="shop-now-btn">
                                        <Link to="/category/clothes" className="s-now">Shop Now</Link>
                                        <div className="offer">
                                            <h6>Summer Sale -40% Off</h6>
                                            <span>Women Cloth</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-item-img col-xl-3 col-lg-3 col-md-12 col-12">
                                {/*<!-- product1 image1 starts -->*/}
                                <div className="grid-image">
                                    <div className="best-images">
                                        <img src= {dataImage.bags3} alt="image1" className="img-fluid"/>
                                        <div className="shop-now-btn">
                                            <Link to="/category/accessories" className="s-now">Shop Now</Link>
                                            <div className="offer">
                                                <h6>Summer Sale -40% Off</h6>
                                                <span>Handbag Flat</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-image">
                                    <div className="best-images">
                                        <img src= {dataImage.bags4} alt="image1" className="img-fluid"/>
                                        <div className="shop-now-btn">
                                            <Link to="/category/accessories" className="s-now">Shop Now</Link>
                                            <div className="offer">
                                                <h6>Summer Sale -40% Off</h6>
                                                <span>Wireless Speaker Flat</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<!-- product1 ends -->*/}
                        </div>
                    </div>
                </section>
                {/*<!-- best deal section ends -->*/}
            </div>
        )
    }
}

export default Deals2