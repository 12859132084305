import React from "react";
import BreadCrumb from "../GlobalComponents/BreadCrumb";
import { Link } from "react-router-dom";

class ForgetPassword extends React.Component {
    render() {
        return(
            <>
                {/* breadcrumb section start */}
                <BreadCrumb heading="Pages" subHeading="Forget Password"/>
                {/* breadcrumb section end */}
                {/* forgot password section starts */}
                <section className="padding-80px-tb forget-password-page">
                    <div className="container">
                        <div className="row sm-margin-left-right-0px">
                            {/* heading starts */}
                            <div className="text-black col-lg-8 col-md-10 col-12 center-col forgot-pf border-radius-5 paddingtwenty">
                                <div className="text-center">
                                    <h6 className="text-left font-weight-600 display-inline-block border-bottom-khaki margin-bottom-15px text-extra-large md-title-mdall sm-text-extra-large">Forgot Your Password?</h6>
                                </div>
                                <ul className="padding-left-20px">
                                    <li className="text-sky-blue">
                                        <p className="text-left text-black sm-text-large">No need to worry! we will send you email.</p>
                                    </li>
                                    <li className="text-sky-blue">
                                        <p className="text-left text-black sm-text-large">Please enter your email address below we will send you link to set a new password.</p>
                                    </li>
                                </ul>
                                {/* form starts */}
                                <form className="text-left">
                                    {/* email starts */}
                                    <input type="text" name="E-mail" placeholder="Enter your email address" className="form-control border-radius-5 p-2 sm-text-large" />
                                    {/* email ends */}
                                    {/* button starts */}
                                    <Link to="#" className="btn btn-style1">Send</Link>
                                    {/* button ends */}
                                </form>
                                {/* form ends */}
                            </div>
                            {/* heading ends */}
                        </div>
                    </div>
                </section>
                {/* forgot password section ends */}

            </>
        )
    }

}

export default ForgetPassword;