import React from "react";
import imageUtil from "../globalUtility/imageHelper";
import {Link} from "react-router-dom";

class SaleAndNewsLetter extends React.Component {
    render() {
        const images = imageUtil.images
        return(
            <>
                {/* newsletter section starts */}
                <section className="padding-80px-tb newsletterstyle-1">
                    {/* newsletter heading starts */}
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="newsletter-offer-img">
                                    <img alt="newsLetter" src={images.newsLetter} className="img-fluid border-radius-5" />
                                    <div className="newsletter-content">
                                        <h2 className="newsletter-subtitle">Limited time only</h2>
                                        <h3 className="newsletter-title">End of Season <br /> Save 50% off</h3>
                                        <Link to="/products/grids-page" className="btn btn-style1">Shop Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 text-center news-letter-section">
                                <div className="news-letter-section-box border-radius-5">
                                    <div className="news-letter-content">
                                        <h6 className="text-transform lg-title-medium md-title-medium sm-title-mdmore">NEWSLETTER &amp; GET DISCOUNT 25% OFF</h6>
                                        <span className="main-font sm-text-large d-block margin-bottom-40px lg-margin-bottom-30px md-margin-bottom-25px sm-margin-bottom-20px">Sign Up to our newsletter and get a 20% discount offer</span>
                                        <div className="foremail">
                                            {/* email starts */}
                                            <input className="email form-control display-inline-block sm-text-large" data-field="required" id="email" type="text" name="email" placeholder="Your E-mail Address" />
                                            {/* email ends */}
                                            {/* subscribe button starts */}
                                            <div>
                                                <Link to="#" className="btn-style1 margin-top-20px display-inline-block">
                                                    <i className="fas fa-paper-plane pr-2" />Subscribe
                                                </Link>
                                            </div>
                                            {/* subscribe button ends */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* newsletter heading ends */}
                </section>
                {/* newsletter section ends */}
            </>
        )
    }
}

export default SaleAndNewsLetter;
