import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom"
import {productList} from "../globalUtility/jsonData/product/ProductDataList";
import ProductViewModal from "../globalUtility/ProductViewModal";
import {addItemToCart} from "../actions/cartAction";
import titleUpperCase from "../globalUtility/commonHelper";
import {addItemToWish} from "../actions/wishListAction";

class ExclusiveProducts extends React.Component{
    state = {
        isModal: false,
        productPreview: {},
        productDataList: []
    }

    componentDidMount() {
        const productData = productList.filter(p => p.id >= 0 && p.id <= 8 )
        this.setState({
            productDataList: productData || []
        })
    }

    handelModal = (item) =>{
        this.setState({
            isModal: !this.state.isModal,
            productPreview: item.productTitle ? item : {}
        })
    }

    render() {
        const {isModal, productPreview, productDataList} = this.state
        return(
            <section className="padding-80px-tb exproducts-style1 border-top-medium-dark">
                <ProductViewModal
                    isModal={isModal}
                    productPreview={productPreview}
                    handelModal={this.handelModal}/>
                <div className="container">
                    <div className="row">
                        {/* ex-products heading starts */}
                        <div className="col-lg-8 center-col margin-bottom-40px lg-margin-bottom-30px md-margin-bottom-25px sm-margin-bottom-20px text-center">
                            <h3 className="text-black font-weight-800 main-font text-transform lg-title-medium md-title-medium sm-title-mdmore">
                                Our Exclusive Products
                            </h3>
                            <span className="text-black md-text-large main-font font-weight-600 sm-text-large">
                                The Products Which You Never See Anywhere
                            </span>
                        </div>
                        {/* ex-products heading ends */}
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                    {
                        productDataList.map((item, index) =>{
                            return(
                                <div key={index} className="col-xl-3 col-lg-3 col-md-3 col-6">
                                    <div className="product-info">
                                        <div className="content-overlay position-relative overflow-hidden">
                                            {/* product1 image starts */}
                                            <div className="product-img color-bg-b text-center">
                                                <img src={item.pictures && item.pictures[0]}
                                                     alt={item.pictures && item.pictures[0]}
                                                     className="img-fluid" />
                                                {item.new && <div className="new-offer bg-green">New</div>}
                                                {item.trade && <div className="new-offer bg-light-blue">Best Feature</div>}
                                                {item.bestFeature && <div className="new-offer bg-light-red">Trade</div>}
                                            </div>
                                            {/* product1 image ends */}
                                            <div className="content position-absolute opacity-none">
                                                {/* icons starts */}
                                                <ul className="no-padding-left">
                                                    <li>
                                                        <span onClick={() => this.props.addToCart(item)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block">
                                                            <i className="fas fa-shopping-bag" />
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span onClick={()=>this.handelModal(item)} className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block">
                                                            <i className="fas fa-eye" />
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span onClick={() => this.props.addToWish(item)}  className="text-fast-gray hoverstyle1 text-medium2 line-height-34 d-block">
                                                            <i className="fas fa-heart" />
                                                        </span>
                                                    </li>
                                                </ul>
                                                {/* icons ends */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* product1 description starts */}
                                    <div className="protext text-center margin-top-10px">
                                        <Link to={`/product/${item.id}`} className="d-inline-block text-dark-gray font-weight-600 hoverstyle1">
                                            {titleUpperCase(item.productTitle)}
                                        </Link>
                                        <p className="pricetext font-weight-600">${item.salePrice && item.salePrice.toFixed(2)}</p>
                                    </div>
                                    {/* product1 description ends */}
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </section>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item)),
    addToWish: (data) => dispatch(addItemToWish(data)),
})

export default connect(
    null,
    mapDispatchToProps
)(ExclusiveProducts)
