import React from "react";
import Slider1 from "./Slider1";
import Deals2 from "./Deals2";
import OurExclusiveProducts from "./OurExclusiveProducts";
import DealOfTheDay from "../DealOfTheDay";
import BestBrands from "../BestBrands";
import SaleAndNewsLetter from "../SaleAndNewsLetter";

class Home1 extends React.Component {
    render() {
        return (
            <div className="home-2">
                <Slider1/>
                <Deals2/>
                <OurExclusiveProducts/>
                <DealOfTheDay/>
                <BestBrands/>
                <SaleAndNewsLetter/>
            </div>
        )
    }
}

export default Home1
