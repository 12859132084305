import React from "react";
import {Link} from "react-router-dom"
import HomeDataList from "../globalUtility/jsonData/HomeDataList";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class Slider extends React.Component{

    render() {
        const sliderDataList = HomeDataList.sliderDataList || []
        return(
            <section className="slider-style1 position-relative">

                {/* <div className="owl-carousel d-block" id="home-slider"> */}
                <OwlCarousel className="home-slider owl-carousel owl-theme d-block" loop items={1} navText={['<i class="fas fa-long-arrow-alt-left"></i>','<i class="fas fa-long-arrow-alt-right"></i>']} nav >
                     {
                        sliderDataList.map((item, index) =>{
                            return(
                                <div key={index} className="item fullscreen cover-background lg-position-center md-right-65p-bottom sm-right-65p-bottom" style={{backgroundImage: `url(${item.image})`, minHeight:'625px'}}>
                                    <div className="slidertext left-60p sm-left-50p width-30 position-absolute top-40p z-index-8 md-top-35p sm-top-35p">
                                        <span className="font-weight-600 border-bottom-khaki text-black">{item.offerTitle}</span>
                                        <h1 className="font-weight-800 title-extra-large-3 lg-title-extra-large-2 md-title-extra-large-1 margin-top-15px sm-title-extra-large margin-bottom-25px text-black">
                                            {item.discountBeforeText}
                                            <span className="text-sky-blue">{item.discount}%</span>
                                            {item.discountAfterText}
                                        </h1>
                                        <Link to={item.redirectUrl} className="btn-style1">Buy Now</Link>
                                    </div>
                                </div>
                            )
                        })
                    }

                </OwlCarousel>

                {/* </div> */}
            </section>

        )
    }
}

export default Slider
