import {
    ADD_TO_WISH_LIST_PRODUCT, END_ADD_TO_WISH_LIST, REMOVE_TO_WISH_LIST_PRODUCT,
} from '../constants/actionTypes';

const initialState = {
    wishList: []
}

const wishReducer = (state = initialState , action) => {
    switch (action.type) {
        case ADD_TO_WISH_LIST_PRODUCT: {
            return {
                ...state,
                wishList: (action.allWish) || []
            }
        }
        case END_ADD_TO_WISH_LIST: {
            return {
                ...state,
                errorMsg: action.errorMsg,
            }
        }
        case REMOVE_TO_WISH_LIST_PRODUCT: {
            return {
                ...state,
                totalWishList: action.totalWishList
            }
        }
        default:
            return state
    }
}
export default wishReducer
