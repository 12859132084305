import {
    REMOVE_TO_COMPARE_PRODUCT,
    ADD_TO_COMPARE_PRODUCT,
    END_ADD_TO_CART,
} from "../constants/actionTypes";
import {toast} from "react-toastify";

export const productCompareAction = (item) => {
    return (dispatch) => {
        try {
            const compare = localStorage.getItem("compareList")
            const compareListData = JSON.parse(compare) || []
            const isExist = compareListData.find(p => p.id === item.id)
            if(isExist){
                toast.warn(`This item already added to compare`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }else {
                compareListData.push(item)
                if((compareListData && compareListData.length) > 3){
                    toast.warn(`You have reached Maximum limit`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }else {
                    toast.success(`Item has been added to compare`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    localStorage.setItem("compareList" , JSON.stringify(compareListData))
                    dispatch({type: ADD_TO_COMPARE_PRODUCT, compareListData: compareListData});
                }
            }
        } catch (error) {
            dispatch({type: END_ADD_TO_CART, errorMsg: error});
        }
    }
};

export const handelCompareProduct = (data) => {
    return (dispatch) => {
        try {
            dispatch({type: REMOVE_TO_COMPARE_PRODUCT, compareListData: data});
        } catch (error) {
            dispatch({type: END_ADD_TO_CART, errorMsg: error});
        }
    }
};
