import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {productList} from "../../../globalUtility/jsonData/product/ProductDataList";
import ProductSort from "../Common/ProductSort";
import Rating from "../Common/Rating";
import Pagination from "../../../GlobalComponents/Pagination";
import ProductViewModal from "../../../globalUtility/ProductViewModal";
import BreadCrumb from "../../../GlobalComponents/BreadCrumb";
import titleUpperCase from "../../../globalUtility/commonHelper";
import {addItemToCart} from "../../../actions/cartAction";
import {addItemToWish} from "../../../actions/wishListAction";
import {productCompareAction} from "../../../actions/productCompareAction";

class ListPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            productDetails: {},
            isModal: false,
            productDataList: [],
            productSortDataList: [],
            isGridPageFull: false
        }
    }

    componentWillMount() {
        let activePage = window.location.href.split("/")[4]
        this.setState({
            productDataList: productList,
            isGridPageFull: activePage === "list-page-full" || false
        })
    }

    handelModal = (item) =>{
        this.setState({
            isModal: !this.state.isModal,
            productPreview: item.productTitle ? item : {}
        })
    }

    handelActiveImg = (img, i, index) =>{
        const {productDataList} = this.state
        productDataList[i].activeImg = img
        productDataList[i].activeImgIndex = index
        this.setState({
            productDataList
        })
    }

    handelSorting = (data, type) =>{
        this.setState({
            productDataList: [],
        },()=> {
            this.setState({
                sortType: type,
                productSortDataList: data,
            })
        })
    }

    onChangePage = (pageOfItems) => {
        this.setState({
            productDataList: pageOfItems
        });
    }

    render() {
        const {isModal, productPreview, productDataList, sortType, productSortDataList, isGridPageFull} = this.state
        return(
            <div>
                <BreadCrumb heading="Products" subHeading={isGridPageFull ? "Product List Full Page" : "Product List Page"}/>
                <section className="padding-80px-tb tilesstyle-1 pro-list-section">
                <div className={isGridPageFull ? "container-fluid" : "container"}>
                    <div className="border-bottom-medium-dark2 margin-bottom-15px">
                        <div className="col-md-12">
                            <div className="row">
                                {/* grids & list button starts */}
                                <div className="col-lg-6 col-md-6 col-sm-5 col-6 grid-list padding-bottom-15px no-padding-left border-radius-5 sm-display-flex sm-align-items-center view-mode">
                                    <Link to="/products/grids-page" className="btn small-btn-gl margin-right-5px"><i className="fa fa-th-large" /></Link>
                                    <Link to="/products/list-page" className="btn small-btn-gl active"><i className="fa fa-bars" /></Link>
                                </div>
                                {/* grids & list button ends */}
                                {/* sort by starts */}
                                <ProductSort
                                    productDataList={productList}
                                    handelSorting={this.handelSorting}
                                />
                                {/* sort by ends */}
                            </div>
                        </div>
                        {/*quick view starts */}
                        {
                            isModal &&
                            <ProductViewModal
                                isModal={isModal}
                                productPreview={productPreview}
                                handelModal={this.handelModal}/>
                        }
                        {/*quick view ends */}
                    </div>
                    {
                        productDataList.map((item, index) =>{
                            return(
                                <div key={index} className="row pro-list-main">
                                    <div className="col-xl-12 list-border">
                                        <div className="row ">
                                            {/* product2 starts */}
                                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                                {/* product1 image starts */}
                                                <div className="tiles">
                                                    <div className="tab-content">
                                                        <div className="tab-pane active" id="pic-5">
                                                            <img src={item.activeImg ? item.activeImg : item.pictures[0]}
                                                                 alt="product-detail"
                                                                 className="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-12 pro-list-content">
                                                {/* product1 heading starts */}
                                                <div className="font-weight-600 margin-bottom-5px sm-margin-top-10px sm-text-large">
                                                    <Link to={`/product/${item.id}`} className="hoverstyle1 text-black">
                                                        {titleUpperCase(item.productTitle)}
                                                    </Link>
                                                </div>
                                                {/* product1 heading ends */}
                                                <div className="product-details display-table-cell-vertical-middle">
                                                    <p className="margin-bottom-10px sm-text-large">
                                                        {titleUpperCase(item.description)}
                                                    </p>
                                                </div>
                                                <div className="small-img">
                                                    {/* product1 image ends */}
                                                    <ul className="preview-thumbnail nav nav-tabs">
                                                        {/* product below image 1 start */}
                                                        {
                                                            item.pictures.map((img, i) =>(
                                                                <li key={i}>
                                                                    <Link to="#" className={item.activeImgIndex === i ? "active show" : ""}
                                                                       onClick={()=> this.handelActiveImg(img, index, i)} data-toggle="tab">
                                                                        <img src={img} alt="product-detail" className="i mg-fluid w-100" />
                                                                    </Link>
                                                                </li>
                                                            ))
                                                        }
                                                        {/* product below image 4 end */}
                                                    </ul>
                                                    {/* product below image end */}
                                                </div>
                                            </div>
                                            {/* product ends */}
                                            <div className="col-xl-3 col-lg-3 col-md-6 col-12 pro-price-info">
                                                <div className="pro-list-info">
                                                    {/* price starts */}
                                                    <div className="pro-price main-font sm-text-large">
                                                        <span className="pl-price">Price: </span>
                                                        ${item.salePrice && item.salePrice.toFixed(2)}
                                                    </div>
                                                    {/* price ends */}
                                                    {/* product ratings starts */}
                                                    <Rating ratingStars={item.rating}/>
                                                    {/* product ratings ends */}
                                                    <div className="quick-com">
                                                        <div className="quick-view display-inline-block">
                                                            <span onClick={()=>this.handelModal(item)}
                                                               className="text-black cursor-pointer"><i className="fas fa-eye" />Quick View
                                                            </span>
                                                        </div>
                                                        <div className="compare">
                                                            <span onClick={() => this.props.addToCompareProduct(item)}
                                                                  className="text-black cursor-pointer"><i className="fas fa-balance-scale" />Compare
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="product-badget">
                                                        <span onClick={() => this.props.addToCart(item)}
                                                              className="btn btn-style1"><i className="fas fa-shopping-bag" /> Add to cart
                                                        </span>
                                                    </div>
                                                    <div className="wishlist">
                                                        <span onClick={() => this.props.addToWish(item)}
                                                              className="btn btn-style1"><i className="fas fa-heart" />Wishlist
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* product2 end */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <Pagination
                        pageSize={8}
                        sortType={sortType}
                        items={sortType ? productSortDataList : productList}
                        onChangePage={this.onChangePage} />
                </div>
            </section>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item)),
    addToCompareProduct: (item) => dispatch(productCompareAction(item)),
    addToWish: (data) => dispatch(addItemToWish(data)),
})

export default connect(
    null,
    mapDispatchToProps
)(ListPage)
