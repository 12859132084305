import React from "react";
import imageUtil from "../globalUtility/imageHelper";

class BestBrands extends React.Component {
    render() {
        const images = imageUtil.images
        return(
            <>
                {/* best brands section starts */}
                <section className="padding-80px-tb bestbrands border-bottom-medium-dark">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 text-center b-brand">
                                <div className="margin-bottom-40px lg-margin-bottom-30px md-margin-bottom-25px sm-margin-bottom-20px">
                                    {/* best brands heading starts */}
                                    <h3 className="text-black font-weight-800 main-font text-transform lg-title-medium md-title-medium sm-title-mdmore">Best Brands About Fashion</h3>
                                    <span className="text-black md-text-large main-font font-weight-600 sm-text-large">We Have The Best Brands Available For You</span>
                                    {/* best brands heading ends */}
                                </div>
                                {/* brand icons starts */}
                                <ul className="best-brand">
                                    <li className="best-brand-logo">
                                        <div className="brand-icon text-center">
                                            <img className="img-fluid" src={images.bestBrand1} alt="brand-icon" />
                                        </div>
                                    </li>
                                    <li className="best-brand-logo">
                                        <div className="brand-icon text-center">
                                            <img  className="img-fluid" src={images.bestBrand2} alt="brand-icon" />
                                        </div>
                                    </li>
                                    <li className="best-brand-logo">
                                        <div className="brand-icon text-center">
                                            <img className="img-fluid" src={images.bestBrand3} alt="brand-icon" />
                                        </div>
                                    </li>
                                    <li className="best-brand-logo">
                                        <div className="brand-icon text-center">
                                            <img className="img-fluid" src={images.bestBrand4} alt="brand-icon" />
                                        </div>
                                    </li>
                                    <li className="best-brand-logo">
                                        <div className="brand-icon text-center">
                                            <img className="img-fluid" src={images.bestBrand5} alt="brand-icon" />
                                        </div>
                                    </li>
                                    <li className="best-brand-logo">
                                        <div className="brand-icon text-center">
                                            <img className="img-fluid" src={images.bestBrand6} alt="brand-icon" />
                                        </div>
                                    </li>
                                </ul>
                                {/* brand icons ends */}
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="text-center margin-bottom-40px lg-margin-bottom-30px md-margin-bottom-25px sm-margin-bottom-20px">
                                    {/* best brands heading starts */}
                                    <h3 className="text-black font-weight-800 main-font text-transform lg-title-medium md-title-medium sm-title-mdmore">Follow Us On Instagram</h3>
                                    <span className="text-black md-text-large main-font font-weight-600 sm-text-large">@ Macro Shop Instagram</span>
                                    {/* best brands heading ends */}
                                </div>
                                <div className="instagram">
                                    <div className="text-center">
                                        {/* insta-feed heading start */}
                                        {/* insta-feed image start */}
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                        {/* id="instaFeed-style1"*/}
                                            <div className="instagram_feed row">
                                               <div className="col-xl-4 col-lg-4 col-md-4 col-6 padding-left-0px padding-right-0px instafeed-style1">
                                                  <a href="https://www.instagram.com/p/BzkrRXJH2-s/" className="insta-link" target="_blank" rel="noopener noreferrer">
                                                     <img alt="insta-counts" src="//scontent.cdninstagram.com/v/t51.2885-15/e35/64830434_1305181922973415_6410922545125069679_n.jpg?_nc_ht=scontent.cdninstagram.com&amp;_nc_ohc=AGf8QAwcOLAAX_R1NHT&amp;oh=3b825f6b6cb197768e0ce40bfedde6ef&amp;oe=5E8FC6B9" className="insta-image img-fluid w-100 h-100"/>
                                                      <div className="insta-counts">
                                                          <span className="margin-right-20px">
                                                              <i className="far fa-heart"/>
                                                              <span className="count-number">7</span>
                                                          </span>
                                                          <span>
                                                              <i className="far fa-comment"/>
                                                              <span className="count-number">0</span>
                                                          </span>
                                                      </div>
                                                  </a>
                                               </div>
                                               <div className="col-xl-4 col-lg-4 col-md-4 col-6 padding-left-0px padding-right-0px instafeed-style1">
                                                  <a href="https://www.instagram.com/p/BzkrN-mnxSy/" className="insta-link" target="_blank" rel="noopener noreferrer">
                                                     <img alt="insta" src="//scontent.cdninstagram.com/v/t51.2885-15/e35/66485827_396989954255218_213273440306399488_n.jpg?_nc_ht=scontent.cdninstagram.com&amp;_nc_ohc=BdR6KPMKC7sAX-FARPD&amp;oh=5395b5df85a420c836e55d120e5dc83b&amp;oe=5EB3AEFB" className="insta-image img-fluid w-100 h-100"/>
                                                      <div className="insta-counts">
                                                          <span className="margin-right-20px">
                                                              <i className="far fa-heart"/>
                                                              <span className="count-number">3</span>
                                                          </span>
                                                          <span>
                                                              <i className="far fa-comment"/>
                                                              <span className="count-number">0</span>
                                                          </span>
                                                      </div>
                                                  </a>
                                               </div>
                                               <div className="col-xl-4 col-lg-4 col-md-4 col-6 padding-left-0px padding-right-0px instafeed-style1">
                                                  <a href="https://www.instagram.com/p/BzkrNNDHgg5/" className="insta-link" target="_blank" rel="noopener noreferrer">
                                                     <img alt="imginsta" src="//scontent.cdninstagram.com/v/t51.2885-15/e35/65847320_1543005729163711_6969893945738952396_n.jpg?_nc_ht=scontent.cdninstagram.com&amp;_nc_ohc=qM-7n0M_FOYAX86IWN8&amp;oh=a3eef51ad425b6403b0dd228fbd5d67f&amp;oe=5EB32F3F" className="insta-image img-fluid w-100 h-100"/>
                                                      <div className="insta-counts">
                                                          <span className="margin-right-20px">
                                                              <i className="far fa-heart"/>
                                                              <span className="count-number">8</span>
                                                          </span>
                                                          <span>
                                                              <i className="far fa-comment"/>
                                                              <span className="count-number">0</span>
                                                          </span>
                                                      </div>
                                                  </a>
                                               </div>
                                               <div className="col-xl-4 col-lg-4 col-md-4 col-6 padding-left-0px padding-right-0px instafeed-style1">
                                                  <a href="https://www.instagram.com/p/BzkrMg_HAXv/" className="insta-link" target="_blank" rel="noopener noreferrer">
                                                     <img alt="imagecounts" src="//scontent.cdninstagram.com/v/t51.2885-15/e35/65628700_2450026505060808_2463245365529382414_n.jpg?_nc_ht=scontent.cdninstagram.com&amp;_nc_ohc=-Y9YGE22ArgAX9-guuF&amp;oh=6c37adeaf563eba51c0bd2b4f7c84bc6&amp;oe=5EAE3228" className="insta-image img-fluid w-100 h-100"/>
                                                      <div className="insta-counts">
                                                          <span className="margin-right-20px">
                                                              <i className="far fa-heart"/>
                                                              <span className="count-number">5</span>
                                                          </span>
                                                          <span>
                                                              <i className="far fa-comment"/>
                                                              <span className="count-number">0</span>
                                                          </span>
                                                      </div>
                                                  </a>
                                               </div>
                                               <div className="col-xl-4 col-lg-4 col-md-4 col-6 padding-left-0px padding-right-0px instafeed-style1">
                                                  <a href="https://www.instagram.com/p/BzkrL-MHVCg/" className="insta-link" target="_blank" rel="noopener noreferrer">
                                                     <img alt="imagecounts" src="//scontent.cdninstagram.com/v/t51.2885-15/e35/65716338_307137926836329_8923636533720042229_n.jpg?_nc_ht=scontent.cdninstagram.com&amp;_nc_ohc=hbs_FAx1mXEAX938MyG&amp;oh=b73a8745e9eb33ebcf1e5bcb98ab8898&amp;oe=5E9D1C14" className="insta-image img-fluid w-100 h-100"/>
                                                      <div className="insta-counts">
                                                          <span className="margin-right-20px">
                                                              <i className="far fa-heart"/>
                                                              <span className="count-number">2</span>
                                                          </span>
                                                          <span>
                                                              <i className="far fa-comment"/>
                                                              <span className="count-number">0</span>
                                                          </span>
                                                      </div>
                                                  </a>
                                               </div>
                                               <div className="col-xl-4 col-lg-4 col-md-4 col-6 padding-left-0px padding-right-0px instafeed-style1">
                                                  <a href="https://www.instagram.com/p/BzkrKWsnpmK/" className="insta-link" target="_blank" rel="noopener noreferrer">
                                                     <img alt="counst-insta" src="//scontent.cdninstagram.com/v/t51.2885-15/e35/66436305_849226582114203_6417109562522561497_n.jpg?_nc_ht=scontent.cdninstagram.com&amp;_nc_ohc=gn3Dr1oTlCAAX9Vd3cw&amp;oh=49939b22111fe9382ae1e96ceaa90767&amp;oe=5EB2D20B" className="insta-image img-fluid w-100 h-100"/>
                                                      <div className="insta-counts">
                                                          <span className="margin-right-20px">
                                                              <i className="far fa-heart"/>
                                                              <span className="count-number">7</span>
                                                          </span>
                                                          <span>
                                                              <i className="far fa-comment"/>
                                                              <span className="count-number">0</span>
                                                          </span>
                                                      </div>
                                                  </a>
                                               </div>
                                            </div>
                                        </div>
                                        {/* insta-feed image end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* best brands section starts */}

            </>
        )
    }
}

export default BestBrands;
