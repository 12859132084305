import React from "react";
import imageUtil from "../../../globalUtility/imageHelper";
import {Link} from "react-router-dom";
import BreadCrumb from "../../../GlobalComponents/BreadCrumb";
import BlogSocial from "../Common/BlogSocial";
import Comments from "../Common/Comments";
import BlogData from "../../../globalUtility/jsonData/blog/BlogDataList"
import titleUpperCase, {blogDetailComments} from "../../../globalUtility/commonHelper";

class BlogDetailsFull extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            relatedBlogs1: [],
            relatedBlogsDetail: {},
            blogIndex: '',
            blog: {
                id: 19,
                image: imageUtil.images.blogDetailMain,
                title: "What Is Art? An Insight About Profoundness Of Art!"
            },
            comment: 0
        }
    }

    componentWillMount() {
        const relatedBlogs = BlogData.relatedBlogs
        console.log(relatedBlogs)
        const {location} = this.props
        this.countComments()
        const data = (location && location.state && location.state.blog) || {}
        if (data && Object.keys(data).length) {
            this.setState({
                blog: data
            }, () => this.countComments())
        }
        this.setState({
            relatedBlogsDetail: relatedBlogs[0]
        })
    }

    countComments = () => {
        const {blog} = this.state
        const blogData = blogDetailComments(blog)
        this.setState({
            comment: (blogData && blogData.length) ? blogData.length : 0
        })
    }

    blogChange = (item,index) => {
        this.setState({
            relatedBlogsDetail: item,
            blogIndex:index
        })
    }

    render() {
        const {blog, relatedBlogsDetail,blogIndex, comment} = this.state
        const relatedBlogs = BlogData.relatedBlogs
        return(
            <>
                {/* breadcrumb section start */}
                <BreadCrumb heading="Blogs" subHeading="Blog Details Full"/>
                {/* breadcrumb section end */}
                <section className="blog-detailsstyle1 padding-80px-tb">
                    <div className="container-fluid">
                        <div className="row">
                            {/* blog image section starts */}
                            <div className="col-lg-12">
                                <img src={blog && blog.image} className="img-fluid border-radius-5" alt="img" />
                            </div>
                            {/* blog image section ends */}
                            <div className="col-lg-12">
                                {/* heading section starts */}
                                <div className="margin-top-30px margin-bottom-10px">
                                    <h4 className="d-inline-block font-weight-500 main-font title-medium lg-title-medium md-title-med-more2 sm-title-mdmore">{blog && blog.title}</h4>
                                </div>
                                {/* heading section ends */}
                                <div className="blog-detail-social">
                                    <ul className="blog-post-social">
                                        <li>25 Dec 2019</li>
                                        <li>Posted By: Admin</li>
                                        {/*<li><i className="far fa-heart" /> 5 Hits</li>*/}
                                        <li><i className="far fa-comments" /> {`${comment} Comment`} </li>
                                    </ul>
                                </div>
                                {/* hash tags section starts */}
                                <div className="tags font-weight-500 sm-display-block display-inline-block text-medium sm-margin-top-10px sm-text-large">
                                    <div className="sm-margin-left-none display-inline-block hash-content text-black">#Art</div>
                                    <div className="margin-left-5px display-inline-block sm-margin-left-none hash-content text-black">#Splash</div>
                                    <div className="margin-left-5px display-inline-block sm-margin-left-none hash-content text-black">#Holi</div>
                                    <div className="margin-left-5px display-inline-block sm-margin-left-none hash-content text-black">#Color</div>
                                    <div className="margin-left-5px display-inline-block sm-margin-left-none hash-content text-black">#Beauty</div>
                                </div>
                                {/* hash tags section ends */}
                                {/* description starts */}
                                <div className="margin-top-20px">
                                    <p className="font-weight-600 mb-0 sm-text-large"><i className="fas fa-quote-left text-sky-blue padding-right-5px" />It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.<i className="fas fa-quote-right text-sky-blue padding-left-5px" /></p>
                                </div>
                                {/* description ends */}
                                {/* blog texts starts */}
                                <p className="margin-top-15px sm-text-large sm-margin-top-10px">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p>
                                <p className="no-margin-bottom sm-text-large">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.
                                    <span className="blockquote-footer font-weight-600 margin-top-10px">From Jd Kelin</span>
                                </p>
                                {/* blog texts ends */}
                                <div className="border-top margin-top-20px margin-bottom-20px" />
                                {/* share post section starts */}
                                <div className="blog-social-share">
                                    {/* blog details heading starts */}
                                    <div className="padding-bottom-15px">
                                        <h5 className="d-inline-block border-bottom-khaki font-weight-600 main-font text-extra-large md-title-mdall sm-text-extra-large">Share This Post</h5>
                                    </div>
                                    {/* blog details heading ends */}
                                    {/* social icon start */}
                                        <BlogSocial/>
                                    {/* social icon ends */}
                                </div>
                                {/* share post blog ends */}
                                <div className="border-top margin-top-20px margin-bottom-20px" />
                                {/* related blogs starts */}
                                <div className="padding-bottom-15px">
                                    <h5 className="d-inline-block border-bottom-khaki font-weight-600 main-font text-extra-large md-title-mdall sm-text-extra-large">Related Blogs!</h5>
                                </div>
                                {/* blogs starts */}
                                <ul className="nav nav-tabs related-box-tabs">
                                    {
                                        relatedBlogs.map((items, index)=>{
                                            return(
                                                <li key={index} className={`nav-item ${(!blogIndex && index === 0) ? 'active show' : ''}`}>
                                                    <Link to="#" onClick={() => this.blogChange(items,index)} data-target="#home1" data-toggle="tab" className="nav-link font-weight-600">Blog {index+1}</Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                {/* blogs ends */}
                                <br />
                                <div id="tabsJustifiedContent" className="tab-content">
                                    {/* blog1 starts */}
                                    {
                                        blogIndex && blogIndex % 2 !==0 ? (
                                            <div className="row">
                                                <div className="col-md-8 d-flex align-items-center">
                                                    <div className="tab-info-details sm-padding-top-10px">
                                                        <h6 className="font-weight-600 text-large md-text-large sm-text-extra-large">
                                                            {titleUpperCase(relatedBlogsDetail.title)}
                                                        </h6>
                                                        <p className="pt-2 sm-text-large">
                                                            {titleUpperCase(relatedBlogsDetail.description)}
                                                        </p>
                                                        <Link to={{ pathname: '/blogs/blogdetails-left-sidebar-full', state: { blog:{image: relatedBlogsDetail.image, title: titleUpperCase(relatedBlogsDetail.title)} } }}
                                                              className="btn btn-style1 bg-sky-blue-light hoverstyle1 text-white">View Details</Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 sm-margin-top-20px mt-md-0">
                                                    {/* related image starts */}
                                                    <img src={relatedBlogsDetail.image} className="img-fluid border-radius-5" alt={relatedBlogsDetail.image} />
                                                    {/* related image ends */}
                                                </div>
                                            </div>
                                        ) : (
                                            <div id="profile1" className="">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        {/* related image starts */}
                                                        <img src={relatedBlogsDetail.image} className="float-right img-fluid border-radius-5" alt="" />
                                                        {/* related image ends */}
                                                    </div>
                                                    <div className="col-md-8 d-flex align-items-center">
                                                        <div className="tab-info-details sm-padding-top-10px">
                                                            <h6 className="font-weight-600 text-large md-text-large sm-text-extra-large">
                                                                {titleUpperCase(relatedBlogsDetail.title)}
                                                            </h6>
                                                            <p className="pt-2 sm-text-large">
                                                                {titleUpperCase(relatedBlogsDetail.description)}
                                                            </p>
                                                            <Link to={{ pathname: '/blogs/blogdetails-left-sidebar-full', state: { blog:{image: relatedBlogsDetail.image, title: relatedBlogsDetail.title} } }}
                                                                  className="btn btn-style1 bg-sky-blue-light hoverstyle1 text-white">View Details</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {/* blog1 ends */}
                                </div>
                                {/* related blogs ends */}
                                <Comments blog={blog}/>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default BlogDetailsFull
