import React from "react";
import BreadCrumb from "../GlobalComponents/BreadCrumb";
import {Link} from "react-router-dom";
import PageDescription from "../globalUtility/jsonData/description/PageDescription"

class SiteMap extends React.Component {
    render() {
        const siteMapData = (PageDescription && PageDescription.siteMapData) || []
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Home" subHeading="Site Map"/>
                {/* breadcrumb section end */}
                <section className="padding-80px-tb site-map">
                    <div className="container">
                        <div className="row">
                                {
                                    siteMapData && siteMapData.map((item, index) => {
                                        return(
                                            <div className="col-sm-4">
                                                <ul key={index}>
                                                <li className="text-sky-blue">
                                                    {/* products starts */}
                                                    <Link to="#" className="font-weight-600 text-black hoverstyle1 text-extra-large md-title-mdall sm-text-extra-large">
                                                        {item.title}
                                                    </Link>
                                                    {/* products ends */}
                                                    <ul className="pl-2">
                                                        {/* products subcontent starts */}
                                                        {
                                                            item && item.details.map((val, i) => {
                                                                return(
                                                                    <li key={i} className="list-unstyled">
                                                                        <Link to={val.path} className="text-black text-medium hoverstyle1 sm-text-large">
                                                                            {val.title}
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </li>
                                            </ul>
                                            </div>
                                        )
                                    })
                                }
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default SiteMap
