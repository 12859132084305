import {
    ADD_TO_CART_PRODUCT, END_ADD_TO_CART,
} from '../constants/actionTypes';

const initialState = {
    cartList: [],
    countAddToCart: 0
}

const cartReducer = (state = initialState , action) => {
    switch (action.type) {
        case ADD_TO_CART_PRODUCT: {
            return {
                ...state,
                cartList: (action.allCart) || [],
                countAddToCart: state.countAddToCart + 1
            }
        }
        case END_ADD_TO_CART: {
            return {
                ...state,
                errorMsg: action.errorMsg,
                countAddToCart: 0
            }
        }
        default:
            return state
    }
}
export default cartReducer
