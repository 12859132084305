import React from "react"
import imageUtil from "../globalUtility/imageHelper";
import { Link } from "react-router-dom";
const images = imageUtil.images

const BreadCrumb = ({heading, subHeading}) => {
    return (
        <section className="blog-detailsstyle1 breadcrumb-img position-relative cover-background height-300-px" style={{backgroundImage: `url(${images.BreadCrumb})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="breadcrumb-text text-center left-0 right-0">
                            {/* breadcrumb title starts */}
                            <div className="d-inline-block">
                                <Link className="alt-font font-weight-600 text-extra-large border-bottom-khaki text-black2 md-title-mdall sm-text-extra-large" to="#">{heading}</Link>
                                <i className="fas fa-arrow-circle-right text-black2 text-extra-large md-title-mdall sm-text-extra-large" />
                            </div>
                            {/* breadcrumb title ends */}
                            {/* breadcrumb path starts */}
                            <div className="d-inline-block">
                                <h5 className="font-weight-600 text-extra-large text-sky-blue md-text-large sm-text-large">{subHeading}</h5>
                            </div>
                            {/* breadcrumb path ends */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BreadCrumb
