import React from "react";
import imageUtil from "../globalUtility/imageHelper";
import BreadCrumb from "../GlobalComponents/BreadCrumb";
import { Link } from "react-router-dom";

class SignOut extends React.Component {
    render() {
        const images = imageUtil.images
        return(
            <>
                {/* breadcrumb section start */}
                <BreadCrumb heading="Pages" subHeading="Sign Out"/>
                {/* breadcrumb section end */}
                {/* sign out section starts */}
                <section className="padding-80px-tb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12 center-col sing-out-content border-radius-5">
                                <div className="sign-out-con">
                                    <div className="sing-out">
                                        {/* member image starts */}
                                        <img src={images.signOut} alt="sign out" />
                                        {/* member image ends */}
                                        <div className="d-inline-flex flex-column">
                                            <h6 className="d-inline-block font-weight-600 title-small lg-text-extra-large md-title-mdall sm-text-extra-large">
                                                Denzel Love
                                            </h6>
                                            <Link to="/sign-out" className="d-inline-block text-gray hoverstyle1 sm-text-large">
                                                Manage Account
                                            </Link>
                                        </div>
                                    </div>
                                    {/* sign-out icon starts */}
                                    <Link to="/" className="d-inline-block hoverstyle1 sm-text-large">
                                        <i className="fas fa-power-off" />Sign-out
                                    </Link>
                                    {/* sign-out icon ends */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* sign out section ends */}
            </>
        )
    }
}

export default SignOut
