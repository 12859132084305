import React from "react";
import {SketchPicker} from "react-color";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

class WebSetting extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            rtl: false,
            box: false,
            theme: false,
            color: '#13b5ea',
            oldColor: '#13b5ea',
            handleColorPicker: false,
            disableAlpha: true,
            totalProductCompare: 0,
        }
    }

    componentDidMount() {
        let body = document.body;
        let data = localStorage.getItem("data");
        const compareListData = JSON.parse(localStorage.getItem("compareList")) || []
        let commonData = JSON.parse(data) || {}
        let webSetting = (commonData && commonData.data && commonData.data.webSetting) || {}

        this.setState({
            rtl: (webSetting && webSetting.rtl) || false,
            box: (webSetting && webSetting.box) || false,
            theme: (webSetting && webSetting.theme) || false,
            color: (webSetting && webSetting.color) || "#13b5ea",
            oldColor: (webSetting && webSetting.color) || "#13b5ea",
            totalProductCompare: compareListData.length || 0
        })

        if(webSetting && webSetting.rtl){
            body.classList.add("rtl");
        }

        if(webSetting && webSetting.box){
            body.classList.add("box-content");
        }

        if(webSetting && webSetting.theme){
            body.classList.add("dark-theme");
        }

        if(webSetting && webSetting.color){
            this.handelColor(webSetting.color, true)
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { productCompareList } = nextProps
        const { totalProductCompare } = this.state

        if(productCompareList.length !== totalProductCompare){
            this.setState({
                totalProductCompare: productCompareList.length || 0
            })
        }
    }

    handleOnReset = () => {
        let body = document.body;
        let data = localStorage.getItem("data");
        let dataItem = JSON.parse(data) || {}
        body.classList.remove('rtl');
        body.classList.remove('box-content');
        body.classList.remove('dark-theme');

        const settings = {
            "rtl": false,
            "box": false,
            "theme": false,
            "color": "#13b5ea"
        }

        dataItem = {
            data: {
                comments: dataItem.data.comments,
                webSetting: settings
            }
        }
        localStorage.setItem('data', JSON.stringify(dataItem));
        this.setState({
            rtl: false,
            box: false,
            theme: false,
            color: "#13b5ea"
        },()=>{
            this.handelColor("#13b5ea",true)
        })
    }

    handleOnChange = (event) =>{
        let body = document.body;
        const target = event.target;
        let checked = target.type === 'checkbox' ? target.checked : target.value
        let data = localStorage.getItem("data");
        let dataItem = JSON.parse(data) || {}
        let myObj = {
            data: {
                ...dataItem.data,
                webSetting: {
                    ...dataItem.data && dataItem.data.webSetting,
                    [target.name]: checked
                }
            }
        };

        const webSetting = (myObj.data && myObj.data.webSetting)
        if(webSetting.rtl){
            body.classList.add("rtl");
        }else if(!webSetting.rtl){
            body.classList.remove('rtl');
        }

        if(webSetting.box){
            body.classList.add("box-content");
        }else if(!webSetting.box){
            body.classList.remove('box-content');
        }

        if(webSetting.theme){
            body.classList.add("dark-theme");
        }else if(!webSetting.theme){
            body.classList.remove('dark-theme');
        }
        localStorage.setItem('data', JSON.stringify(myObj));
        this.setState({
            [target.name]: checked
        })
    }

    handleChangeComplete = () => {
        const {handleColorPicker, color} = this.state
        this.setState({
            handleColorPicker: !handleColorPicker
        },()=>(this.handelColor(color, !handleColorPicker)));
    };

    handelColor = (colorName, isStoreColor) =>{
        let data = localStorage.getItem("data");
        let dataItem = JSON.parse(data) || {}
        let myObj = {
            data: {
                ...dataItem.data,
                webSetting: {
                    ...dataItem.data && dataItem.data.webSetting,
                    color: colorName
                }
            }
        };
        const webSetting = (myObj.data && myObj.data.webSetting)

        let style = document.createElement('style');

        let hoverStyle1 = `a:hover, 
                           .header-style1 .dropdown-menu li a:hover, 
                           .hoverstyle1:hover,
                           .text-sky-blue,
                            .nav-pills-style-1 .nav-link.active,
                            .header-sticky .wishlist:hover,
                            .header-sticky .search:focus,
                            .header-sticky .bagtext:hover,
                            .popup-style1 .close:hover{ color: ${webSetting.color} !important;}`;
        style.appendChild(document.createTextNode(hoverStyle1));
        document.getElementsByTagName('head')[0].appendChild(style);

        let textColor = `.service-box i,
                        .content-overlay .content ul li span,
                        .slider-style2 .slidertext .slider-title span:nth-child(2),
                        .slider-style2 .sw-ar .swiper-pagination .swiper-pagination-current,
                        .qty-input-style1 .more:hover,
                        .qty-input-style1 .less:hover,
                        .page-not-found .not-found-bottom-inner h1,
                        .hash-content:hover{ color: ${webSetting.color} }`;
        style.appendChild(document.createTextNode(textColor));
        document.getElementsByTagName('head')[0].appendChild(style);

        let arrowColor = `.slider-style2 .sw-ar .prev, .slider-style2 .sw-ar .next{ color: ${webSetting.color} }`;
        style.appendChild(document.createTextNode(arrowColor));
        document.getElementsByTagName('head')[0].appendChild(style);

        let style1 = `.btn-style1, a.small-btn{ border: 2px solid ${webSetting.color}; background-color: ${webSetting.color} }`;
        style.appendChild(document.createTextNode(style1));
        document.getElementsByTagName('head')[0].appendChild(style);

        let borderBottomStyle = `.border-bottom-khaki{ border-bottom: 1px solid ${webSetting.color};}`;
        style.appendChild(document.createTextNode(borderBottomStyle));
        document.getElementsByTagName('head')[0].appendChild(style);

        let borderStyle = `.view-mode a.active, 
                           .view-mode a:hover,
                           .pagination-style .pagination ul li button.active,
                           .pagination-style .pagination ul li button:hover{ border: 1px solid ${webSetting.color};}`;
        style.appendChild(document.createTextNode(borderStyle));
        document.getElementsByTagName('head')[0].appendChild(style);

        let borderTab = `.nav-pills-style-1 .nav-link.active{ border-bottom: 2px solid ${webSetting.color};}`;
        style.appendChild(document.createTextNode(borderTab));
        document.getElementsByTagName('head')[0].appendChild(style);

        let bgStyle = `.bg-sky-blue-light, 
                       .content-overlay .content ul li span:hover,
                       .header-sticky .navbar-toggler .line,
                       .navbar-toggler .line,
                        .best-images .shop-now-btn .s-now:hover,
                        .pagination-style .pagination ul li button.active,
                        .pagination-style .pagination ul li button:hover,
                        .ship-before::before,
                        .ord-rec-after:after,
                        .o4d-before:before,
                        .o4d-before:after,
                        .ship-after:after,
                        .blog-detailsstyle1 ul.blog-details-social li a:hover,
                        .left-sidebar .sidebar-content #recent-blog .owl-dots .owl-dot.active span,
                        .product-comparision .over-view .over-view-info .close-product,
                        .product-comparision .over-view .over-view-info .size .size-opt a,
                        .no-pro-available,
                        .cm-compare-button a.cm-compare-icon,
                        .cm-compare-button a span{ background-color: ${webSetting.color} }`;
        style.appendChild(document.createTextNode(bgStyle));
        document.getElementsByTagName('head')[0].appendChild(style);

        let bgStyleImp = `.view-mode a.active, 
                          .view-mode a:hover, .Toastify__toast--success { background-color: ${webSetting.color} !important; }`;
        style.appendChild(document.createTextNode(bgStyleImp));
        document.getElementsByTagName('head')[0].appendChild(style);

        let backStyle = `.slider-style1 .owl-theme .owl-dots .owl-dot.active span,
                         .slider-style1 .owl-theme .owl-dots .owl-dot:hover span,
                         .blog-detailsstyle1 .owl-theme .owl-dots .owl-dot.active span,
                         .blog-detailsstyle1 .owl-theme .owl-dots .owl-dot:hover span{ background: ${webSetting.color} }`;
        style.appendChild(document.createTextNode(backStyle));
        document.getElementsByTagName('head')[0].appendChild(style);

        let bgStyleOp = `.timer-style1 .counter-box{ background-color: ${webSetting.color}; opacity: 0.5 }`;
        style.appendChild(document.createTextNode(bgStyleOp));
        document.getElementsByTagName('head')[0].appendChild(style);

        let btnStyle1 = `.btn-style1:hover,
                          a.small-btn:hover{ color: ${webSetting.color} !important; border: 2px solid ${webSetting.color}; background-color: transparent !important; }`;
        style.appendChild(document.createTextNode(btnStyle1));
        document.getElementsByTagName('head')[0].appendChild(style);

        if(!isStoreColor){
            localStorage.setItem('data', JSON.stringify(myObj));
        }
    }

    handleClose = (color) => {
        this.setState({ color: color.hex})
    }

    handleToolBox = () => {
        const color = JSON.parse(localStorage.getItem('data'))
        let activeColor = (color && color.data && color.data.webSetting && color.data.webSetting.color) || this.state.oldColor
        this.setState({
            handleColorPicker: false,
            color: activeColor
        })
    }

    render() {
        const {rtl, box, theme, handleColorPicker, color, totalProductCompare} = this.state
        return(
            <div>
                <div className="cm-compare-button">
                    <Link to="/products/product-comparision" className="cm-compare-icon">
                        <i className="fas fa-balance-scale"/>
                        <span className="cm-compare-counter">{totalProductCompare}</span>
                    </Link>
                </div>
                <div className="web-tool">
                    <div className="web-settting">
                        <button className="btn-setting" onClick={() => this.handleToolBox()}>
                            <i className="fas fa-cog"/>
                        </button>
                        <div className="bg-color">
                            <div className="switch-wrap" >
                                <h2>
                                    <span>RTL</span>
                                    <i className="fas fa-arrows-alt-h"/>
                                </h2>
                                <label className="switch switch-flat">
                                    <input
                                        name="rtl"
                                        checked={rtl}
                                        onChange={this.handleOnChange}
                                        className="switch-input"
                                        id="rtl-support"
                                        type="checkbox" />
                                    <span className="switch-label" data-on="On" data-off="Off"/>
                                    <span className="switch-handle"/>
                                </label>
                            </div>
                            <div className="switch-wrap">
                                <h2>
                                    <span>Box</span>
                                    <i className="fas fa-square-full"/>
                                </h2>
                                <label className="switch switch-flat">
                                    <input
                                        name="box"
                                        checked={box}
                                        onChange={this.handleOnChange}
                                        className="switch-input"
                                        id="box-support"
                                        type="checkbox" />
                                    <span className="switch-label" data-on="On" data-off="Off"/>
                                    <span className="switch-handle"/>
                                </label>
                            </div>
                            <div className="switch-wrap">
                                <h2>
                                    <span>Dark</span>
                                    <i className="fas fa-adjust"/>
                                </h2>
                                <label className="switch switch-flat">
                                    <input
                                        name="theme"
                                        checked={theme}
                                        onChange={this.handleOnChange}
                                        id="dark-support"
                                        type="checkbox"
                                        className="switch-input" />
                                    <span className="switch-label" data-on="On" data-off="Off"/>
                                    <span className="switch-handle"/>
                                </label>
                            </div>
                            <div className="switch-wrap">
                                <h2>
                                    <span>Color</span>
                                    <i className="fas fa-palette"/>
                                </h2>
                                <label className="color-select">
                                    <button
                                        style={{backgroundColor: color}}
                                        name="button"
                                        onClick={this.handleChangeComplete}
                                        className="btn btn-color">
                                        {handleColorPicker ? "Apply" : "Choose"}
                                    </button>
                                </label>
                            </div>
                            {
                                handleColorPicker &&
                                <div className="color-picker">
                                    <SketchPicker
                                        disableAlpha={this.state.disableAlpha}
                                        color={color}
                                        onChangeComplete={this.handleClose}
                                    />
                                </div>
                            }
                            <div className="reset-change">
                                <button
                                    name="button"
                                    onClick={this.handleOnReset}
                                    className=" btn btn-reset">Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    productCompareList: (state.productCompareReducer && state.productCompareReducer.productCompareList) || [],
})

export default withRouter(connect(
    mapStateToProps,
    null
)(WebSetting))
