import React from "react"
import BreadCrumb from "../../GlobalComponents/BreadCrumb"
import {toast} from "react-toastify"
import {addItemToCart} from "../../actions/cartAction";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Rating from "./Common/Rating";
import {handelCompareProduct} from "../../actions/productCompareAction";
import titleUpperCase from "../../globalUtility/commonHelper";

class ProductComparision extends React.Component{
    state = {
        compareList: []
    }

    componentWillMount(){
        let compare = localStorage.getItem("compareList")
        let compareListData = JSON.parse(compare) || []
        this.setState({
            compareList: compareListData,
        })
    }

    onRemove = (index) => {
        const { compareList} = this.state
        compareList.splice(index, 1)
        localStorage.setItem("compareList" , JSON.stringify(compareList))
        this.props.removeToCompareProduct(compareList)
        toast.error(`Product Removed Successfully`, {
            position: toast.POSITION.TOP_RIGHT
        })
        this.setState({
            compareList
        })
    }

    render() {
        const {compareList} = this.state
        return(
            <div>
                <BreadCrumb heading="Products" subHeading="Product Comparision"/>
                <section className="padding-80px-tb product-comparision">
                    <div className="container">
                        <div className="row">
                            {
                                compareList.map((item, index) =>{
                                    return(
                                        <div key={index} className="col-md-4 col-12 pro-com">
                                            <div className="over-view">
                                                <div className="over-view-info">
                                                    <div className="com-image">
                                                        <img src={item.pictures[0]} className="img-fluid" alt=""/>
                                                    </div>
                                                    <a href="#" className="close-product" onClick={() => this.onRemove(index)}>
                                                        <i className="fas fa-times"/>
                                                    </a>
                                                    <div className="pro-com-add-to-cart">
                                                        <span onClick={() => this.props.addToCart(item)} className="btn btn-style1">Add To Cart</span>
                                                    </div>
                                                </div>
                                                <div className="over-view-info">
                                                    <h6>Brand</h6>
                                                    <span>{(item.brandName) || '-'}</span>
                                                </div>
                                                <div className="over-view-info">
                                                    <h6>Name</h6>
                                                    <span>{item.productTitle}</span>
                                                </div>
                                                <div className="over-view-info">
                                                    <h6>Price</h6>
                                                    <span>${item.salePrice && item.salePrice.toFixed(2)}</span>
                                                </div>
                                                <div className="over-view-info">
                                                    <h6>Rating</h6>
                                                    <Rating ratingStars={item.rating}/>
                                                </div>
                                                <div className="over-view-info">
                                                    <h6>Detail</h6>
                                                    <span className="pro-desc-detail">{titleUpperCase(item.description || "")}</span>
                                                </div>
                                                <div className="over-view-info">
                                                    <h6>Colors</h6>
                                                    <span className="pro-com-color">
                                                        <ul>
                                                            {
                                                                item.colors.length > 0 ? item.colors.map((item, index) =>(
                                                                    <li key={index}>
                                                                        <a style={{backgroundColor: item}} className="bg-pink"/>
                                                                    </li>
                                                                )) : "-"
                                                            }
                                                        </ul>
                                                    </span>
                                                </div>
                                                <div className="over-view-info">
                                                    <h6>Size</h6>
                                                    <span className="size">
                                                        {
                                                            item.size.length > 0 ? item.size.map((item, index) =>
                                                                <span className="size-opt" key={index}>
                                                                    <a href="">{item}</a>
                                                                </span>
                                                            ) : "-"
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            compareList.length <= 0 &&
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="no-pro-available">
                                        <p className="alert-msg">There are no product for comparision</p>
                                    </div>
                                    <div className="go-link">
                                        <Link to="/products/grids-page" className="btn btn-style1">Go to product</Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item)),
    removeToCompareProduct: (data) => dispatch(handelCompareProduct(data))
})

export default connect(
    null,
    mapDispatchToProps
)(ProductComparision)
