import imageUtil from "../imageHelper";

const images = imageUtil.images

const HomeDataList = {
    sliderDataList: [
        {
            offerTitle: "Special Offers",
            image: images.sliderImage2,
            redirectUrl: "/product/37",
            discountBeforeText: "This Diwali Get Upto",
            discountAfterText: "On Headphones",
            discount: 50,
        },
        {
            offerTitle: "Special Offers",
            image: images.sliderImage1,
            redirectUrl: "/category/footwear",
            discountBeforeText: "All Shoes Flat",
            discountAfterText: "Off On Christmas",
            discount: 10,
        },
        {
            offerTitle: "Special Offers",
            image: images.sliderImage3,
            redirectUrl: "/category/clothes",
            discountBeforeText: "All Clothes Flat",
            discountAfterText: "Off On Christmas",
            discount: 10
        },
    ],
    sliderDataListHome1: [
        {
            offerTitle: "Special Offers",
            image: images.sliderImage4,
            redirectUrl: "/product/37",
            discountBeforeText: "This Diwali Get Upto",
            discountAfterText: "On Headphones",
            discount: 50,
        },
        {
            offerTitle: "Special Offers",
            image: images.sliderImage5,
            redirectUrl: "/category/footwear",
            discountBeforeText: "All Shoes Flat",
            discountAfterText: "Off On Christmas",
            discount: 10,
        },
        {
            offerTitle: "Special Offers",
            image: images.sliderImage6,
            redirectUrl: "/category/clothes",
            discountBeforeText: "All Clothes Flat",
            discountAfterText: "Off On Christmas",
            discount: 10
        },
    ]
}
export default HomeDataList;
