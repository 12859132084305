import React from "react"

const BlogSocial = () => {
    return (
        <ul className="blog-details-social no-margin-bottom display-inline-block text-left no-padding-left">
            <li>
                <a href="http://facebook.com" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f" />
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram" />
                </a>
            </li>
            <li>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter" />
                </a>
            </li>
            <li>
                <a href="https://in.pinterest.com" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-pinterest-p" />
                </a>
            </li>
        </ul>
    )
}

export default BlogSocial;
