import BlogDetails from "../Components/Blogs/BlogDetails/BlogDetails";
import BlogDetailsFull from "../Components/Blogs/BlogDetails/BlogDetailsFull";
import BlogDetailsLeftSidebar from "../Components/Blogs/BlogDetails/BlogDetailsLeftSidebar";
import BlogDetailsLeftSidebarFull from "../Components/Blogs/BlogDetails/BlogDetailsLeftSidebarFull";
import BlogDetailsRightSidebar from "../Components/Blogs/BlogDetails/BlogDetailsRightSidebar";
import BlogDetailsRightSidebarFull from "../Components/Blogs/BlogDetails/BlogDetailsRightSidebarFull";
import Blogs from "../Components/Blogs/Blogs/Blogs";
import BlogsFull from "../Components/Blogs/Blogs/BlogsFull";
import BlogsLeftSidebar from "../Components/Blogs/Blogs/BlogsLeftSidebar";
import BlogsLeftSidebarFull from "../Components/Blogs/Blogs/BlogsLeftSidebarFull";
import BlogsRightSidebar from "../Components/Blogs/Blogs/BlogsRightSidebar";
import BlogsRightSidebarFull from "../Components/Blogs/Blogs/BlogsRightSidebarFull";


import ProductComparision from "../Components/Product/ProductComparision";
import ProductDetails from "../Components/Product/ProductDetails";
import GridsPage from "../Components/Product/GridsPages/GridsPage";
import GridsLeftSidebar from "../Components/Product/GridsPages/GridsLeftSidebar";
import GridsRightSidebar from "../Components/Product/GridsPages/GridsRightSidebar";
import ListPage from "../Components/Product/ListPages/ListPage";
import ListPageLeftSidebar from "../Components/Product/ListPages/ListPageLeftSidebar";
import ListPageRightSidebar from "../Components/Product/ListPages/ListPageRightSidebar";

const RoutesList = {
    blogsRoutes: [
        {
            exact: true,
            path: "/blogs",
            component: Blogs
        },
        {
            path: "/blogs/blogs",
            component: Blogs
        },
        {
            path: "/blogs/blog-details",
            component: BlogDetails
        },
        {
            path: "/blogs/blog-details-full",
            component: BlogDetailsFull
        },
        {
            path: "/blogs/blogdetails-left-sidebar",
            component: BlogDetailsLeftSidebar
        },
        {
            path: "/blogs/blogdetails-right-sidebar",
            component: BlogDetailsRightSidebar
        },
        {
            path: "/blogs/blogdetails-left-sidebar-full",
            component: BlogDetailsLeftSidebarFull
        },
        {
            path: "/blogs/blogdetails-right-sidebar-full",
            component: BlogDetailsRightSidebarFull
        },
        {
            path: "/blogs/blogs-full",
            component: BlogsFull
        },
        {
            path: "/blogs/blogs-left-sidebar",
            component: BlogsLeftSidebar
        },
        {
            path: "/blogs/blogs-left-sidebar-full",
            component: BlogsLeftSidebarFull
        },
        {
            path: "/blogs/blogs-right-sidebar",
            component: BlogsRightSidebar
        },
        {
            path: "/blogs/blogs-right-sidebar-full",
            component: BlogsRightSidebarFull
        },
    ],
    productsRoutes: [
        {
            exact: true,
            path: "/products",
            component: GridsPage
        },
        {
            path: "/product/:id",
            component: ProductDetails
        },
        {
            path: "/products/product-comparision",
            component: ProductComparision
        },
        {
            path: "/products/grids-page",
            component: GridsPage
        },
        {
            path: "/products/grids-page-full",
            component: GridsPage
        },
        {
            path: "/products/grids-left-sidebar",
            component: GridsLeftSidebar
        },
        {
            path: "/products/grids-left-sidebar-full",
            component: GridsLeftSidebar
        },
        {
            path: "/products/grids-right-sidebar",
            component: GridsRightSidebar
        },
        {
            path: "/products/grids-right-sidebar-full",
            component: GridsRightSidebar
        },
        {
            path: "/products/list-page",
            component: ListPage
        },
        {
            path: "/products/list-page-full",
            component: ListPage
        },
        {
            path: "/products/list-page-left-sidebar",
            component: ListPageLeftSidebar
        },
        {
            path: "/products/list-page-left-sidebar-full",
            component: ListPageLeftSidebar
        },
        {
            path: "/products/list-page-right-sidebar",
            component: ListPageRightSidebar
        },
        {
            path: "/products/list-page-right-sidebar-full",
            component: ListPageRightSidebar
        },
    ]
}
export default RoutesList;
