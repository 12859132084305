import React from "react";
import BreadCrumb from "../GlobalComponents/BreadCrumb";
import PageDescription from "../globalUtility/jsonData/description/PageDescription"


class FrequentlyAskedQuestions extends React.Component {
    render() {
        const faqs = (PageDescription && PageDescription.faqs) || []
        return(
            <>
                {/* breadcrumb section start */}
                    <BreadCrumb heading="Home" subHeading="Faq's"/>
                {/* breadcrumb section end */}
                <section className="padding-80px-tb">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-5 col-10 center-col text-center margin-bottom-40px lg-margin-bottom-30px md-margin-bottom-25px sm-margin-bottom-20px">
                                {/* help starts */}
                                <h6 className="text-center display-inline-block border-bottom-khaki font-weight-600 text-extra-large lg-text-extra-large md-title-mdall sm-text-extra-large">How Can We Help You?</h6>
                                <div className="input-group margin-top-15px">
                                    <input type="text" className="form-control padding-four border-radius-5 text-extra-medium" name="search" placeholder="Describe your issue" />
                                    <span className="input-group-btn margin-left-5px">
                                    <button className="btn btn-default bg-sky-blue-light border-radius-5" type="button"><i className="fas fa-search text-white" /></button>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 panel-group faq-content">
                                {/* question 1 starts */}
                                <div className="panel padding-10px border-radius-5 position-relative xs-padding-5px" id="accordion4">
                                    <h6 className="panel-title text-large sm-text-large">
                                        <a data-toggle="collapse" data-parent="#accordion4" href="#collapse4" className="text-black collapsed" aria-expanded="false">{faqs[0].title}
                                            {/* plus icon starts */}
                                            <i className="fas fa-plus-circle" />
                                            {/* plus icon ends */}
                                        </a>
                                    </h6>
                                    <div id="collapse4" className="panel-collapse in collapse bg-white border-radius-5 sm-no-letter-spacing">
                                        <div className="padding-5px margin-left-10px bg-sky-blue-light text-white border-radius-5 d-inline-block sm-text-large">Ans.</div>
                                        <p className="margin-left-10px padding-top-10px padding-bottom-10px font-weight-500 no-margin-bottom sm-text-large">{faqs[0].description}</p>
                                    </div>
                                </div>
                                {/* question 1 ends */}
                                {/* question 2 starts */}
                                <div className="panel border-radius-5 padding-10px margin-top-15px position-relative xs-padding-5px" id="accordion5">
                                    <h6 className="panel-title text-large sm-text-large">
                                        <a data-toggle="collapse" data-parent="#accordion5" href="#collapse5" className="text-black collapsed" aria-expanded="false">{faqs[1].title}
                                            {/* plus icon starts */}
                                            <i className="fas fa-plus-circle" />
                                            {/* plus icon ends */}
                                        </a>
                                    </h6>
                                    <div id="collapse5" className="panel-collapse in collapse bg-white border-radius-5 margin-top-5px sm-no-letter-spacing">
                                        <div className="padding-5px margin-left-10px bg-sky-blue-light text-white border-radius-5 d-inline-block sm-text-large">Ans.</div>
                                        <p className="margin-left-10px padding-bottom-10px font-weight-500 no-margin-bottom sm-text-large">{faqs[1].description}</p>
                                    </div>
                                </div>
                                {/* question 2 ends */}
                                {/* question 3 starts */}
                                <div className="panel border-radius-5 padding-10px margin-top-15px position-relative xs-padding-5px" id="accordion6">
                                    <h6 className="panel-title text-large sm-text-large">
                                        <a data-toggle="collapse" data-parent="#accordion6" href="#collapse6" className="text-black collapsed" aria-expanded="false">{faqs[2].title}
                                            {/* plus icon starts */}
                                            <i className="fas fa-plus-circle" />
                                            {/* plus icon ends */}
                                        </a>
                                    </h6>
                                    <div id="collapse6" className="panel-collapse in collapse bg-white border-radius-5 margin-top-5px sm-no-letter-spacing">
                                        <div className="padding-5px margin-left-10px bg-sky-blue-light text-white border-radius-5 d-inline-block sm-text-large">Ans.</div>
                                        <p className="margin-left-10px padding-bottom-10px font-weight-500 no-margin-bottom sm-text-large">{faqs[2].description}</p>
                                    </div>
                                </div>
                                {/* question 3 ends */}
                                {/* question 4 starts */}
                                <div className="panel border-radius-5 padding-10px margin-top-15px position-relative xs-padding-5px" id="accordion7">
                                    <h6 className="panel-title text-large sm-text-large">
                                        <a data-toggle="collapse" data-parent="#accordion7" href="#collapse7" className="text-black collapsed" aria-expanded="false">{faqs[3].title}
                                            {/* plus icon starts */}
                                            <i className="fas fa-plus-circle" />
                                            {/* plus icon ends */}
                                        </a>
                                    </h6>
                                    <div id="collapse7" className="panel-collapse in collapse bg-white border-radius-5 margin-top-5px sm-no-letter-spacing">
                                        <div className="padding-5px margin-left-10px bg-sky-blue-light text-white border-radius-5 d-inline-block sm-text-large">Ans.</div>
                                        <p className="margin-left-10px padding-bottom-10px font-weight-500 no-margin-bottom sm-text-large">{faqs[3].description}</p>
                                    </div>
                                </div>
                                {/* question 4 ends */}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default FrequentlyAskedQuestions
