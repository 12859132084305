import React from "react"
import imageUtil from "../../../globalUtility/imageHelper"
import moment from "moment";
import titleUpperCase from "../../../globalUtility/commonHelper";
const images = imageUtil.images

class Comments extends React.Component{
    state = {
        name: "",
        email: "",
        description: "",
        reply: false,
        commentList: [],
        commentError: {},
        replyError: {},
        replyName: "",
        replyEmail: "",
        replyDescription: "",
        replyComment: "",
        blog: {},
        isReplyOpen: false
    }

    componentWillMount() {
        const {blog} = this.props
        let data = localStorage.getItem("data");
        let commonData = JSON.parse(data) || {}
        let allComments = (commonData && commonData.data && commonData.data.comments) || []
        const commentList = (allComments && allComments.length) ? allComments.filter(g => g.blogId === (blog && blog.id)) : []
        this.setState({
            commentList,
            allComments,
            blog
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.blog && this.props.blog.id) !== (prevProps.blog && prevProps.blog.id)) {
            this.setState({
                blog: this.props.blog
            })
        }
    }

    errorValidation = (name, value) => {
        switch (name) {
            case "name":
                if (!value) {
                    return "Name is require"
                } else {
                    return null
                }
            case "email":
                if (!value) {
                    return "E-mail  is require"
                }else if (!value.match(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)) {
                    return "Enter  a valid  E-mail"
                } else {
                    return null
                }
            case "description":
                if (!value) {
                    return "Comment is require"
                } else {
                    return null
                }
            default:
                return  null
        }
    };

    handleButtonClick = () => {
        const {blog} = this.state
        let allError = {}
        const { name, email, description, allComments } = this.state
        const currentData = {
            _id: `Comment-${Math.random().toString(12).substr(2, 9)}`,
            blogId: blog.id,
            name: name,
            email: email,
            description: description,
            date: new Date()
        }

        Object.keys(currentData).forEach(key => {
            const error = this.errorValidation(key, currentData[key])
            if (error) {
                allError[key] = error;
            }});
        if (Object.keys(allError).length) {
            this.setState({commentError:allError})
        } else {
            let data = localStorage.getItem("data")
            let dataItem = JSON.parse(data) || {}
            allComments.push(currentData)
            let myObj = {
                data: {
                    ...dataItem.data,
                    comments: allComments
                }
            }
            const commentList = (allComments && allComments.length) ? allComments.filter(g => g.blogId === (blog && blog.id)) : []
            this.setState({
                name: "",
                email: "",
                description: "",
                commentError: {},
                commentList
            })
            localStorage.setItem('data', JSON.stringify(myObj));
        }
    }

    handelChange = (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: value
        })
    }

    onReply = (data) => {
        const {isReplyOpen} = this.state
        this.setState({
            replyComment: data,
            reply: true,
            isReplyOpen: !isReplyOpen
        })
    }

    onReplySubmit = () => {
        let allError = {}
        let {replyComment, allComments, blog} = this.state
        const { replyName, replyEmail, replyDescription } = this.state
        const replyData = {
            _id: `Reply-${Math.random().toString(12).substr(2, 9)}`,
            name: replyName,
            email: replyEmail,
            description: replyDescription,
            date: new Date()
        }

        Object.keys(replyData).forEach(key => {
            const error = this.errorValidation(key, replyData[key]);
            if (error) {
                allError[key] = error;
            }});
        if (Object.keys(allError).length) {
            this.setState({replyError:allError})
        } else {
            if (replyComment && replyComment.reply) {
                replyComment.reply.push(replyData)
            } else {
                replyComment = {
                    ...replyComment,
                    reply : [replyData]
                }
            }

            let data = localStorage.getItem("data");
            let dataItem = JSON.parse(data) || {}

            const index = dataItem && dataItem.data && dataItem.data.comments && dataItem.data.comments.findIndex(f => f._id === replyComment._id)
            const i = allComments && allComments.length && allComments.findIndex(f => f._id === replyComment._id)

            dataItem.data.comments[index] = replyComment
            allComments[i] = replyComment
            const commentList = (allComments && allComments.length) ? allComments.filter(g => g.blogId === (blog && blog.id)) : []
            localStorage.setItem('data', JSON.stringify(dataItem))
            this.setState({
                replyName: "",
                replyEmail: "",
                replyDescription: "",
                commentList,
                allComments,
                reply: false,
                isReplyOpen: false,
                replyError: {}
            })
        }
    }

    render() {
        const {name, email, description, commentList, reply, replyName, replyEmail, replyDescription, replyComment, commentError, replyError, isReplyOpen} = this.state
        return (
            <div>
                {/* leave a reply section starts */}
                <div className="comments-style1">
                    {/* comments heading starts */}
                    <div className="padding-bottom-15px comments-title">
                        <h5 className="d-inline-block border-bottom-khaki font-weight-600 main-font text-extra-large md-title-mdall sm-text-extra-large">
                            {(commentList && commentList.length) || 0} Comments
                        </h5>
                    </div>
                    {/* comments heading ends */}
                    {/* comment 1 starts */}
                    {
                        (commentList && commentList.length) ? commentList.map((item, index) => {
                            const date = item.date && item.date.toLocaleString()
                            return(
                                <div key={index} className="comments-sectionstyle1">
                                    <div className="comments-wrapper">
                                        <div className="comment-img">
                                            <img src={images.imgAvatar} height="60" width="60" className="img-fluid" alt="img" />
                                        </div>
                                        <div className="blog-comme-content">
                                            <div className="commentdetails padding-left-15px d-xl-inline-block d-lg-block">
                                                <div className="padding-bottom-10px sm-padding-bottom-5px">
                                                    <h6 className="font-weight-600 text-large md-text-large sm-text-large"> {titleUpperCase(item.name)}</h6>
                                                    <span className="text-gray font-weight-500 sm-text-large">
                                                        {moment(date).format("MMM Do YYYY, h:mm a")}
                                                    </span>
                                                </div>
                                                <p className="text-gray sm-text-large">
                                                    {titleUpperCase(item.description)}
                                                </p>
                                            </div>
                                            <div className="comme-reply">
                                                <button className="btn btn-style1 font-weight-bold reply-toggle" onClick={() => this.onReply(item)}>
                                                    {(item._id === replyComment._id) && isReplyOpen ? "Cancel" : "Reply"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        item && item.reply && item.reply.length ? item.reply.map((s, index) => {
                                            const date = s.date && s.date.toLocaleString()
                                            return (
                                                <div key={index} className="cmt-reply">
                                                    <div className="comment-img">
                                                        <img src={images.imgAvatar} height="60" width="60" className="img-fluid" alt="img" />
                                                    </div>
                                                    <div className="blog-comme-content">
                                                        <div className="commentdetails padding-left-15px d-lg-block">
                                                            <div className="padding-bottom-10px sm-padding-bottom-5px">
                                                                <h6 className="font-weight-600 text-large md-text-large sm-text-large">
                                                                    {s.name && titleUpperCase(s.name)}
                                                                </h6>
                                                                <span className="text-gray font-weight-500 sm-text-large">
                                                                    {moment(date).format("MMM Do YYYY, h:mm a")}
                                                                </span>
                                                            </div>
                                                            <p className="text-gray sm-text-large">
                                                                {s.description && titleUpperCase(s.description)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : null
                                    }
                                    {
                                        reply && (replyComment._id === item._id) &&
                                        <div className="leave-reply-section panel">
                                            <div className="padding-bottom-15px">
                                                {/* leave a reply heading starts */}
                                                <h5 className="d-inline-block border-bottom-khaki font-weight-600 main-font text-extra-large md-title-mdall sm-text-extra-large">Leave A Reply</h5>
                                                {/* leave a reply heading ends */}
                                            </div>
                                            <div className="border-radius-5 paddingtwenty comment-form">
                                                {/* form starts */}
                                                <div className="row sm-text-large">
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group margin-bottom-25px sm-no-padding-l sm-margin-bottom-20px">
                                                            <label htmlFor="exampleInputName" className="text-black">Name</label>
                                                            <input className="sm-margin-bottom-10px form-control border-radius-5 p-2"
                                                                   id="exampleInputName"
                                                                   type="text"
                                                                   name="replyName"
                                                                   value={replyName}
                                                                   onChange={this.handelChange}
                                                                   placeholder="Your name" />
                                                            <span className="text-danger">{replyError.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group margin-bottom-25px sm-no-padding-right ">
                                                            <label htmlFor="exampleInputEmail1" className="text-black">E-mail</label>
                                                            <input className="form-control border-radius-5 p-2"
                                                                   id="exampleInputEmail1"
                                                                   type="text"
                                                                   name="replyEmail"
                                                                   value={replyEmail}
                                                                   onChange={this.handelChange}
                                                                   placeholder="Your email address" />
                                                            <span className="text-danger">{replyError.email}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 commentstyle-1">
                                                        <div className="pb-4 sm-no-padding-lr">
                                                            <input className="comment border-radius-5 form-control"
                                                                   name="replyDescription"
                                                                   value={replyDescription}
                                                                   onChange={this.handelChange}
                                                                   placeholder="Write your reply" />
                                                            <span className="text-danger">{replyError.description}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="text-left sm-no-padding-l">
                                                            <button className="btn btn-style1 hoverstyle1" onClick={this.onReplySubmit}>Reply</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* form ends */}
                                            </div>
                                            {/* leave Link reply section ends */}
                                        </div>
                                    }
                                </div>
                            )
                        }) : <p>No Comments Available for current blog</p>
                    }
                </div>

                <div className="leave-reply-section">
                    <div className="padding-bottom-15px">
                        {/* leavr a reply heading starts */}
                        <h5 className="d-inline-block border-bottom-khaki font-weight-600 main-font text-extra-large md-title-mdall sm-text-extra-large">Leave A Comment</h5>
                        {/* leavr a reply heading ends */}
                    </div>
                    <div className="border-radius-5 paddingtwenty comment-form">
                        {/* form starts */}
                        <div className="row sm-text-large">
                            <div className="col-md-6 col-12">
                                <div className="form-group margin-bottom-25px sm-no-padding-l sm-margin-bottom-20px">
                                    <label htmlFor="exampleInputName" className="text-black">Name</label>
                                    <input className="sm-margin-bottom-10px form-control border-radius-5 p-2"
                                        id="exampleInputName"
                                        type="text"
                                        name="name"
                                        value={name}
                                        onChange={this.handelChange}
                                        placeholder="Your name" />
                                    <span className="text-danger">{commentError.name}</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="form-group margin-bottom-25px sm-no-padding-right ">
                                    <label htmlFor="exampleInputEmail1" className="text-black">E-mail</label>
                                    <input className="form-control border-radius-5 p-2"
                                        id="exampleInputEmail1"
                                        type="text"
                                        name="email"
                                        value={email}
                                        onChange={this.handelChange}
                                        placeholder="Your email address" />
                                    <span className="text-danger">{commentError.email}</span>
                                </div>
                            </div>
                            <div className="col-md-12 commentstyle-1">
                                <div className="pb-4 sm-no-padding-lr">
                                    <input className="comment border-radius-5 form-control"
                                        name="description"
                                        value={description}
                                        onChange={this.handelChange}
                                        placeholder="Write your comment" />
                                    <span className="text-danger">{commentError.description}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="text-left sm-no-padding-l">
                                    <button className="btn btn-style1 hoverstyle1" onClick={this.handleButtonClick}>Submit</button>
                                </div>
                            </div>
                        </div>
                        {/* form ends */}
                    </div>
                    {/* leave Link reply section ends */}
                </div>
            </div>
        )
    }
}

export default Comments
