import {
    SET_PRODUCT_LIST,
} from '../constants/actionTypes';

const initialState = {
    productListData: []
};

const productListReducer = (state = initialState , action) => {
    switch (action.type) {
        case SET_PRODUCT_LIST: {
            return {
                ...state,
                productListData: (action.productData && action.productData.productList)
            }
        }
        default:
            return state
    }
}
export default productListReducer
