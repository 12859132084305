import React from "react";
import BreadCrumb from "../GlobalComponents/BreadCrumb";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import titleUpperCase from "../globalUtility/commonHelper";
import {addItemToCart} from "../actions/cartAction";
import {connect} from "react-redux";
import {removeItemToWishList} from "../actions/wishListAction";

class WishList extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            wishList: []
        }
    }

    componentWillMount() {
        const allWishList = JSON.parse(localStorage.getItem('wishList') ) || []
        this.setState({
            wishList: allWishList
        })
    }

    onRemove = (index) => {
        const {wishList} = this.state
        wishList.splice(index, 1)
        this.props.removeToWishList(wishList)
        this.setState({wishList})
        toast.error(`Product Removed Successfully`, {
            position: toast.POSITION.TOP_RIGHT
        })
    }

    render() {
        const {wishList} = this.state
        return(
            <>
                {/* breadcrumb section start */}
                <BreadCrumb heading="Pages" subHeading="Wishlist"/>
                {/* breadcrumb section end */}
                {/* wishlist page starts */}
                <section className="padding-80px-tb wishlist-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                {/*product table starts  */}
                                <div className="table-responsive">
                                    {
                                        (wishList && wishList.length) > 0 &&
                                            <table className="table table-bordered no-margin-bottom">
                                                <thead>
                                                {/*product heading table strats */}
                                                <tr className="text-center">
                                                    <th>Product Name</th>
                                                    <th>Unit Price</th>
                                                    <th>Quantity</th>
                                                    <th>Status</th>
                                                    <th>Sub Total</th>
                                                    <th>Add To Cart</th>
                                                    <th>Action</th>
                                                </tr>
                                                {/*product heading table ends */}
                                                </thead>
                                                <tbody>
                                                {
                                                    wishList && wishList.map((d, i) =>
                                                        <tr key={i} className="text-center">
                                                            {/*product image strats */}
                                                            <td>
                                                                <div className="wishlist-img">
                                                                    <Link to={`/product/${d.id}`}>
                                                                        <img src={d.pictures[0]} alt="img" width={100} height={100} />
                                                                    </Link>
                                                                    <div className="added-product">
                                                                        <Link to={`/product/${d.id}`} className="font-weight-600 text-black sm-text-large">
                                                                            {titleUpperCase(d.productTitle)}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {/*product image ends */}
                                                            <td className="vertical-align-middle sm-text-large">
                                                                ${d.salePrice && d.salePrice.toFixed(2)}
                                                            </td>
                                                            <td className="vertical-align-middle sm-text-large">
                                                                {d.count || 1}
                                                            </td>
                                                            <td className="vertical-align-middle">
                                                                <span className="text-info sm-text-large">Available</span>
                                                            </td>
                                                            <td className="vertical-align-middle font-weight-600">
                                                                {`${(d.salePrice && d.salePrice.toFixed(2) * (d.count || 1)).toFixed(2)}`}
                                                            </td>
                                                            <td className="vertical-align-middle md-width-170px sm-width-100">
                                                                <span onClick={() => this.props.addToCart(d)} className="btn btn-style1">
                                                                    add to cart
                                                                </span>
                                                            </td>
                                                            <td className="vertical-align-middle">
                                                                <div className="delet-icon">
                                                                    {/* trash icon starts */}
                                                                    <i className="fas fa-times hoverstyle1" onClick={() => this.onRemove(i)}/>
                                                                    {/* trash icon ends */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                    }
                                </div>
                            </div>
                            {/*product table ends  */}
                        </div>
                        {
                            wishList.length <= 0 &&
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="no-pro-available">
                                        <p className="alert-msg">No Product Found in WishList</p>
                                    </div>
                                    <div className="go-link">
                                        <Link to="/products/grids-page" className="btn btn-style1">Shop Now</Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
                {/* wishlist page ends */}
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addToCart: (item) => dispatch(addItemToCart(item)),
    removeToWishList: (productData) => dispatch(removeItemToWishList(productData)),
})

export default connect(
    null,
    mapDispatchToProps
)(WishList)
